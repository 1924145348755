// Global
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { ReactElement, useEffect } from 'react';

// Local
import { ProductModel } from 'src/widgets/ProductListWithSearch/index';

type PowerReviewsScriptType = {
  ENABLE_CLIENT_SIDE_STRUCTURED_DATA: boolean | undefined;
  api_key: string | undefined;
  locale: string | undefined;
  merchant_group_id: string | undefined;
  merchant_id: string | undefined;
  page_id: string;
  components: {
    CategorySnippet: string;
  };
};

type PowerReviewsPrductListWithSearchProps = {
  products: ProductModel[];
};

const PowerReviewsPrductListWithSearch = ({
  products,
}: PowerReviewsPrductListWithSearchProps): ReactElement => {
  const { sitecoreContext } = useSitecoreContext();
  const enablePowerReview = sitecoreContext?.powerReviews?.enablePowerReviews;

  // If power reviews is disabled
  if (!enablePowerReview) {
    return <></>;
  }
  const language =
    sitecoreContext?.powerReviews?.locale?.replace('-', '_') ||
    sitecoreContext.language?.replace('-', '_');
  const merchantID = sitecoreContext?.powerReviews?.merchantID;
  const merchantGroupID = sitecoreContext?.powerReviews?.merchantGroupID;
  const powerReviewsApiKey = sitecoreContext?.powerReviews?.powerReviewsApiKey;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const ratingTypes: string[] = ['card', 'list'];
    const array: PowerReviewsScriptType[] = [];

    if (products.length) {
      products?.forEach((p, index) => {
        ratingTypes.forEach((element) => {
          const selectorId = `search-rating-${element}-snippet-${index}`;
          const script1: PowerReviewsScriptType = {
            ENABLE_CLIENT_SIDE_STRUCTURED_DATA: false,
            api_key: powerReviewsApiKey,
            locale: language,
            merchant_group_id: merchantGroupID,
            merchant_id: merchantID,
            page_id: p?.product_information_item_id?.toUpperCase(),
            components: {
              CategorySnippet: selectorId,
            },
          };
          if (script1.page_id) {
            array.push(script1);
          }
        });
      });
    }

    if (array.length) {
      window.pwr =
        window.pwr ||
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function (...args: any) {
          (window.pwr.q = window.pwr.q || []).push([...args]);
        };
      window.pwr('render', array);
    }

    return () => {
      array.forEach((item) => {
        window.pwr('unmount', item?.components?.CategorySnippet);
      });
    };
  }, [language, merchantGroupID, merchantID, powerReviewsApiKey, products]);

  return <></>;
};
export default PowerReviewsPrductListWithSearch;
