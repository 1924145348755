// Global
import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { ReactElement } from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { GtmEvent } from 'lib/utils/gtm-utils';

// Local
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';

export type ColorTypes = 'color' | 'white';
export type ButtonTypes = 'default' | 'rounded';

export interface LabelProps {
  backgroundColor?: string;
  buttonType?: ButtonTypes;
  className?: string;
  color?: ColorTypes;
  gtmEvent?: GtmEvent;
  link?: LinkField;
  text?: Field<string>;
}

const tailwindVariants = tv({
  slots: {
    base: [
      'bg-clip-padding',
      'border-2',
      'border-solid',
      'border-transparent',
      'relative',
      'self-start',
      'w-fit',
      'before:-m-0.5',
      'before:absolute',
      'before:inset-0',
      'before:z-[-1]',
    ],
    textClasses: [
      'font-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
    ],
    wrapper: ['flex', 'relative', 'z-0'],
  },
  variants: {
    buttonType: {
      rounded: {
        base: [
          'py-components-button-spacing-large-default-padding-y',
          'px-components-button-spacing-large-default-padding-x',
          'rounded-components-button-group-spacing-border-radius',
          'before:rounded-components-button-group-spacing-border-radius',
          'ml-components-button-spacing-large-default-padding-x',
        ],
      },
      default: {
        base: [
          // subtract border - 2px = 0.125rem
          // from padding - 8px = 0.5rem
          // 0.5rem - 0.125rem = 0.375rem
          'p-[0.375rem]',
          'rounded-themes-radius-large-label',
          'before:rounded-themes-radius-large-label',
        ],
      },
    },
    colorTypes: {
      color: {
        base: [
          'text-components-label-color-on-color-label-label-default',
          'before:bg-components-label-color-oncolor-stroke-stroke-default',
        ],
      },
      white: {
        base: [
          'text-components-label-color-on-white-label-label-default',
          'before:bg-components-label-color-onwhite-stroke-stroke-default',
        ],
      },
    },
    isLink: {
      color: {
        base: [
          'active:bg-components-label-color-on-color-background-bg-pressed',
          'active:border-components-label-color-on-color-stroke-stroke-pressed',
          'active:text-components-label-color-on-color-label-label-pressed',
          'hover:bg-components-label-color-on-color-background-bg-hover',
          'hover:border-components-label-color-on-color-stroke-stroke-hover',
          'hover:text-components-label-color-on-color-label-label-hover',
        ],
      },
      white: {
        base: [
          'active:bg-components-label-color-on-white-background-bg-pressed',
          'active:border-components-label-color-on-white-stroke-stroke-pressed',
          'active:text-components-label-color-on-white-label-label-pressed',
          'hover:bg-components-label-color-on-white-background-bg-hover',
          'hover:border-components-label-color-on-white-stroke-stroke-hover',
          'hover:text-components-label-color-on-white-label-label-hover',
        ],
      },
    },
  },
});

const Label = ({
  backgroundColor,
  buttonType = 'default',
  className,
  color = 'white',
  gtmEvent,
  link,
  text,
}: LabelProps): ReactElement => {
  const isLink = !!link;
  const isText = !!text;

  const tailwindVariantsExtended = tv({
    extend: tailwindVariants,
    slots: {
      base: [className],
    },
  });

  const { base, textClasses, wrapper } = tailwindVariantsExtended({
    colorTypes: color as ColorTypes,
    isLink: isLink ? (color as ColorTypes) : undefined,
    buttonType: buttonType,
  });

  if (isLink && link?.value?.text == '') {
    return <></>;
  }

  if (isText && text?.value == '') {
    return <></>;
  }

  return (
    <div className={wrapper()} data-component="helpers/general/label">
      <div className={`${className || ''} ${base()} ${backgroundColor || ''}`}>
        {isLink && link?.value?.text !== '' ? (
          <LinkWrapper className={textClasses()} field={link} gtmEvent={gtmEvent} />
        ) : (
          <RichTextA11yWrapper className={textClasses()} field={text} />
        )}
      </div>
    </div>
  );
};

export default Label;
