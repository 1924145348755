// Global
import { useI18n } from 'next-localization';
import { tv } from 'tailwind-variants';

// Local
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import { useRealPathName } from 'lib/utils/use-real-pathname';

export type SkipToMainProps = {
  text?: string;
};

const tailwindVariants = tv({
  slots: {
    base: [
      'absolute',
      'bg-themes-background-color-black',
      'flex',
      'font-bodySans-medium',
      'items-center',
      'justify-center',
      'leading-bodySans-medium',
      'px-components-accessibility-bar-spacing-small-padding-x',
      'py-components-accessibility-bar-spacing-small-padding-y',
      'text-bodySans-medium',
      'text-white',
      '-top-full',
      'underline',
      'w-full',
      'focus:sticky',
      'focus:z-[9999]',
      'md:font-bodySans-xLarge-underline',
      'md:leading-bodySans-xLarge-underline',
      'md:px-components-accessibility-bar-spacing-large-padding-x',
      'md:py-components-accessibility-bar-spacing-large-padding-y',
      'md:text-bodySans-xLarge-underline',
    ],
  },
});

const SkipToMain = () => {
  const { base } = tailwindVariants();
  const i18n = useI18n();

  const skipToMainText = i18n.t('SkipToMain');

  const handleOnClick = (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    e.preventDefault();

    document.getElementById('main')?.focus();
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    // The "Skip to Main" component is exclusively activated by users' keyboard inputs. As such, we cannot block keyboard events.
    // SEE: https://horizontal.atlassian.net/jira/software/c/projects/SJ/boards/851?selectedIssue=SJ-1199
    // e.preventDefault();

    if (e.key === 'Enter' || e.key === ' ') {
      document.getElementById('main')?.focus();
    }
  };

  const url = useRealPathName();

  return (
    <LinkWrapper
      className={base()}
      data-component="helpers/SkipToMain"
      field={{ value: { href: url.replace(/#.*/, ''), anchor: '#main' } }}
      id="skipToMain"
      onClick={(e) => handleOnClick(e)}
      onKeyDown={(e) => handleOnKeyDown(e)}
    >
      {skipToMainText || 'Skip to Main'}
    </LinkWrapper>
  );
};

export default SkipToMain;
