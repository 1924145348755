// Global
import { useSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect, useState } from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartComponents } from 'lib/templates/Feature.Dart.model';
import { BaseFields } from 'lib/templates/Foundation.Dart.model';

// Local
import CtaBorderAnimation from 'helpers/CTA/CtaBorderAnimation';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import { useRealPathName } from 'lib/utils/use-real-pathname';
import { NameDefault } from './HeaderDesktop';

export type Countrylist = DartComponents.Navigation.RegionJson &
  NameDefault &
  DartComponents.Navigation.CountryJson;

export type FeaturedLinks = BaseFields.CtaJson;

export type HeaderMobileProps = ComponentProps &
  DartComponents.HeaderJson & {
    staticProps?: {
      header?: DartComponents.HeaderJson;
    };
  };

export type MainNavigations = DartComponents.Navigation.SecondaryNavigationJson &
  DartComponents.Navigation.PrimaryHeaderNavigationJson &
  BaseFields.FeaturedJson;

export type SecondaryNavigations = DartComponents.Navigation.SecondaryNavigationJson;

const tailwindVariants = tv({
  compoundSlots: [
    {
      slots: [
        'flexCommonClass',
        'headerWrapper',
        'primaryNavigationListitem',
        'secondaryNavigationListItemTitleLinkWrapper',
        'countryWrapperInner',
        'currentCountry',
        'countryItemLinkWrapper',
        'countryItemLink',
      ],
      class: ['flex', 'flex-row', 'items-center'],
    },
  ],
  slots: {
    base: ['bg-components-header-color-bg', 'block', 'component header', 'w-full', 'md:hidden'],
    headerWrapper: [
      'header-wrapper',
      'relative',
      'pt-components-header-spacing-small-padding-top',
      'pb-components-header-spacing-small-padding-bottom',
      'pl-components-header-spacing-small-padding-left',
      'pr-components-header-spacing-small-padding-right',
      'justify-between',
      'border-components-header-color-bottom-stroke',
      'border-b',
    ],
    mobileHeaderLeft: ['mobile-header-left', 'flex', 'flex-row'],
    logoWrapper: ['logo-wrapper'],
    logo: ['logo', 'mr-margin-large-4'],
    mobileHeaderRight: ['mobile-header-right', 'flex', 'flex-row', 'gap-4', 'items-center'],
    searchWrapper: ['search-wrapper'],
    searchForm: ['w-full', 'flex', 'justify-between', 'items-start'],
    searchFormInput: [
      'hidden',
      'font-semibold',
      'text-heading-medium-xxsmall',
      'font-brand-1-600',
      'navlink',
      'placeholder-blue-700',
      'leading-[18px]',
      'font-semibold',
      'hover:text-blue-950',
      'mb-margin-large-1',
    ],
    searchFormButton: [
      'basicFocus',
      'duration-300',
      'ease-in-out',
      'font-medium',
      'h-[40px]',
      'mt-[-3px]',
      'text-white',
      'text-xl',
      'w-[40px]',
    ],
    searchFormButtonIcon: [
      'fill-components-header-color-icon-icon-default',
      '[&>svg]:h-[40px]',
      '[&>svg]:w-[40px]',
    ],
    hamburgerWrapper: ['flex', 'h-[40px]', 'w-[40px]', 'relative'],
    toggleMenuWrapper: ['toggle-menu', 'flex', 'justify-center', 'items-center'],
    toggleMenuCheckbox: [
      'checkbox',
      'cursor-pointer',
      'block',
      'h-[40px]',
      'opacity-0',
      'relative',
      'right-0',
      'w-[40px]',
      'z-20',
    ],
    hamburgerLinesWrapper: [
      'hamburger-lines',
      'h-[20px]',
      'absolute',
      'flex-col',
      'flex',
      'justify-between',
      'right-0',
      'w-[30px]',
      'z-10',
    ],
    hamburgerLine1: [
      'bg-components-header-color-icon-icon-default',
      'block',
      'duration-300',
      'ease-in-out',
      'h-[3px]',
      'line',
      'line1',
      'origin-[0%_0%]',
      'w-full',
    ],
    hamburgerLine2: [
      'bg-components-header-color-icon-icon-default',
      'block',
      'duration-300',
      'ease-in-out',
      'h-[3px]',
      'line',
      'line2',
      'w-full',
    ],
    hamburgerLine3: [
      'bg-components-header-color-icon-icon-default',
      'block',
      'duration-300',
      'ease-in-out',
      'h-[3px]',
      'line',
      'line3',
      'origin-[0%_100%]',
      'w-full',
    ],
    mobileNavigation: ['mobile-navigation'],
    navigation: ['navigation'],
    navigationInner: ['navigation-inner'],
    primaryNavigation: ['primary-navigation', 'flex', 'flex-col'],
    primaryNavigationListitemWrapper: ['primary-navigation-listitem'],
    primaryNavigationListitem: [
      'justify-between',
      'py-components-header-spacing-small-nav-menu-link-padding-y',
      'pl-components-header-spacing-small-nav-category-padding-left',
      'pr-components-header-spacing-small-nav-category-padding-right',
      'border-components-header-color-bottom-stroke',
      'border-b',
      'min-h-[48px]',
    ],
    primaryNavigationListItemLink: [
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'navlink',
      'text-components-header-color-links-nav-main-link-default',
      'hover:text-components-header-color-links-nav-main-link-hover',
    ],
    primaryNavigationListItemIcon: ['fill-components-header-color-links-nav-main-link-default'],
    secondaryNavigationWrapper: [
      'secondary-navigation-wrapper',
      'top-full',
      'flex',
      'flex-col',
      'z-10',
      'bg-brand-1-100',
      'w-full',
      'justify-between',
    ],
    secondaryNavigation: [
      'secondary-navigation',
      'flex',
      'flex-col',
      'bg-components-header-color-mobile-mobile-expanded-bg',
    ],
    secondaryNavigationListItemTitleLinkWrapper: [
      'justify-between',
      'py-components-header-spacing-small-nav-menu-link-padding-y',
      'pl-components-header-spacing-small-nav-category-padding-left',
      'pr-components-header-spacing-small-nav-category-padding-right',
      'min-h-[48px]',
    ],
    secondaryNavigationListItemTitleLink: [
      'block',
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-components-header-color-links-nav-category-link-category-default',
      'hover:text-components-header-color-links-nav-category-link-category-hover',
    ],
    secondaryNavigationListItemLinkIcon: ['fill-components-header-color-icon-icon-default'],
    secondaryNavigationItemsWrapper: ['secondary-navigation-items-wrapper', 'flex', 'flex-col'],
    secondaryNavigationItem: [
      'py-components-header-spacing-small-nav-menu-link-padding-y',
      'pl-components-header-spacing-small-nav-menu-link-padding-left',
      'pr-ccomponents-header-spacing-small-nav-menu-link-padding-right',
      'min-h-[48px]',
    ],
    secondaryNavigationItemLink: [
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'text-components-header-color-links-nav-main-link-default',
      'leading-bodySans-medium-semibold',
      'hover:text-text-components-header-color-links-nav-main-link-hover',
    ],
    featuredContentWrapper: [
      'featured-content-wrapper',
      'bg-components-header-color-mega-menu-featured-bg',
      'px-components-header-spacing-small-mega-menu-featured-padding-x',
      'py-components-header-spacing-small-mega-menu-featured-padding-y',
      'w-auto',
      'md:w-[444px]',
    ],
    featuredContentTitleWrapper: ['featured-content-title-wrapper'],
    featuredContentTitle: [
      'text-header-large-xSmall',
      'leading-header-large-xSmall',
      'font-header-large-xSmall',
      'text-components-header-color-mega-menu-featured-title',
    ],
    featuredContentImageWrapper: [
      'featured-content-image',
      'object-cover',
      'h-[213px]',
      'w-full',
      'md:w-[380px]',
      'relative',
      'my-components-header-spacing-small-mega-menu-featured-image-margin-bottom',
    ],
    featuredContentImage: ['rounded-2xl'],
    featuredContentLinksWrapper: ['featured-content-links'],
    featuredContentLinks: ['featured-links'],
    featuredContentLinkItem: [
      'font-bodySans-medium-semibold',
      'mr-margin-medium-4',
      'inline-block',
    ],
    countryWrapper: ['country-wrapper', 'bg-components-header-color-mega-menu-bg'],
    countryWrapperInner: [
      'country-wrapper-inner',
      'cursor-pointer',
      'w-full',
      'justify-between',
      'py-components-header-spacing-small-language-selector-padding-y',
      'px-components-header-spacing-small-language-selector-padding-x',
    ],
    currentCountry: ['current-country'],
    currentCountryInner: [
      'flex',
      'flex-row',
      'items-start',
      'gap-components-header-spacing-large-language-selector-space-between',
    ],
    flagIconWrapper: ['flag-icon'],
    logoPlaceholder: [
      'logo-placeholder',
      'w-[18px]',
      'h-[13px]',
      'bg-components-header-color-mega-menu-featured-bg',
    ],
    countryInformation: [
      'country-information',
      'flex',
      'flex-col',
      'gap-components-header-spacing-large-language-selector-country-space-between',
      'items-start',
    ],
    currentCountryName: [
      'current-country-name',
      'text-components-header-color-links-nav-menu-link-link-default',
      'font-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      ' text-bodySans-medium-semibold',
    ],
    currentCountryLanguage: [
      'current-country-language',
      'text-components-header-color-language-selector-language',
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
    ],
    countryDropDownIcon: ['fill-components-header-color-links-nav-main-link-default'],
    countryListWrapper: [
      'country-list-wrapper',
      'top-full',
      'bg-components-header-color-mega-menu-bg w-full',
    ],
    countryListInnerWrapper: ['country-list-inner-wrapper', 'p-spacing-padding-large-2'],
    countryListTop: ['country-list-top'],
    countryListHeading: [
      'country-list-heading',
      'text-components-header-color-mega-menu-category-title',
      'text-header-small-xSmall',
      'font-header-small-xSmall',
      'leading-header-small-xSmall',
      'mb-components-header-spacing-small-mega-menu-title-margin-bottom',
    ],
    countryListBottom: ['country-list-bottom'],
    countryListCols: ['country-list-cols'],
    countryListCol: ['country-list-col'],
    countryName: [
      'country-name',
      'font-header-medium-xxSmall',
      'text-header-medium-xxSmall',
      'text-components-header-color-links-nav-category-link-category-default',
      'mb-components-header-spacing-large-mega-menu-category-link-margin-bottom',
      'leading-header-medium-xxSmall',
    ],
    countryList: ['country-list'],
    countryItemLinkWrapper: [
      'country-list-item',
      'group',
      'px-spacing-padding-large-2',
      'py-spacing-padding-large-1',
      'mb-components-header-spacing-large-mega-menu-link-margin-bottom',
      'rounded-components-header-spacing-large-language-selector-radius',
      'cursor-pointer',
      'hover:bg-components-header-color-language-selector-bg',
    ],
    countryItemLink: ['items-start', 'justify-between', 'w-full'],
    countryListItemLinkleft: ['country-list-item-link-left', 'flex', 'flex-row', 'items-start'],
    countryListItemLinkleftInfo: [
      'country-info',
      'ml-components-header-spacing-large-language-selector-space-between',
      'flex',
      'flex-col',
      'gap-components-header-spacing-large-language-selector-country-space-between',
    ],
    countryListItemLinkleftInfoTitle: [
      'text-components-header-color-links-nav-menu-link-link-default',
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
    ],
    countryListItemLinkleftInfoSubTitle: [
      'text-colors-grayscale-400',
      'text-bodySans-small',
      'font-bodySans-small',
      'leading-bodySans-small',
    ],
    countryListRight: ['country-list-right'],
    countrySelection: ['country-selection'],
    countryListRightIcon: ['fill-color-system-success-400'],
    flexCommonClass: [],
  },
  variants: {
    toggleMobileMenu: {
      true: {
        base: ['overflow-y-scroll', 'fixed', 'left-0', 'right-0', 'top-0', 'bottom-0', 'z-50'],
        searchFormButton: ['opacity-0', 'pointer-events-none'],
      },
      false: {
        searchFormButton: ['opacity-100'],
      },
    },
    showCountryBg: {
      true: {
        countryItemLinkWrapper: ['bg-components-header-color-language-selector-bg'],
      },
      false: {
        countryItemLinkWrapper: [],
      },
    },
  },
});

const HeaderMobile = (props: HeaderMobileProps): JSX.Element => {
  const { logoImage, mainNavigations, regions, searchPlaceholderText, title, logoUrl } =
    props?.staticProps?.header || {};
  const { componentName, dataSource } = props?.rendering || {};

  const [matchingCountry, setMatchingCountry] = useState<Countrylist | null>();
  const [openCountryWrapper, setOpenCountryWrapper] = useState<boolean>(false);
  const [openL1Panels, setOpenL1Panels] = useState([]);
  const [openL2Panels, setOpenL2Panels] = useState<Record<number, number[]>>({});
  const [toggleMenu, setToggleMenu] = useState(false);

  const pathname = useRealPathName();
  const { sitecoreContext } = useSitecoreContext();

  const {
    base,
    headerWrapper,
    mobileHeaderLeft,
    logoWrapper,
    logo,
    mobileHeaderRight,
    searchWrapper,
    searchForm,
    searchFormInput,
    searchFormButton,
    searchFormButtonIcon,
    hamburgerWrapper,
    toggleMenuWrapper,
    toggleMenuCheckbox,
    hamburgerLinesWrapper,
    hamburgerLine1,
    hamburgerLine2,
    hamburgerLine3,
    mobileNavigation,
    navigation,
    navigationInner,
    primaryNavigation,
    primaryNavigationListitemWrapper,
    primaryNavigationListitem,
    primaryNavigationListItemLink,
    primaryNavigationListItemIcon,
    secondaryNavigationWrapper,
    secondaryNavigation,
    secondaryNavigationListItemTitleLinkWrapper,
    secondaryNavigationListItemTitleLink,
    secondaryNavigationListItemLinkIcon,
    secondaryNavigationItemsWrapper,
    secondaryNavigationItem,
    secondaryNavigationItemLink,
    featuredContentWrapper,
    featuredContentTitleWrapper,
    featuredContentTitle,
    featuredContentImageWrapper,
    featuredContentImage,
    featuredContentLinksWrapper,
    featuredContentLinks,
    featuredContentLinkItem,
    countryWrapper,
    countryWrapperInner,
    currentCountry,
    currentCountryInner,
    flagIconWrapper,
    logoPlaceholder,
    countryInformation,
    currentCountryName,
    currentCountryLanguage,
    countryDropDownIcon,
    countryListWrapper,
    countryListInnerWrapper,
    countryListTop,
    countryListHeading,
    countryListBottom,
    countryListCols,
    countryListCol,
    countryName,
    countryList,
    countryItemLinkWrapper,
    countryItemLink,
    countryListItemLinkleft,
    countryListItemLinkleftInfo,
    countryListItemLinkleftInfoTitle,
    countryListItemLinkleftInfoSubTitle,
    countryListRight,
    countrySelection,
    countryListRightIcon,
  } = tailwindVariants({ toggleMobileMenu: toggleMenu });

  useEffect(() => {
    if (!regions) return;

    const foundCountry = regions?.targetItems
      .flatMap((region: Countrylist) => {
        return region?.countries?.targetItems;
      })
      .find(
        (country?: Countrylist) =>
          country?.languageCode?.jsonValue?.value === sitecoreContext.language
      );
    setMatchingCountry(foundCountry);
  }, [regions, sitecoreContext]);

  useEffect(() => {
    if (toggleMenu) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [toggleMenu]);

  const showCountryWrapper = () => {
    setOpenCountryWrapper(!openCountryWrapper);
  };

  const expandL1Navigation = (index: number) => {
    if (openL1Panels?.includes(index as never)) {
      setOpenL1Panels(openL1Panels.filter((x) => x !== index));
    } else {
      setOpenL1Panels([...(openL1Panels as never), index as never]);
    }
  };

  const expandL2Navigation = (index: number, innerIndex: number) => {
    setOpenL2Panels((prevOpenL2Panels) => {
      const currentL1Panels = prevOpenL2Panels[index] || [];

      return {
        ...prevOpenL2Panels,
        [index]: Array.isArray(currentL1Panels)
          ? currentL1Panels.includes(innerIndex)
            ? currentL1Panels.filter((y) => y !== innerIndex)
            : [...currentL1Panels, innerIndex]
          : [innerIndex],
      };
    });
  };

  const toggleHamburger = (state?: boolean) => {
    setOpenL1Panels([]);
    setOpenL2Panels([]);
    setToggleMenu(state !== undefined ? state : !toggleMenu);
  };

  return (
    <div className={base()} data-component="authorable/landmarks/header">
      <div className={headerWrapper()}>
        <div className={mobileHeaderLeft()}>
          <div className={logoWrapper()}>
            <div className={logo()}>
              {pathname.replace(/#.*/, '') !== '/' ? (
                <LinkWrapper
                  aria-label="Link to homepage"
                  field={logoUrl?.jsonValue}
                  gtmEvent={{
                    event: 'navigation_click',
                    'gtm.element.dataset.gtmDatasourceId': dataSource,
                    'gtm.element.dataset.gtmComponentName': componentName,
                  }}
                  onClick={() => toggleHamburger(false)}
                  title="Link to homepage"
                >
                  <ImageWrapper field={logoImage?.jsonValue} />
                </LinkWrapper>
              ) : (
                <ImageWrapper field={logoImage?.jsonValue} />
              )}
            </div>
          </div>
        </div>
        <div className={mobileHeaderRight()}>
          <div className={searchWrapper()}>
            <form action="/search" className={searchForm()} role="search">
              <label htmlFor="searchboxh" className="hidden">
                {searchPlaceholderText?.jsonValue?.value}
              </label>
              <input
                autoComplete="off"
                className={searchFormInput()}
                id="searchboxh"
                name="q"
                placeholder={searchPlaceholderText?.jsonValue?.value}
                required={false}
                type="text"
              />
              <button aria-label="Search" className={searchFormButton()} type="submit">
                <GoogleMaterialSymbol
                  className={searchFormButtonIcon()}
                  icon="search"
                  variant="outlined"
                />
              </button>
            </form>
          </div>
          <div className={hamburgerWrapper()}>
            <div className={toggleMenuWrapper()}>
              <input
                aria-label="Main Menu"
                className={toggleMenuCheckbox()}
                checked={toggleMenu}
                onChange={() => toggleHamburger()}
                type="checkbox"
              />
              <div className={hamburgerLinesWrapper()}>
                <span className={hamburgerLine1()}></span>
                <span className={hamburgerLine2()}></span>
                <span className={hamburgerLine3()}></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {toggleMenu && (
        <>
          <div className={mobileNavigation()}>
            <nav className={navigation()}>
              <div className={navigationInner()}>
                <ul className={primaryNavigation()}>
                  {mainNavigations?.targetItems?.map((ListItem: MainNavigations, index: number) => {
                    return (
                      <li key={index} className={primaryNavigationListitemWrapper()}>
                        <div
                          className={primaryNavigationListitem()}
                          onClick={() => expandL1Navigation(index)}
                        >
                          {ListItem?.navigationLink?.jsonValue?.value?.href ? (
                            <LinkWrapper
                              className={primaryNavigationListItemLink()}
                              field={ListItem?.navigationLink?.jsonValue}
                              gtmEvent={{
                                event: 'navigation_click',
                                type: 'header',
                                'gtm.element.dataset.gtmLinkName':
                                  ListItem?.navigationLink?.jsonValue?.value.text,
                                'gtm.element.dataset.gtmLinkUrl':
                                  ListItem?.navigationLink?.jsonValue?.value?.href,
                                'gtm.element.dataset.gtmDatasourceId': dataSource,
                                'gtm.element.dataset.gtmComponentName': componentName,
                              }}
                              onClick={() => toggleHamburger()}
                            />
                          ) : (
                            <Text
                              aria-label={ListItem?.navigationTitle?.jsonValue?.value}
                              className={primaryNavigationListItemLink()}
                              encode={false}
                              field={ListItem?.navigationTitle?.jsonValue}
                              tag="span"
                            />
                          )}
                          {ListItem?.secondaryNavigations?.targetItems?.length !== 0 && (
                            <GoogleMaterialSymbol
                              className={primaryNavigationListItemIcon()}
                              icon={
                                openL1Panels.includes(index as never)
                                  ? 'expand_less'
                                  : 'expand_more'
                              }
                            />
                          )}
                        </div>
                        {openL1Panels?.includes(index as never) && (
                          <div className={secondaryNavigationWrapper()}>
                            <ul className={secondaryNavigation()}>
                              {ListItem?.secondaryNavigations?.targetItems?.map(
                                (ListIteminner: SecondaryNavigations, innerindex: number) => {
                                  return (
                                    <li key={innerindex}>
                                      <div
                                        className={secondaryNavigationListItemTitleLinkWrapper()}
                                        onClick={() => expandL2Navigation(index, innerindex)}
                                      >
                                        <LinkWrapper
                                          className={secondaryNavigationListItemTitleLink()}
                                          field={ListIteminner?.navigationLink?.jsonValue}
                                          gtmEvent={{
                                            event: 'navigation_click',
                                            type: 'header',
                                            'gtm.element.dataset.gtmLinkName':
                                              ListIteminner?.navigationLink?.jsonValue?.value.text,
                                            'gtm.element.dataset.gtmLinkUrl':
                                              ListIteminner?.navigationLink?.jsonValue?.value?.href,
                                            'gtm.element.dataset.gtmDatasourceId': dataSource,
                                            'gtm.element.dataset.gtmComponentName': componentName,
                                          }}
                                          key={index}
                                          onClick={() => toggleHamburger()}
                                        />
                                        {ListIteminner?.navigations?.targetItems?.length &&
                                        ListIteminner?.navigations?.targetItems?.length !== 0 ? (
                                          <GoogleMaterialSymbol
                                            className={secondaryNavigationListItemLinkIcon()}
                                            icon={
                                              (openL2Panels[index] || []).includes(innerindex)
                                                ? 'remove'
                                                : 'add'
                                            }
                                          />
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                      {(openL2Panels[index] || []).includes(innerindex) && (
                                        <ul className={secondaryNavigationItemsWrapper()}>
                                          {ListIteminner?.navigations?.targetItems?.map(
                                            (
                                              SecondaryItems: SecondaryNavigations,
                                              index: number
                                            ) => {
                                              return (
                                                SecondaryItems?.navigationLink?.jsonValue?.value
                                                  ?.href && (
                                                  <li
                                                    className={secondaryNavigationItem()}
                                                    key={index}
                                                  >
                                                    <LinkWrapper
                                                      className={secondaryNavigationItemLink()}
                                                      field={
                                                        SecondaryItems?.navigationLink?.jsonValue
                                                      }
                                                      gtmEvent={{
                                                        event: 'navigation_click',
                                                        type: 'header',
                                                        'gtm.element.dataset.gtmLinkName':
                                                          SecondaryItems?.navigationLink?.jsonValue
                                                            ?.value.text,
                                                        'gtm.element.dataset.gtmLinkUrl':
                                                          SecondaryItems?.navigationLink?.jsonValue
                                                            ?.value?.href,
                                                        'gtm.element.dataset.gtmDatasourceId':
                                                          dataSource,
                                                        'gtm.element.dataset.gtmComponentName':
                                                          componentName,
                                                      }}
                                                      onClick={() => toggleHamburger()}
                                                    />
                                                  </li>
                                                )
                                              );
                                            }
                                          )}
                                        </ul>
                                      )}
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                            {ListItem?.featuredImage?.jsonValue?.value?.src && (
                              <div className={featuredContentWrapper()}>
                                <div className={featuredContentTitleWrapper()}>
                                  <Text
                                    className={featuredContentTitle()}
                                    encode={false}
                                    field={ListItem?.featuredTitle?.jsonValue}
                                    tag="p"
                                  />
                                </div>
                                <div className={featuredContentImageWrapper()}>
                                  <ImageWrapper
                                    className={featuredContentImage()}
                                    field={ListItem?.featuredImage?.jsonValue}
                                  />
                                </div>
                                <div className={featuredContentLinksWrapper()}>
                                  <ul className={featuredContentLinks()}>
                                    {ListItem?.featuredLinks?.targetItems?.map(
                                      (featuredLinkItem: FeaturedLinks, index: number) => {
                                        return (
                                          <React.Fragment key={index}>
                                            {featuredLinkItem?.ctaLink?.jsonValue?.value?.href && (
                                              <li className={featuredContentLinkItem()}>
                                                {featuredLinkItem?.ctaLink && (
                                                  <CtaBorderAnimation
                                                    fields={featuredLinkItem?.ctaLink?.jsonValue}
                                                  />
                                                )}
                                              </li>
                                            )}
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </ul>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </nav>
          </div>
          <div className={countryWrapper()}>
            <button
              aria-label={`Current Country and Language - ${
                matchingCountry?.name?.jsonValue?.value
                  ? matchingCountry?.name?.jsonValue?.value
                  : matchingCountry?.nameDefault?.jsonValue?.value
              } ${matchingCountry?.languageName?.jsonValue?.value}`}
              className={countryWrapperInner()}
              onClick={showCountryWrapper}
            >
              <div className={currentCountry()}>
                <div className={currentCountryInner()}>
                  <div className={flagIconWrapper()}>
                    {matchingCountry?.flagIcon?.jsonValue?.value?.src ? (
                      <ImageWrapper field={matchingCountry?.flagIcon?.jsonValue} />
                    ) : (
                      <div className={logoPlaceholder()}></div>
                    )}
                  </div>
                  <div className={countryInformation()}>
                    <Text
                      className={currentCountryName()}
                      encode={false}
                      field={
                        matchingCountry?.name?.jsonValue
                          ? matchingCountry?.name?.jsonValue
                          : matchingCountry?.nameDefault?.jsonValue
                      }
                      tag="div"
                    />
                    <Text
                      className={currentCountryLanguage()}
                      encode={false}
                      field={matchingCountry?.languageName?.jsonValue}
                      tag="div"
                    />
                  </div>
                </div>
              </div>
              <GoogleMaterialSymbol
                className={countryDropDownIcon()}
                icon={openCountryWrapper ? 'arrow_drop_up' : 'arrow_drop_down'}
                variant="outlined"
              />
            </button>
            {openCountryWrapper && (
              <div className={countryListWrapper()}>
                <div className={countryListInnerWrapper()}>
                  <div className={countryListTop()}>
                    <Text
                      className={countryListHeading()}
                      encode={false}
                      feild={title?.jsonValue}
                      tag="div"
                    />
                  </div>
                  <div className={countryListBottom()}>
                    {regions?.targetItems?.map((country: Countrylist, index: number) => {
                      return (
                        <>
                          {country?.countries?.targetItems?.length !== 0 &&
                            (country?.name?.jsonValue || country?.nameDefault?.jsonValue) && (
                              <div className={countryListCols()} key={index}>
                                <div className={countryListCol()}>
                                  <Text
                                    className={countryName()}
                                    encode={false}
                                    field={
                                      country?.name?.jsonValue
                                        ? country?.name?.jsonValue
                                        : country?.nameDefault?.jsonValue
                                    }
                                    tag="p"
                                  />
                                </div>
                                <div className={countryList()}>
                                  {country?.countries?.targetItems?.map(
                                    (countryItem: Countrylist, index: number) => {
                                      const matchingCountryItemName = matchingCountry?.name
                                        ?.jsonValue
                                        ? matchingCountry?.name?.jsonValue
                                        : matchingCountry?.nameDefault?.jsonValue;
                                      const countryItemName = countryItem?.name?.jsonValue
                                        ? countryItem?.name?.jsonValue
                                        : countryItem?.nameDefault?.jsonValue;
                                      const isMatchingCountry =
                                        matchingCountryItemName?.value === countryItemName?.value &&
                                        matchingCountry?.languageName?.jsonValue?.value ===
                                          countryItem?.languageName?.jsonValue?.value;

                                      // use interanl links to conrol languge switching. external links just redirect to sites we haven't built yet.
                                      const linkField = countryItem?.link?.jsonValue;
                                      if (linkField?.value?.linktype == 'internal') {
                                        linkField.value.href = '';
                                      }

                                      return (
                                        <div
                                          className={countryItemLinkWrapper({
                                            showCountryBg: isMatchingCountry,
                                          })}
                                          key={index}
                                        >
                                          <LinkWrapper
                                            aria-label={`Set Country and Language to ${countryItemName?.value} ${countryItem?.languageName?.jsonValue?.value}`}
                                            className={countryItemLink()}
                                            field={countryItem?.link?.jsonValue}
                                            key={index}
                                            showLinkTextWithChildrenPresent={false}
                                            target={countryItem?.link?.jsonValue?.value?.target}
                                            locale={countryItem?.languageCode?.jsonValue?.value?.toLocaleLowerCase()}
                                          >
                                            <div className={countryListItemLinkleft()}>
                                              {countryItem?.flagIcon?.jsonValue?.value?.src && (
                                                <ImageWrapper
                                                  field={countryItem?.flagIcon?.jsonValue}
                                                />
                                              )}
                                              <div className={countryListItemLinkleftInfo()}>
                                                <Text
                                                  className={countryListItemLinkleftInfoTitle()}
                                                  encode={false}
                                                  field={countryItemName}
                                                  tag="p"
                                                />
                                                <Text
                                                  className={countryListItemLinkleftInfoSubTitle()}
                                                  encode={false}
                                                  field={countryItem?.languageName?.jsonValue}
                                                  tag="span"
                                                />
                                              </div>
                                            </div>
                                            <div className={countryListRight()}>
                                              <div className={countrySelection()}>
                                                {isMatchingCountry && (
                                                  <GoogleMaterialSymbol
                                                    className={countryListRightIcon()}
                                                    icon="done"
                                                    variant="outlined"
                                                  />
                                                )}
                                              </div>
                                            </div>
                                          </LinkWrapper>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            )}
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default HeaderMobile;
