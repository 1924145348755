const HeaderQuery = `query($datasource: String!, $language: String!) {
  header: item(language: $language, path: $datasource) {
    ... on Header {
      enableSearch {
        jsonValue
      }
      searchPlaceholderText {
        jsonValue
      }
      logoImage {
        jsonValue
      }
      logoUrl {
        jsonValue
      }
      title {
        jsonValue
      }
      regions {
        ... on MultilistField {
          targetItems {
            ... on Region {
              name: field(name: "name_fb798baf683344aa85409f834be3abf2") {
                jsonValue
              }
              nameDefault: field(name: "name") {
                jsonValue
              }
              countries {
                ... on MultilistField {
                  targetItems {
                    ... on Country {
                      flagIcon {
                        jsonValue
                      }
                      languageCode {
                        jsonValue
                      }
                      languageName {
                        jsonValue
                      }
                      link {
                        jsonValue
                      }
                      name: field(name: "name_4298d6f7a6484585b410aed9c14dbce2") {
                        jsonValue
                      }
                      nameDefault: field(name: "name") {
                        jsonValue
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      mainNavigations {
        ... on MultilistField {
          targetItems {
            ... on PrimaryHeaderNavigation {
              isDropdown {
                jsonValue
              }
              navigationLink {
                jsonValue
              }
              navigationTitle {
                jsonValue
              }
              featuredImage {
                jsonValue
              }
              featuredImageLink {
                jsonValue
              }
              featuredTitle {
                jsonValue
              }
              featuredLinks {
                ... on MultilistField {
                  targetItems {
                    ... on Link_a47c0c2279e947a6936d870d8564e892 {
                      ctaLink {
                        jsonValue
                      }
                    }
                  }
                }
              }
              secondaryNavigations {
                ... on MultilistField {
                  targetItems {
                    ... on SecondaryNavigation {
                      navigationTitle {
                        jsonValue
                      }
                      navigationLink {
                        jsonValue
                      }
                      navigations {
                        ... on MultilistField {
                          targetItems {
                            ... on TertiaryNavigation {
                              navigationTitle {
                                jsonValue
                              }
                              navigationLink {
                                jsonValue
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`;

export default HeaderQuery;
