import React from 'react';

// Lib
import { DartComponents } from 'lib/templates/Feature.Dart.model';
import { ComponentProps } from 'lib/component-props';
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Parser from 'html-react-parser';

export type BazaarVoiceSocialProps = ComponentProps &
  DartComponents.BazaarVoiceSocial.BazaarVoiceSocial;

const BazaarVoiceSocial = (props: BazaarVoiceSocialProps): JSX.Element => {
  const { galleriesHTML } = props?.fields || {};

  if (!props.fields) return <>Bazaar Voice Social</>;

  return (
    <div data-component="authorable/bazaarvoicesocial">
      <Container>
        <>{galleriesHTML ? Parser(galleriesHTML?.value) : ''}</>
      </Container>
    </div>
  );
};

export default BazaarVoiceSocial;
