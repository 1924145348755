// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect, useRef, useState } from 'react';
import { tv } from 'tailwind-variants';
import { sendGTMEvent } from '@next/third-parties/google';

// Lib
import { ComponentProps } from 'lib/component-props';
import { CompositeComponents } from 'lib/templates/Feature.Dart.model';
import useExperienceEditor from 'lib/use-experience-editor';
import Parser from 'html-react-parser';

// Local
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';

export type JumpLinksProps = ComponentProps & CompositeComponents.JumpLinks.JumpLinksList;

export type JumpLinksItem = CompositeComponents.JumpLinks.JumpLink;

// Tailwind
const tailwindVariants = tv({
  slots: {
    base: ['component', 'jumplinks-main', 'z-[99]'],
    jumpLinksWrapper: [
      'jumplinks-wrapper',
      'p-components-jumplink-spacing-small-padding-x',
      'bg-components-jumplink-color-bg-default',
      'flex',
      'flex-col',
      'items-center',
      'text-center',
      'md:py-components-jumplink-spacing-large-padding-y',
      'md:px-components-jumplink-spacing-large-padding-x',
    ],
    jumpLinkItems: [
      'jumplinks-items',
      'flex',
      'flex-row',
      'flex-wrap',
      'justify-center',
      'gap-components-jumplink-spacing-small-link-container-space-between',
      'md:gap-components-jumplink-spacing-large-link-container-space-between',
      'px-components-jumplink-spacing-small-link-container-padding-x',
      'py-components-jumplink-spacing-small-link-container-padding-y',
      'md:px-components-jumplink-spacing-large-link-container-padding-x',
      'md:py-components-jumplink-spacing-large-link-container-padding-y',
    ],
    jumpLinksTopWrapper: ['pt-spacing-spacing-4'],
    jumpLinksTitle: [
      'font-header-small-medium',
      'text-header-small-medium',
      'leading-header-small-medium',
      'md:font-header-large-medium',
      'md:text-header-large-medium',
      'md:leading-header-large-medium',
      'text-components-jumplink-color-title-text',
      'mb-components-top-title-spacing-small-title-margin-bottom',
      'md:mb-spacing-spacing-5',
    ],
    jumpLinksDescription: [
      'font-bodySans-small',
      'text-bodySans-small',
      'leading-bodySans-small',
      'md:font-bodySans-medium',
      'md:text-bodySans-medium',
      'md:leading-bodySans-medium',
      'text-components-jumplink-color-description-text',
      'mb-components-jumplink-spacing-small-title-area-margin-bottom',
      'md:mb-components-jumplink-spacing-large-title-area-margin-bottom',
    ],
    jumpLinksLabel: [
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-components-jumplink-color-link-text-default',
      'md:hover:underline',
      'md:hover:decoration-2',
      'md:hover:underline-offset-8',
      'md:hover:text-components-jumplink-color-link-text-active',
      'group-focus:underline',
      'group-focus:decoration-2',
      'group-focus:underline-offset-8',
      'group-focus:text-components-jumplink-color-link-text-active',
      'group-active:underline',
      'group-active:decoration-2',
      'group-active:underline-offset-8',
      'group-active:text-components-jumplink-color-link-text-active',
      'group-focus-visible:text-components-jumplink-color-link-text-active',
      'group-focus-visible:underline',
      'group-focus-visible:decoration-2',
      'group-focus-visible:underline-offset-8',
      'group-focus-visible:text-components-jumplink-color-link-text-active',
    ],
    jumpLinksLabelLinkWrapper: ['group', 'focus-visible:outline-none'],
    jumpLinksBottomWrapper: [],
  },
  variants: {
    isSticky: {
      true: {
        base: ['sticky', 'w-full', 'top-0'],
      },
      false: {
        base: [],
      },
    },
    isActive: {
      true: {
        jumpLinksLabel: [
          'underline',
          'underline-offset-2',
          '!text-components-jumplink-color-link-text-active',
        ],
      },
      false: {
        jumpLinksLabel: [],
      },
    },
  },
});

const JumpLinks = (props: JumpLinksProps): JSX.Element => {
  const { jumpLinks, title, description } = props?.fields ?? {};

  const componentRef = useRef<HTMLDivElement | null>(null);

  const [isSticky, setIsSticky] = useState(false);

  const isEE = useExperienceEditor();

  const header = typeof window !== 'undefined' ? window.document.getElementById('header') : null;

  const {
    base,
    jumpLinksWrapper,
    jumpLinkItems,
    jumpLinksTopWrapper,
    jumpLinksTitle,
    jumpLinksDescription,
    jumpLinksBottomWrapper,
    jumpLinksLabel,
    jumpLinksLabelLinkWrapper,
  } = tailwindVariants({ isSticky: isSticky });

  useEffect(() => {
    const handleScroll = () => {
      if (jumpLinks && jumpLinks.length > 0) {
        const elements = jumpLinks
          .map((jumpLink) => {
            const anchorValue = (jumpLink as JumpLinksItem).fields?.jumpLinkAnchor?.value;
            if (anchorValue?.anchor) {
              return document.getElementById(anchorValue.anchor);
            }
            return null;
          })
          .filter(Boolean);

        if (elements.length === 0) return;

        const sortedElements = elements.sort(
          (a: HTMLElement, b: HTMLElement) => a.offsetTop - b.offsetTop
        );
        const lastElement = sortedElements[sortedElements.length - 1];

        if (!lastElement || !componentRef.current) return;

        const lastElementRect = lastElement.getBoundingClientRect();
        const lastElementBottom = lastElementRect.bottom + window.scrollY;
        const jumpLinkElementRect = componentRef.current?.getBoundingClientRect();
        const jumpLinkElementTop = jumpLinkElementRect.top;
        const isInView = window.scrollY > jumpLinkElementTop && window.scrollY < lastElementBottom;

        setIsSticky(isInView);
        if (header) {
          if (isInView) {
            header.classList.add('visually-none');
          } else {
            header.classList.remove('visually-none');
          }
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setIsSticky, isSticky, jumpLinks, header]);

  const handleClick = (targetElement: JumpLinksItem) => {
    const target = targetElement?.fields?.jumpLinkAnchor?.value?.anchor;
    const toBeScrolled = document.getElementById(target as string);
    const jumpLinksWrapperElement = componentRef.current;

    if (toBeScrolled && jumpLinksWrapperElement) {
      const wrapperRect = jumpLinksWrapperElement.getBoundingClientRect();
      const targetRect = toBeScrolled.getBoundingClientRect();
      const scrollPosition = targetRect.top - wrapperRect.height + window.scrollY;

      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });

      toBeScrolled.focus({ preventScroll: true });
    }
    sendGTMEvent({
      event: 'link',
      type: 'jump',
      'gtm.element.dataset.gtmLinkName': targetElement?.fields?.jumpLinkAnchor?.value?.text,
      'gtm.element.dataset.gtmLinkUrl': target,
    });
  };

  return (
    <div
      className={base({ isSticky: isSticky })}
      data-component="authorable/jumplinks"
      id="jump-links"
      ref={componentRef}
    >
      <div className={jumpLinksWrapper()}>
        {(title?.value || description?.value) && (
          <div className={jumpLinksTopWrapper()}>
            <Text className={jumpLinksTitle()} encode={false} field={title} tag="h2"></Text>
            <Text
              className={jumpLinksDescription()}
              encode={false}
              field={description}
              tag="p"
            ></Text>
          </div>
        )}
        <div className={jumpLinksBottomWrapper()}>
          <ul className={jumpLinkItems()}>
            {jumpLinks?.map((jumpLinkItem: JumpLinksItem, index: number) => {
              return (
                <li key={index}>
                  {isEE && jumpLinkItem?.fields?.jumpLinkAnchor?.value?.href ? (
                    <LinkWrapper
                      field={{
                        href: 'javascript:void(0)',
                        title: jumpLinkItem?.fields?.jumpLinkAnchor?.value?.text,
                        text: jumpLinkItem?.fields?.jumpLinkAnchor?.value?.text,
                      }}
                      onClick={() => handleClick(jumpLinkItem)}
                    ></LinkWrapper>
                  ) : (
                    <LinkWrapper
                      field={{
                        href: 'javascript:void(0)',
                        title: jumpLinkItem?.fields?.jumpLinkAnchor?.value?.text,
                      }}
                      onClick={() => handleClick(jumpLinkItem)}
                      editable={false}
                      className={jumpLinksLabelLinkWrapper()}
                    >
                      <span className={jumpLinksLabel()}>
                        {Parser(jumpLinkItem?.fields?.jumpLinkAnchor?.value?.text as string)}
                      </span>
                    </LinkWrapper>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default JumpLinks;
