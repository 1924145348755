import { pathExtractor } from 'lib/extract-path';
import { usePathname } from 'next/navigation';

/**
 * The path name on the server includes our rewritten path, which is different from on the client
 * This causes a hydration error.  Use the pathExtractor to get the real path.
 * @returns
 */
export function useRealPathName() {
  const pathName = usePathname();
  const path = pathExtractor.extract({ path: pathName });
  return path;
}
