const ArticleListingItemQuery = `query($routePath: String!, $language: String!, $site: String!){
    layout(language: $language, routePath: $routePath, site: $site) {
        item {
          template{
            id
          }
          ancestors(hasLayout:true,includeTemplateIDs:"75873e02-6de2-467f-bce6-1ec481e48829"){
            name
          }
        }
      }
  }`;
export default ArticleListingItemQuery;
