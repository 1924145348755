const GetLanguageCode = (sitecoreLanguageCode: string | undefined) => {
  // Default country code
  const defaultLanguageCode = 'en';

  if (!sitecoreLanguageCode) {
    return defaultLanguageCode;
  }

  // Split the input string using '-'
  if (sitecoreLanguageCode.includes('-')) {
    const parts = sitecoreLanguageCode.split('-');
    return parts[0];
  } else {
    return defaultLanguageCode;
  }
};

export default GetLanguageCode;
