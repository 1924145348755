// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect, useState } from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { DartComponents } from 'lib/templates/Feature.Dart.model';
import { ComponentProps } from 'lib/component-props';

// Local
import Button from 'helpers/Button/Button';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import SVG from 'helpers/SVG/SVG';
import { ComponentVariants } from 'lib/context/ComponentVariants';
import fallback from 'lib/fallback/fallback';

export type TopBarProps = ComponentProps & DartComponents.TopBar.TopBar;

type Theme = 'Dark' | 'Light' | undefined;

const tailwindVariants = tv({
  slots: {
    base: [
      'top-bar',
      'flex',
      'md:flex-row',
      'flex-col',
      'md:py-components-banner-spacing-large-padding-y',
      'py-spacing-spacing-3',
      'px-spacing-spacing-3',
      'relative',
      'top-0',
      'gap-components-banner-spacing-large-content-space-between',
      'md:items-center',
      'items-start',
      'justify-center',
      'z-10',
    ],
    contentContainer: [
      'flex',
      'md:flex-row',
      'flex-col',
      'md:items-center',
      'items-start',
      'justify-center',
      'gap-components-banner-spacing-small-title-margin-bottom',
      'md:gap-0',
    ],
    headlineText: [
      'font-bodySans-small-semibold',
      'leading-bodySans-small-semibold',
      'text-bodySans-small-semibold',
      'md:bodySans-medium-semibold',
      'md:leading-bodySans-medium-semibold',
      'md:text-bodySans-medium-semibold',
    ],
    descriptionText: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'md:font-bodySans-medium',
      'md:leading-bodySans-medium',
      'md:text-bodySans-medium',
    ],
    ctaContainer: [
      'flex',
      'flex-col-reverse',
      'gap-components-promo-spacing-large-buttons-space-between',
      'md:items-center',
    ],
    iconTitileWrapper: [
      'icon-titile-wrapper',
      'flex-row',
      'flex',
      'gap-components-banner-spacing-icon-margin-left',
      'items-center',
      'mr-components-banner-spacing-large-content-space-between',
    ],
    closeButtonStyle: [
      'absolute',
      'md:top-1/2',
      'md:transform',
      'md:right-[25px]',
      'md:-translate-y-1/2',
      'top-[12px]',
      'right-[12px]',
      'cursor-pointer',
      'md:py-components-button-spacing-large-icon-only-padding-y',
      'md:px-components-button-spacing-large-icon-only-padding-x',
      'md:hover:rounded-themes-radius-large-button',
      'md:hover:bg-components-button-color-text-brand-hover-bg-icon-only',
    ],
    topBarIcon: ['icon'],
    ctaSpacing: [
      '!py-components-button-spacing-compressed-default-padding-y',
      '!px-components-button-spacing-compressed-default-padding-x',
    ],
  },
  variants: {
    theme: {
      Dark: {
        base: ['bg-components-banner-color-bg-alert'],
        headlineText: ['text-components-banner-color-title-alert'],
        descriptionText: ['text-components-banner-color-body-alert'],
        topBarIcon: ['[&_svg_*]:fill-components-banner-color-icon-alert'],
      },
      Light: {
        base: ['bg-components-banner-color-bg-default'],
        headlineText: ['text-components-banner-color-title-default'],
        descriptionText: ['text-components-banner-color-body-default'],
        topBarIcon: ['[&_svg_*]:fill-components-banner-color-icon-default'],
      },
    },
  },
});

const TopBar = (props: TopBarProps): JSX.Element => {
  const [showTopBar, setShowTopBar] = useState<boolean>(false);
  const { description, title, primaryCTA, icon, closeButton, primaryCTAType, primaryCTAColor } =
    props?.fields || {};
  const { theme } = props?.params || {};
  const componentVariants = ComponentVariants();

  // Add fallback component variant color
  const fallbackComponentVariantColor = fallback?.topBar?.value;
  const fallbackComponentVariantType = fallback?.topBar?.type;

  const {
    base,
    contentContainer,
    headlineText,
    descriptionText,
    ctaContainer,
    iconTitileWrapper,
    closeButtonStyle,
    topBarIcon,
    ctaSpacing,
  } = tailwindVariants({
    theme: theme as Theme,
  });

  useEffect(() => {
    setShowTopBar(!sessionStorage.getItem(`topBanner_${props?.rendering?.uid}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeBanner = () => {
    sessionStorage.setItem(`topBanner_${props?.rendering?.uid}`, 'true');
    setShowTopBar(false);
  };
  if (!props.fields) return <>TopBar Component</>;

  if (!showTopBar) return <></>;

  return (
    <div className={base()} data-component="authorable/topbar">
      <div className={contentContainer()}>
        <div className={iconTitileWrapper()}>
          {icon?.value?.src && (
            <SVG className={topBarIcon()} svg="outline" url={icon?.value?.src} />
          )}
          {title?.value && (
            <Text className={headlineText()} encode={false} field={title} tag="h2" />
          )}
        </div>
        {description?.value && (
          <RichTextA11yWrapper className={descriptionText()} field={description} />
        )}
      </div>
      <div className={ctaContainer()}>
        {primaryCTA?.value?.text && primaryCTA?.value?.href && (
          <>
            <Button
              childClass={ctaSpacing()}
              href={primaryCTA.value.href}
              iconRight="chevron_right"
              label={primaryCTA.value.text}
              tag="a"
              target={primaryCTA?.value?.target}
              title={primaryCTA?.value?.title}
              // The design requires an outline CTA but field name is primaryCTA,
              // so for that we have added a fallback as an outline value,
              // so if there is no value in sitecore field, it will take the outline value.
              type={primaryCTAType?.value || fallbackComponentVariantType}
              color={
                primaryCTAColor?.value ||
                (theme === 'Light'
                  ? componentVariants?.topBar?.primaryCta?.color
                  : fallbackComponentVariantColor)
              }
            />
          </>
        )}
      </div>
      {closeButton?.value && (
        <div className={closeButtonStyle()} onClick={closeBanner}>
          <GoogleMaterialSymbol
            icon="close"
            className={
              theme === 'Light'
                ? `fill-components-banner-color-icon-close-default`
                : 'fill-components-banner-color-icon-close-alert'
            }
          ></GoogleMaterialSymbol>
        </div>
      )}
    </div>
  );
};
export default TopBar;
