// Global
import { GetStaticComponentProps, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import graphQLClientFactory from 'lib/graphql-client-factory';
import React from 'react';
import { tv } from 'tailwind-variants';

// Lib

// Local
import { BreadcrumbDataType } from './Breadcrumb.types';
import BreadcrumbQuery from './Breadcrumb.graphql';
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';

const tailwindVariants = tv({
  slots: {
    base: [
      'px-components-breadcrumb-spacing-small-padding-x',
      'md:px-components-breadcrumb-spacing-large-padding-x',
      'bg-components-breadcrumb-color-bg',
      'flex-row',
      'flex',
      'gap-components-breadcrumb-spacing-small-space-between',
      'items-center',
      'justify-start',
      'no-scrollbar',
      'py-components-breadcrumb-spacing-small-padding-y',
      'md:py-components-breadcrumb-spacing-large-padding-y',
      'w-full',
      'whitespace-nowrap',
    ],
    currentPageContainer: [
      'flex-row',
      'flex',
      'gap-components-breadcrumb-spacing-large-space-between',
      'items-center',
      'justify-start',
      'relative',
      'shrink-0',
    ],
    currentPageText: [
      'flex',
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'items-center',
      'justify-start',
      'relative',
      'text-components-breadcrumb-color-breadcrumb-link-active',
      'text-left',
      'tracking-tighter',
    ],
    link: [
      'flex',
      'items-center',
      'justify-start',
      'relative',
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'text-components-breadcrumb-color-breadcrumb-link-default',
      'text-left',
      'tracking-tighter',
      'hover:text-components-breadcrumb-color-breadcrumb-link-hover',
      'hover:underline',
    ],
    linkContainer: [
      'flex-row',
      'flex',
      'gap-components-breadcrumb-spacing-large-space-between',
      'items-center',
      'justify-start',
      'relative',
      'shrink-0',
    ],
    linkIcon: [
      'fill-components-breadcrumb-color-icon-divider',
      'shrink-0',
      '[&>svg]:w-4',
      '[&>svg]:h-4',
    ],
    wrapper: [
      'flex',
      'flex-1',
      'flex-nowrap',
      'flex-row',
      'gap-components-breadcrumb-spacing-small-space-between',
      'items-center',
      'justify-start',
      'no-scrollbar',
      'overflow-y-clip',
      'overflow-x-auto',
      'relative',
      'whitespace-nowrap',
    ],
    componentBG: ['bg-components-breadcrumb-color-bg'],
    gradient: ['absolute', 'w-[12px]', 'h-[36px]', 'right-[16px]'],
  },
});

const Breadcrumb = (staticProps: BreadcrumbDataType): JSX.Element => {
  const { ancestors, breadcrumbTitle } = staticProps?.staticProps?.currentPage || {};

  const { componentName, dataSource } = staticProps?.rendering || {};
  const {
    base,
    currentPageContainer,
    currentPageText,
    link,
    linkIcon,
    linkContainer,
    wrapper,
    componentBG,
    gradient,
  } = tailwindVariants();

  return (
    <div className={componentBG()}>
      <Container>
        <div data-component="authorable/landmarks/breadcrumbs" data-testid="breadcrumbs">
          <div className={base()}>
            <nav className={wrapper()} aria-label="Breadcrumb">
              <ol className="flex">
                {ancestors
                  ?.slice()
                  .reverse()
                  .map((itm, index: React.Key | null | undefined) => {
                    let hideBreadcrumb = false;

                    itm?.disabledLinkNames?.names?.map((disitm) => {
                      if (disitm?.field?.disabled?.value === 'breadcrumb') hideBreadcrumb = true;
                    });

                    if (hideBreadcrumb) return;

                    return (
                      itm?.breadcrumbTitle?.jsonValue?.value && (
                        <li className={linkContainer()} key={index}>
                          <LinkWrapper
                            className={link()}
                            field={{
                              value: {
                                href: itm?.pageUrl?.link,
                                text: itm?.breadcrumbTitle?.jsonValue?.value,
                                title: itm?.breadcrumbTitle?.jsonValue?.value,
                              },
                            }}
                            gtmEvent={{
                              event: 'link',
                              type: 'breadcrumb',
                              'gtm.element.dataset.gtmDatasourceId': dataSource,
                              'gtm.element.dataset.gtmComponentName': componentName,
                            }}
                          />
                          <GoogleMaterialSymbol
                            className={linkIcon()}
                            icon="chevron_right"
                            aria-hidden={true}
                          />
                        </li>
                      )
                    );
                  })}
                <li className={currentPageContainer()}>
                  <Text
                    className={currentPageText()}
                    encode={false}
                    field={{
                      value: breadcrumbTitle?.jsonValue?.value,
                    }}
                    tag="span"
                  />
                </li>
              </ol>
            </nav>
            <div
              className={gradient()}
              style={{
                background:
                  'linear-gradient(90deg, rgba(255, 255, 255, 0.00) -4.17%, #FFF 104.17%)',
              }}
            ></div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Breadcrumb;

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  const graphQLClient = graphQLClientFactory({});
  const result = await graphQLClient.request<unknown>(BreadcrumbQuery, {
    datasource: rendering.dataSource,
    params: rendering.params,
    language: layoutData?.sitecore?.context?.language,
    itemID: layoutData?.sitecore?.route?.itemId,
  });
  return {
    staticProps: result,
  };
};
