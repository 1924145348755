// Global
import {
  ImageField,
  LinkField,
  RichText,
  Text,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import axios from 'axios';
import { useRouter } from 'next/router';
import React, { LegacyRef, useContext, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { tv } from 'tailwind-variants';
import { useI18n } from 'next-localization';

// Lib
import { ComponentProps } from 'lib/component-props';
import { useTheme } from 'lib/context/ThemeContext';
import { DartComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Button from 'helpers/Button/Button';
import { GlobalContext } from 'helpers/GlobalContext/globalContext';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import SVG from 'helpers/SVG/SVG';
import TextTokenReplacer from 'helpers/TextTokenReplacer/TextTokenReplacer';
import GetLanguageCode from 'helpers/GetLanguageCode/GetLanguageCode';
import GetGoogleCaptcha from 'helpers/GoogleCaptcha/GetGoogleCaptcha';
import { apiMockData } from 'src/stories/authorable/MosquitoForecastResult/MosquitoForecastResult.mock-data';

export type MosquitoForecastResultProps = ComponentProps &
  DartComponents.MosquitoForecastResult.MosquitoForecastResult;

export type SubscribeAPIConfig = {
  countryCode: string;
  errorUrl: string;
  successUrl: string;
  url: string;
};

const tailwindVariants = tv({
  slots: {
    consentCheckbox: [
      'bg-forms-checkbox-color-checkbox-default',
      'bg-gray-100',
      'border-gray-300',
      'border',
      'h-6',
      'w-6',
      'rounded-forms-checkbox-spacing-radius',
      'text-blue-600',
    ],
    consentContainer: [
      'flex-col',
      'flex',
      'gap-10',
      'justify-center',
      'items-center',
      'md:items-start',
      'sm:max-w-[864px]',
      'md:max-w-[976px]',
      'pb-10',
      'px-10',
      'md:flex-row',
      'md:p-0',
    ],
    consentSection: [
      'flex',
      'gap-forms-checkbox-spacing-space-between',
      'items-start',
      'md:items-start',
      'mb-4',
    ],
    consentText: [
      'flex-1',
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-bodySans-medium',
      'text-components-contact-us-promo-color-default-title',
      '[&_a]:font-bodySans-medium-underline',
      '[&_a]:leading-bodySans-medium-underline',
      '[&_a]:text-bodySans-medium-underline',
      '[&_a]:text-components-hyperlink-color-dark-default',
      '[&_a]:inline-block',
    ],
    countText: [
      'font-header-medium-medium',
      'leading-header-medium-medium',
      'mb-components-contact-us-promo-spacing-large-margin-y',
      'text-center',
      'text-components-contact-us-promo-color-default-title',
      'text-header-medium-medium',
      '[&_span]:inline-block',
      '[&_span]:leading[120%]',
      '[&_svg]:cursor-pointer',
      '[&_svg]:fill-colors-accessible-brand-2-400',
      '[&_svg]:inline-block',
      'md:font-header-large-medium',
      'md:leading-header-large-medium',
      'md:text-header-large-medium',
      'px-spacing-padding-large-3',
      'md:px-0',
    ],
    emailSection: ['p-10'],
    emailTitleText: [
      'font-header-small-medium',
      'leading-header-small-medium',
      'mb-10',
      'text-center',
      'text-components-contact-us-promo-color-default-title',
      'text-header-small-medium',
    ],
    forecastCard: [
      'px-spacing-padding-large-1',
      'py-spacing-padding-large-3',
      'bg-themes-background-color-white',
      'rounded-lg',
      'border',
      'border-themes-stroke-color-light',
      'flex',
      'flex-col',
      'justify-start',
      'items-center',
      'gap-2',
      'inline-flex',
      'flex-1',
    ],
    forecastContainer: [
      'flex-col',
      'flex',
      'gap-components-contact-us-promo-spacing-large-margin-y',
      'items-center',
      'justify-center',
      'max-w-full',
      'self-stretch',
    ],
    forecastDayText: [
      'font-bodySans-large-semibold',
      'line-bodySans-large-semibold',
      'text-bodySans-large-semibold',
      'mb-3',
      'text-themes-text-color-dark',
    ],
    forecastLevelText: [
      'font-bodySans-large-semibold',
      'line-bodySans-large-semibold',
      'text-bodySans-large-semibold',
      'text-themes-text-color-dark',
    ],
    forecastPostCodeText: [
      'font-header-large-medium',
      'ml-4',
      'mr-spacing-margin-large-1',
      'text-colors-accessible-brand-2-400',
      'text-header-large-medium',
    ],
    forecastSection: [
      'w-full',
      'h-full',
      'pl-6',
      'pr-6',
      'md:pl-0',
      'md:pr-0',
      'md:justify-center',
      'items-start',
      'gap-spacing-space-between-large-2',
      'inline-flex',
      'overflow-x-auto',
    ],
    forecastSVG: ['[&_svg]:h-[88px]', '[&_svg]:w-[88px]'],
    forecastValueText: [
      'font-bodySans-small',
      'line-bodySans-small',
      'text-bodySans-small',
      'mb-3',
      'text-themes-text-color-dark',
    ],
    formContainer: [
      'flex-col',
      'flex',
      'gap-5',
      'items-stretch',
      'md:items-start',
      'justify-center',
      'relative',
      'md:flex-row',
    ],
    iconContainer: ['flex', 'gap-4'],
    emailInput: [
      'border',
      'min-h-components-text-field-input-min-height',
      'px-components-text-field-input-padding-x',
      'py-components-text-field-input-padding-y',
      'rounded-full',
      'w-full',
      'md:w-[280px]',
      'text-bodySans-medium',
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-components-text-field-input-field-default',
      'focus:text-components-text-field-input-field-focused',
      'focus-within:border-2',
      'focus-within:border-components-text-field-border-focused',
      'focus-within:outline-none',
      'focus-within:bg-components-text-field-bg-focused',
    ],
    offCastImage: ['mx-auto'],
    resultContainer: [
      'flex',
      'flex-col',
      'gap-4',
      'items-center',
      'md:pt-spacing-padding-large-5',
      'md:pb-components-contact-us-promo-spacing-large-padding-y',
      'md:px-components-contact-us-promo-spacing-large-padding-x',
    ],
    shareIcon: [
      'bg-components-button-color-filled-brand-default-bg',
      'p-components-button-spacing-large-icon-only-padding-x',
      'rounded-themes-radius-large-button',
      '[&_*]:!fill-components-button-color-filled-brand-default-icon',
      '[&_svg]:!h-6',
      '[&_svg]:!w-6',
    ],
    shareText: [
      'font-header-small-medium',
      'leading-header-small-medium',
      'text-header-small-medium',
      'text-themes-text-color-dark',
      'text-center',
    ],
    shareTextContainer: [
      'flex',
      'flex-col',
      'gap-12',
      'items-center',
      'justify-center',
      'p-spacing-padding-large-3',
      'md:flex-row',
    ],
    errorText: [
      'font-bodySans-xSmall',
      'text-bodySans-xSmall',
      'leading-bodySans-xSmall',
      'text-components-text-field-supporting-text-destructive',
      'mt-components-text-field-supporting-text-padding-top',
    ],
  },
  variants: {
    showResult: {
      true: {
        resultContainer: ['flex'],
      },
      false: {
        resultContainer: ['hidden'],
      },
    },
    showError: {
      true: {
        errorText: ['block'],
      },
      false: {
        errorText: ['hidden'],
      },
    },
    showEmailError: {
      true: {
        emailInput: [
          'border-components-text-field-border-destructive',
          'focus-within:border-components-text-field-border-destructive',
        ],
      },
      false: {
        emailInput: [
          'border-components-text-field-border-default',
          'focus-within:border-components-text-field-border-focused',
        ],
      },
    },
    promotionEmpty: {
      true: {
        consentSection: ['w-full', 'lg:w-[346px]'],
      },
    },
  },
});

const MosquitoForecastResult = (props: MosquitoForecastResultProps): JSX.Element => {
  const globalContext = useContext(GlobalContext);
  const { sitecoreContext } = useSitecoreContext();
  const { themeName } = useTheme();
  const router = useRouter();

  if (props?.params?.isInStorybook === 'true') {
    globalContext?.setForecastData(apiMockData);
  }
  const _reCaptchaRef = React.createRef();

  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const [consentCheck, setConsentCheck] = useState(false);
  const [promotionCheck, setPromotionCheck] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showVerificationError, setShowVerificationError] = useState(false);
  const [subscribeEmail, setSubscribeEmail] = useState('');
  const i18n = useI18n();
  const emailMsg = i18n.t('EmailAddressRequired');
  const failedToFetchMsg = i18n.t('FailedToFetch');
  const validEmailMsg = i18n.t('ValidEmailAddress');
  const verificationReqMsg = i18n.t('VerificationRequired');

  if (!props.fields) return <>MosquitoForecastPromo Component</>;

  const id = props?.params?.RenderingIdentifier;

  const {
    emailPlaceholderText,
    emailTitle,
    enableSocial,
    forecastLogo,
    newsletterAcceptanceText,
    promotionsOffersText,
    primaryCTA,
    primaryCTAColor,
    primaryCTAType,
    socialLinks,
    socialTitle,
    title,
    disableCaptcha,
  } = props?.fields || {};

  const isPromotionNotEmpty = promotionsOffersText?.value?.toString() != '' ? true : false;

  const {
    consentCheckbox,
    consentContainer,
    consentSection,
    consentText,
    countText,
    emailSection,
    emailTitleText,
    forecastCard,
    forecastContainer,
    forecastDayText,
    forecastLevelText,
    forecastPostCodeText,
    forecastSection,
    forecastSVG,
    forecastValueText,
    formContainer,
    iconContainer,
    emailInput,
    errorText,
    offCastImage,
    resultContainer,
    shareIcon,
    shareText,
    shareTextContainer,
  } = tailwindVariants({
    showResult:
      globalContext?.forecastData &&
      globalContext?.forecastData.rawIndexValue &&
      globalContext.forecastData?.rawIndexValue?.length
        ? true
        : false,

    showError:
      (!subscribeEmail && showEmailError) ||
      ((!consentCheck || !captchaValue) && showVerificationError),
    showEmailError: !subscribeEmail && showEmailError,
    promotionEmpty: isPromotionNotEmpty,
  });

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    let emailVal = e.currentTarget.value;
    if (!validateEmail(e.currentTarget.value)) emailVal = '';
    setSubscribeEmail(emailVal);
  };

  const onChaptchaChange = (value: string | null) => {
    setCaptchaValue(value);
  };

  const onCheckConsent = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConsentCheck(e.currentTarget.checked);
  };

  const onPromotionConsent = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromotionCheck(e.currentTarget.checked);
  };

  const onEditPostalCode = () => {
    const toBeScrolled = document.getElementById('promoSection');
    const forcastSearchInput = document.getElementById('forcastSearchInput');

    if (toBeScrolled) {
      const targetRect = toBeScrolled.getBoundingClientRect();
      const scrollPosition = targetRect.top;
      const header =
        typeof window !== 'undefined' ? window.document.getElementById('header') : null;

      if (header) {
        const currentScrollPosition = window.scrollY || window.pageYOffset;
        const newPosition = currentScrollPosition + scrollPosition - header?.offsetHeight;
        window.scrollTo({
          top: newPosition,
          behavior: 'smooth',
        });
        forcastSearchInput?.focus({ preventScroll: true });
      }
    }
  };

  const absolutePath = function (href: string) {
    const link = document.createElement('a');
    link.href = href;
    return link.protocol + '//' + link.host + link.pathname + link.search + link.hash;
  };

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onSubscribeClick = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowEmailError(false);
    setShowVerificationError(false);

    if (!subscribeEmail) {
      setShowEmailError(true);
      return;
    }

    if (!consentCheck || !captchaValue) {
      setShowVerificationError(true);
      return;
    }

    if (!consentCheck || !captchaValue || !subscribeEmail) return;

    const { successUrl, errorUrl, url, countryCode } =
      sitecoreContext?.mosquitoForecastSFMC as SubscribeAPIConfig;

    try {
      const payload = {
        successURL: absolutePath(successUrl),
        errorURL: absolutePath(errorUrl),
        countryCode: countryCode,
        latitude: globalContext?.locationSearchData?.latitude,
        longitude: globalContext?.locationSearchData?.longitude,
        emailAddress: subscribeEmail,
        apiURL: url,
        emailMsg,
        failedToFetchMsg,
        placeId: globalContext?.locationSearchData?.placeId,
        captchaValue: captchaValue,
        TermsAndConditions: consentCheck,
      };
      const payloadWithPromotion = {
        ...payload,
        Promotions_Offers: promotionCheck,
      };

      const apiResponse = await axios.get('/api/climatecompany/subscribe', {
        params: isPromotionNotEmpty ? payloadWithPromotion : payload,
      });

      return router.push(apiResponse.request.responseURL);
    } catch (error) {
      return error;
    }
  };

  return (
    <div
      id={id || undefined}
      data-component="authorable/mosquitoforecastpromo"
      tabIndex={id ? -1 : 1}
    >
      <div className={resultContainer()} id="forecastResultSection">
        {forecastLogo?.value?.src && (
          <ImageWrapper className={offCastImage()} field={forecastLogo} />
        )}
        <div className={forecastContainer()}>
          {title?.value && globalContext?.locationSearchData.postalCode && (
            <div className={countText()}>
              <TextTokenReplacer
                field={title}
                tokens={['{count}']}
                tokenValues={[globalContext?.locationSearchData.postalCode.toString()]}
                tokenValuesClass={forecastPostCodeText()}
              />
              <span onClick={onEditPostalCode}>
                <GoogleMaterialSymbol icon="edit-fill" />
              </span>
            </div>
          )}
          <div className={forecastSection()}>
            {globalContext?.forecastData.indexCategory &&
              globalContext?.forecastData?.indexCategory?.length &&
              globalContext?.forecastData.indexCategory.map((itemResult: string, index) => {
                const dateObj = new Date(0);

                if (
                  globalContext?.forecastData.validTimeUtc &&
                  globalContext?.forecastData.validTimeUtc?.length
                )
                  dateObj.setUTCSeconds(globalContext?.forecastData.validTimeUtc[index]);

                return (
                  <div className={forecastCard()} key={index}>
                    {globalContext?.forecastData.dayOfWeek &&
                      globalContext?.forecastData?.dayOfWeek.length && (
                        <div className={forecastDayText()}>
                          {i18n.t(globalContext?.forecastData.dayOfWeek[index]) ||
                            globalContext?.forecastData.dayOfWeek[index]}
                        </div>
                      )}
                    {globalContext?.forecastData.validTimeUtc && (
                      <div className={forecastValueText()}>
                        {dateObj.getUTCMonth() + 1}/{dateObj.getUTCDate()}
                      </div>
                    )}
                    <SVG
                      className={forecastSVG()}
                      svg={`ForecastProgress/Ratings=${itemResult},Brand=${themeName}`}
                    />
                    {globalContext?.forecastData.indexCategory &&
                      globalContext?.forecastData.indexCategory?.length && (
                        <div className={forecastLevelText()}>
                          {i18n.t(globalContext?.forecastData.indexCategory[index]) ||
                            globalContext?.forecastData.indexCategory[index]}
                        </div>
                      )}
                  </div>
                );

                return <></>;
              })}
          </div>
        </div>
        <div className={emailSection()}>
          {title?.value && (
            <div className={emailTitleText()}>
              <Text field={emailTitle} tag="h2" />
            </div>
          )}
          <form className={formContainer()} onSubmit={onSubscribeClick}>
            <div>
              <input
                className={emailInput()}
                onChange={onChangeEmail}
                placeholder={emailPlaceholderText?.value}
                type="text"
              />
              <p className={errorText()}>{showEmailError && validEmailMsg}</p>
            </div>
            {primaryCTA?.value.text && (
              <Button
                //As the design requires a filled CTA, we do not need to add a fallback since
                //the button component has a default variant that displays a filled CTA.
                label={primaryCTA?.value.text}
                target={primaryCTA?.value?.target}
                type={primaryCTAType?.value as string}
                color={primaryCTAColor?.value}
              />
            )}
          </form>
        </div>
        <div className={consentContainer()}>
          <div>
            {!disableCaptcha?.value && (
              <ReCAPTCHA
                onChange={onChaptchaChange}
                ref={_reCaptchaRef as LegacyRef<ReCAPTCHA>}
                sitekey={GetGoogleCaptcha()}
                style={{ display: 'inline-block' }}
                hl={GetLanguageCode(sitecoreContext?.language)}
              />
            )}

            <p className={errorText()}>
              {showVerificationError && !captchaValue && verificationReqMsg}
            </p>
          </div>
          <div>
            <div className={consentSection()}>
              <input
                className={consentCheckbox()}
                id="default-checkbox"
                onChange={onCheckConsent}
                type="checkbox"
              />
              {newsletterAcceptanceText?.value && (
                <label className={consentText()} htmlFor="default-checkbox">
                  <RichText field={newsletterAcceptanceText} />
                </label>
              )}
            </div>

            <p className={errorText()}>
              {showVerificationError && !consentCheck && verificationReqMsg}
            </p>
          </div>
          {isPromotionNotEmpty && (
            <div>
              <div className={consentSection()}>
                <input
                  className={consentCheckbox()}
                  id="default-checkbox"
                  onChange={onPromotionConsent}
                  type="checkbox"
                />
                {promotionsOffersText?.value && (
                  <label className={consentText()} htmlFor="default-checkbox">
                    <RichText field={promotionsOffersText} />
                  </label>
                )}
              </div>
            </div>
          )}
        </div>
        {socialTitle?.value && (
          <div className={shareTextContainer()}>
            {socialTitle.value && (
              <div className={shareText()}>
                <Text field={socialTitle} tag="h2" />
              </div>
            )}
            <div className={iconContainer()}>
              {enableSocial?.value &&
                socialLinks?.length &&
                socialLinks?.map((socialLink, index) => {
                  const { socialIcon: socialIconImage, socialUrl } = socialLink.fields || {};
                  const url = socialIconImage as ImageField;

                  return (
                    <LinkWrapper
                      className={shareIcon()}
                      field={socialUrl as LinkField}
                      key={index}
                      showLinkTextWithChildrenPresent={false}
                    >
                      <SVG url={url?.value?.src} />
                    </LinkWrapper>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MosquitoForecastResult;
