export interface ArticleResultsListingItemName {
  layout: Layout;
}

interface Layout {
  item: Item;
}

interface Item {
  template: Template;
  ancestors: Ancestor[];
}

interface Template {
  id: string;
}

interface Ancestor {
  name: string;
}
