export interface ContainerProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  dataComponent?: string;
  fullWidth?: boolean;
  id?: string;
}

const Container = ({
  children,
  className = '',
  dataComponent,
  fullWidth = false,
  id,
}: ContainerProps): JSX.Element => (
  <div
    className={`container ${fullWidth ? 'max-w-none' : 'mx-auto'}${
      className ? ` ${className}` : ''
    }`}
    data-component={dataComponent}
    id={id ? id : undefined}
  >
    {children}
  </div>
);

export default Container;
