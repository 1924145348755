const WhereToBuyQuery = `query( $language: String!, $root: String!) {
    search(
          where: {
            AND: [
              {
                name: "_templates"
                value: "E3396219-CDFA-4F7B-B6E8-08EC87ED410B"
                operator: EQ
              }
              {
                name: "_path"
                value: $root
                operator: CONTAINS
              }
              { name: "_language", value: $language, operator: EQ }
            ]
          }
          first: 100
        ) {
          total
          results {
            ... on ProductDetailPage {
              productInformation {
                targetItem {
                  ... on ProductInformation_a8fc2706d01c425887aa3016a9f2d74d {
                    variantItems{
                      jsonValue
                    }
                  }
                }
              }
            }
          }
        }
    }`;
export default WhereToBuyQuery;
