// Global
import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';

export type CtaBorderAnimationProps = {
  fields?: LinkField | undefined;
};

const CtaBorderAnimation = (props: CtaBorderAnimationProps): JSX.Element => {
  return (
    <div className="border-y-0 border-white">
      <LinkWrapper
        aria-label={props?.fields?.value?.text}
        showLinkTextWithChildrenPresent={false}
        field={props?.fields}
        className={
          'font-semibold text-components-button-color-text-white-hover-text hover:components-button-color-text-white-hover-text  mb-components-header-spacing-large-mega-menu-featured-link-space-between mr-components-header-spacing-large-mega-menu-featured-link-space-between relative group mb-components-header-spacing-large-mega-menu-featured-link-space-between block'
        }
        target={props?.fields?.value?.target}
      >
        {props?.fields?.value?.text}
        <span
          className="absolute left-0 bottom-[-5px] w-0 h-[2px] bg-components-button-color-text-white-default-text group-hover:w-full transition-all duration-400  
                ease-out"
        ></span>
      </LinkWrapper>
    </div>
  );
};

export default CtaBorderAnimation;
