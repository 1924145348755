// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { ComponentProps } from 'lib/component-props';
import { ALL_THEMES, useTheme } from 'lib/context/ThemeContext';
import { HeroComponents } from 'lib/templates/Feature.Dart.model';
import { ComponentVariants } from 'lib/context/ComponentVariants';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Button from 'helpers/Button/Button';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import SVG from 'helpers/SVG/SVG';
import LegalDisclaimer from 'helpers/LegalDisclaimer/LegalDisclaimer';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import fallback from 'lib/fallback/fallback';

export type HeroInternalProps = ComponentProps &
  HeroComponents.HeroInternal.HeroInternal & {
    theme?: string;
  };

type Alignment = 'Left' | 'Center' | undefined;

type ShowPattern = true | false | undefined;

const themeVariants = ALL_THEMES.reduce(
  (acc, curr) => ((acc[curr] = {}), acc),
  {} as Record<string, object>
);

const tailwindVariants = tv({
  slots: {
    base: [
      'component-content',
      'flex',
      'flex-col',
      'h-full',
      'relative',
      'justify-center',
      'min-h-components-hero-internal-spacing-small-min-height',
      'md:min-h-components-hero-internal-spacing-large-min-height',
      'overflow-hidden',
      'px-components-accordion-spacing-small-padding-x',
      'py-components-hero-internal-spacing-small-padding-y',
      'md:pr-components-hero-internal-spacing-large-padding-right',
      'md:pl-components-accordion-spacing-large-padding-x',
      'md:py-components-hero-internal-spacing-large-padding-y',
    ],
    descriptionText: [
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-bodySans-medium',
      'line-clamp-2',
      'text-components-hero-internal-color-default-body',
      'md:font-bodySans-large',
      'md:leading-bodySans-large',
      'md:text-bodySans-large',
      'md:line-clamp-none',
      'mb-components-hero-internal-spacing-small-button-margin-top',
      'md:mb-components-hero-internal-spacing-large-button-margin-top',
      'pt-[4px]',
      'border-b-4',
      'pb-[4px]',
      'border-transparent',
      'max-h-[50px]',
      'md:max-h-none',
    ],
    svgBackground: [
      'absolute',
      'left-0',
      'top-0',
      'w-full',
      'h-full',
      'overflow-hidden',
      'hidden',
      '[&>*]:h-full',
      '[&>*]:top-0',
      '[&>*]:w-full',
      '[&>*]:absolute',
    ],
    svgDecor: ['w-full', 'absolute', 'bottom-0', 'left-0', 'z-10', 'hidden'],
    textWrapper: [
      'field-heroInternalTitle',
      'flex',
      'flex-col',
      'items-start',
      'relative',
      'z-20',
      'text-components-hero-internal-color-default-title',
    ],
    titleText: [
      'font-header-small-large',
      'leading-header-small-large',
      'text-header-small-large',
      'md:font-header-large-large',
      'md:leading-header-large-large',
      'md:text-header-large-large',
      'mb-components-hero-internal-spacing-small-title-margin-bottom',
      'md:mb-components-hero-internal-spacing-large-title-margin-bottom',
    ],
    disclaimerClasses: [
      '!text-components-hero-internal-color-default-body',
      'mt-components-hero-internal-spacing-small-button-margin-top',
      'md:mt-components-hero-internal-spacing-large-button-margin-top',
    ],
  },
  variants: {
    alignment: {
      Center: {
        base: ['items-center'],
        descriptionText: ['text-center'],
        titleText: ['text-center'],
        textWrapper: ['items-center'],
      },
      Left: {},
    },
    showPattern: {
      true: {
        base: [
          'bg-components-hero-internal-color-accent-bg',
          'md:bg-components-hero-internal-color-default-bg',
        ],
        svgBackground: ['hidden', 'md:block'],
        svgDecor: ['hidden', 'md:block'],
      },
      false: {
        base: ['bg-components-hero-internal-color-default-bg'],
      },
    },
    brand: {
      ...themeVariants,
      Glade: {
        svgBackground: ['left-[33%]', 'lg:left-[38%]'],
      },
    },
  },
});
const tailwindVariantsForWithImageVariant = tv({
  slots: {
    base: [
      'flex',
      'flex-col-reverse',
      'md:flex-row',
      'w-full',
      'md:gap-x-spacing-spacing-5',
      'bg-components-hero-internal-color-with-image-bg',
    ],
    leftSideContentWrapper: [
      'flex',
      'flex-col',
      'justify-center',
      'relative',
      'gap-y-components-promo-spacing-large-margin-y',
      'px-components-hero-internal-spacing-small-padding-x',
      'pb-components-hero-homepage-spacing-small-padding-y',
      'pt-spacing-spacing-10',
      'md:py-spacing-spacing-5',
      'md:pr-spacing-spacing-5',
      'md:pl-spacing-spacing-10',
      'md:w-[34.50%]',
    ],
    logoWrapper: [
      '[&>_img]:rounded-components-hero-internal-spacing-large-icon-radius',
      'max-md:absolute',
      'max-md:-top-[60px]',
    ],
    contentWrapper: ['flex', 'flex-col', 'gap-y-spacing-spacing-5'],
    contentTitle: [
      'font-header-small-medium',
      'leading-header-small-medium',
      'text-header-small-medium',
      'md:font-header-large-medium',
      'md:leading-header-large-medium',
      'md:text-header-large-medium',
      'text-components-hero-internal-color-with-image-title',
    ],
    contentSubtitle: [
      'font-header-small-xxSmall',
      'leading-header-small-xxSmall',
      'text-header-small-xxSmall',
      'md:font-header-medium-xxSmall',
      'md:leading-header-medium-xxSmall',
      'md:text-header-medium-xxSmall',
      'text-components-hero-internal-color-with-image-body',
    ],
    contentDescription: [
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-bodySans-medium',
      'text-components-hero-internal-color-with-image-body',
    ],
    contentCtaWrapper: [
      'flex',
      'flex-wrap',
      'gap-components-promo-spacing-large-buttons-space-between',
    ],
    primaryCtaWrapper: ['w-full', 'md:w-auto'],
    secondaryCtaWrapper: ['w-full', 'md:w-auto'],
    disclaimerClasses: ['!text-components-hero-internal-color-with-image-body'],
    rightSideImageWrapper: ['w-full', 'md:w-[65.50%]'],
    imageClasses: ['w-full', 'h-full', 'aspect-video', 'object-fill'],
  },
});

const HeroInternal = (props: HeroInternalProps): JSX.Element => {
  const {
    description,
    enablePattern,
    primaryCTA,
    title,
    disclaimerText,
    primaryCTAColor,
    primaryCTAType,
  } = props?.fields || {};
  const { alignContent } = props?.params || {};
  const { dataSource, componentName } = props?.rendering || {};

  const { themeName } = useTheme();
  const componentVariants = ComponentVariants();

  const alignment = alignContent !== 'Left' && 'Center';
  const showPattern = enablePattern?.value ? true : false;

  const {
    base,
    titleText,
    descriptionText,
    svgBackground,
    svgDecor,
    textWrapper,
    disclaimerClasses,
  } = tailwindVariants({
    alignment: alignment as Alignment,
    showPattern: showPattern as ShowPattern,
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
  });

  if (!props?.fields) return <></>;

  return (
    <Container fullWidth>
      <div className={base()} data-component="authorable/heroes/internalhero">
        {componentVariants?.heroInternal?.hasDecor && (
          <SVG className={svgDecor()} svg={`hero-internal/Decor,Brand=${themeName}`} />
        )}
        <SVG className={svgBackground()} svg={`hero-internal/BG,Brand=${themeName}`} />
        <div className={textWrapper()}>
          <Text className={titleText()} encode={false} field={title} tag="h2" />
          {props.fields?.description && (
            <RichTextA11yWrapper className={descriptionText()} field={description} />
          )}
          {primaryCTA?.value?.href && (
            <Button
              href={primaryCTA?.value?.href}
              label={primaryCTA?.value?.text}
              tag="a"
              target={primaryCTA?.value?.target}
              color={primaryCTAColor?.value || 'white'}
              type={primaryCTAType?.value || 'filled'}
              size="compressed"
              gtmEvent={{
                event: 'cta_click',
                type: 'primary',
                'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                'gtm.element.dataset.gtmDatasourceId': dataSource,
                'gtm.element.dataset.gtmComponentName': componentName,
              }}
            >
              <LinkWrapper field={primaryCTA} />
            </Button>
          )}
          {disclaimerText?.value != '' && (
            <LegalDisclaimer
              disclaimerText={disclaimerText}
              disclaimerClasses={disclaimerClasses()}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export const WithImage = (props: HeroInternalProps): JSX.Element => {
  const {
    description,
    primaryCTA,
    secondaryCTA,
    title,
    disclaimerText,
    primaryCTAColor,
    primaryCTAType,
    secondaryCTAColor,
    secondaryCTAType,
    subtitle,
    logo,
    image,
  } = props?.fields || {};
  const { dataSource, componentName } = props?.rendering || {};

  // Add fallback component variant color
  const fallbackComponentVariantColor = fallback?.topBar?.value;
  const fallbackComponentVariantType = fallback?.topBar?.type;

  const {
    base,
    leftSideContentWrapper,
    logoWrapper,
    contentWrapper,
    contentTitle,
    contentSubtitle,
    contentDescription,
    contentCtaWrapper,
    primaryCtaWrapper,
    secondaryCtaWrapper,
    disclaimerClasses,
    rightSideImageWrapper,
    imageClasses,
  } = tailwindVariantsForWithImageVariant({});

  if (!props?.fields) return <></>;

  return (
    <Container fullWidth>
      <div className={base()} data-component="authorable/heroes/internalheroWithImage">
        <div className={leftSideContentWrapper()}>
          {logo?.value?.src && (
            <div className={logoWrapper()}>
              <ImageWrapper field={logo} />
            </div>
          )}
          <div className={contentWrapper()}>
            {title?.value && (
              <Text className={contentTitle()} encode={false} field={title} tag="h2" />
            )}
            {subtitle?.value && (
              <Text className={contentSubtitle()} encode={false} field={subtitle} tag="p" />
            )}
            {description?.value && (
              <RichTextA11yWrapper className={contentDescription()} field={description} />
            )}
          </div>
          <div className={contentCtaWrapper()}>
            {primaryCTA?.value?.text && (
              <div className={primaryCtaWrapper()}>
                <Button
                  href={primaryCTA?.value?.href}
                  target={primaryCTA?.value?.target}
                  label={primaryCTA?.value?.text}
                  tag="a"
                  fullWidth
                  // As the design requires a filled CTA, we do not need to add a fallback since
                  // the button component has a default variant that displays a filled CTA
                  color={primaryCTAColor?.value || fallbackComponentVariantColor}
                  type={primaryCTAType?.value || 'filled'}
                  gtmEvent={{
                    event: 'cta_click',
                    type: 'primary',
                    'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                    'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                    'gtm.element.dataset.gtmDatasourceId': dataSource,
                    'gtm.element.dataset.gtmComponentName': componentName,
                  }}
                />
              </div>
            )}
            {secondaryCTA?.value?.text && (
              <div className={secondaryCtaWrapper()}>
                <Button
                  href={secondaryCTA?.value?.href}
                  target={secondaryCTA?.value?.target}
                  label={secondaryCTA?.value?.text}
                  tag="a"
                  fullWidth
                  // The design requires an outline CTA, so for that we have added a fallback as an outline value,
                  // so if there is no value in sitecore field, it will take the outline value.
                  type={secondaryCTAType?.value || fallbackComponentVariantType}
                  color={secondaryCTAColor?.value || fallbackComponentVariantColor}
                  gtmEvent={{
                    event: 'cta_click',
                    type: 'secondary',
                    'gtm.element.dataset.gtmLinkUrl': secondaryCTA?.value?.href,
                    'gtm.element.dataset.gtmLinkName': secondaryCTA?.value?.text,
                    'gtm.element.dataset.gtmDatasourceId': dataSource,
                    'gtm.element.dataset.gtmComponentName': componentName,
                  }}
                />
              </div>
            )}
          </div>
          {disclaimerText?.value != '' && (
            <LegalDisclaimer
              disclaimerText={disclaimerText}
              disclaimerClasses={disclaimerClasses()}
            />
          )}
        </div>
        <div className={rightSideImageWrapper()}>
          <ImageWrapper field={image} className={imageClasses()} />
        </div>
      </div>
    </Container>
  );
};

export default HeroInternal;
