// Global
import React, { useEffect, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
import SVG from 'helpers/SVG/SVG';
import { useTheme } from 'lib/context/ThemeContext';

export type GlossaryTabProps = ComponentProps & DartComponents.GlossaryTab.GlossaryTabsList;
export type TabList = DartComponents.GlossaryTab.Tab;

interface Field<T> {
  value: T;
}

const tailwindVariantsBrandCard = tv({
  defaultVariants: {},
  slots: {
    base: [
      'md:py-components-media-gallery-spacing-large-padding-y',
      'md:px-components-media-gallery-spacing-large-padding-x',
      'py-components-accordion-spacing-small-padding-y',
      'px-components-accordion-spacing-small-padding-x',
    ],
    filterTabButtons: [
      'flex',
      'flex-row',
      'overflow-x-auto',
      'gap-components-button-group-spacing-space-between',
      'pb-spacing-spacing-7',
    ],
    filterTabBrandTitle: [
      'cursor-pointer',
      'text-components-button-group-color-button-fg-default',
      'text-bodySans-medium-semibold',
      'font-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'hover:bg-components-button-group-color-button-bg-hover',
      'rounded-themes-radius-large-button',
      'py-components-button-spacing-large-default-padding-y',
      'px-components-button-spacing-large-default-padding-x',
    ],
    filterTabContainer: ['flex', 'flex-col', '[&>div>div>div]:!p-0'],
    svgClassLarge: ['block', 'lg:hidden'],
    svgClassExtraLarge: ['hidden', 'lg:block'],
  },
});

const GlossaryTab = (props: GlossaryTabProps): JSX.Element => {
  const { tabs } = props?.fields ?? {};

  const { themeName } = useTheme();

  let initialTab = '';

  if (tabs?.[0]?.fields?.title) {
    initialTab = (tabs[0].fields.title as Field<string>).value;
  }

  const [selectedTab, setSelectedTab] = useState<string>(initialTab);

  const id = props?.params?.RenderingIdentifier;

  // Ensure the selected tab is updated when the component mounts
  useEffect(() => {
    if (tabs?.[0]?.fields?.title) {
      setSelectedTab((tabs[0].fields.title as Field<string>).value);
    }
    handleTabClick(selectedTab);
  }, [tabs, selectedTab]);

  const handleTabClick = (tabName: string) => {
    setSelectedTab(tabName); // Update the selected tab when clicked
  };

  if (!tabs) return <></>;

  const {
    base,
    filterTabButtons,
    filterTabBrandTitle,
    filterTabContainer,
    svgClassLarge,
    svgClassExtraLarge,
  } = tailwindVariantsBrandCard({});

  return (
    <div
      className={base()}
      id={id ? id : undefined}
      data-component="authorable/glossarytab"
      tabIndex={id ? -1 : 1}
    >
      <Container>
        <div className={filterTabButtons()}>
          {tabs?.map((tab: TabList, index: number) => {
            const { title } = tab?.fields ?? {};

            return (
              <div
                key={index}
                data-id={index}
                className={`${filterTabBrandTitle()} ${
                  selectedTab === tab?.fields?.title?.value
                    ? 'bg-components-button-group-color-button-bg-active'
                    : ''
                }`}
                onClick={() => handleTabClick(tab?.fields?.title?.value || '')}
              >
                {title?.value && <Text encode={false} field={title} />}
              </div>
            );
          })}
        </div>

        <SVG className={svgClassLarge()} svg={`rule-lines/Breakpoint=Large,Brand=${themeName}`} />
        <SVG
          className={svgClassExtraLarge()}
          svg={`rule-lines/Breakpoint=ExtraLarge,Brand=${themeName}`}
        />

        <div className={filterTabContainer()}>
          {tabs
            // Display content for the selected tab only
            .filter((tab: TabList) => tab?.fields?.title?.value === selectedTab)
            .map((_tab: TabList, index) => {
              const indexingTabs = selectedTab === initialTab ? 0 : 1;
              const phKey = `glossarytab-${indexingTabs}-{*}`;
              return <Placeholder key={index} name={phKey} rendering={props.rendering} />;
            })}
        </div>
      </Container>
    </div>
  );
};

export default GlossaryTab;
