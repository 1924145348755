/**
 * You can import your own hybrid (server render + hydration) components below
 * @example
 * import './MyHybridComponent';
 * @example
 * import 'src/otherFolder/MyOtherComponent';
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default {};
