const startItemQuery = /* GraphQL */ `
  query StartItemQuery($language: String!, $site: String!) {
    layout(language: $language, site: $site, routePath: "/") {
      item {
        id
        path
      }
    }
  }
`;
export default startItemQuery;
