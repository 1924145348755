// Global
import React from 'react';
import Container from '../Layout/DartContainer/DartContainer';

// interface RenderingParams {
//   desktop: string;
//   mobile: string;
//   tablet: string;
// }
// interface SpacerProps {
//   rendering: {
//     params: RenderingParams;
//   };
// }

const Spacer = (): JSX.Element => {
  // const { desktop, mobile, tablet } = rendering.params;
  return (
    <Container fullWidth>
      {/* <div className={`py-[${mobile}] md:py-[${tablet}] lg:py-[${desktop}]`}></div> */}
      <div className="spacerClasses"></div>
    </Container>
  );
};

export default Spacer;
