import React, { useState, useEffect } from 'react';

interface YouTubeThumbnailProps {
  videoId: string; // Ensure videoId is a string
}

const YouTubeThumbnail: React.FC<YouTubeThumbnailProps> = ({ videoId }) => {
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  useEffect(() => {
    if (videoId) {
      const hdThumbnail = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
      const fallbackThumbnail = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

      // Check if HD thumbnail exists
      const img = new Image();
      img.src = hdThumbnail;
      img.onload = () => setThumbnailUrl(hdThumbnail); // Use HD thumbnail if available
      img.onerror = () => setThumbnailUrl(fallbackThumbnail); // Fallback to hqdefault if not
    }
  }, [videoId]);

  return (
    <div>
      {thumbnailUrl && (
        <img src={thumbnailUrl} alt="YouTube Video Thumbnail" style={{ width: '100%' }} />
      )}
    </div>
  );
};

export default YouTubeThumbnail;
