const getPublicUrl = (serverFetch = false) => {
  const basePublicUrl =
    // If we're on the server, and running in local docker...
    serverFetch && typeof window === 'undefined' && process.env.DOCKER_PUBLIC_URL
      ? // Use the DOCKER_PUBLIC_URL because Docker can't access the external PUBLIC_URL
        process.env.DOCKER_PUBLIC_URL
      : // Otherwise use the normal PUBLIC_URL
        process.env.PUBLIC_URL;

  const publicUrl = basePublicUrl?.replace(/\/$/, '');

  return publicUrl;
};

/*
 * getStaticAssetUrl
 *
 * The Storybook build process publishes all contents of /public/* to /*.
 * To reference public assets from within Storybook, all paths should start from the root; i.e. /*.
 *
 * All other environments publish static assets to /en/*.
 * To reference public assets, the base URL is required as well as /en to resolve an issue with locales.
 */
const getStaticAssetUrl = () => {
  const staticAssetUrl =
    process.env.IS_STORYBOOK || process.env.IS_EDITING_HOST !== 'true' ? '' : `${getPublicUrl()}`;

  return staticAssetUrl;
};

module.exports = {
  getPublicUrl,
  getStaticAssetUrl,
};
