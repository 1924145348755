// Global
import {
  Placeholder,
  ComponentRendering,
  ComponentParams,
} from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';

export type DartLayoutProps = {
  rendering: ComponentRendering & { params: ComponentParams };
  params: ComponentParams;
};

// type Sites =
//   | 'autan'
//   | 'autanDefense'
//   | 'corporate'
//   | 'off'
//   | 'offDefense'
//   | 'vanilla'
//   | 'ziploc'
//   | undefined;

const FormContainer = (props: DartLayoutProps): JSX.Element => {
  //   const { site } = props?.params || {};
  //   const siteMapping: { [key: string]: string } = {
  //     autan: 'autan',
  //     autanDefense: 'autanDefense',
  //     corporate: 'corporate',
  //     off: 'off',
  //     offDefense: 'offDefense',
  //     vanilla: 'vanilla',
  //     ziploc: 'ziploc',
  //   };
  //   const selectedSite = siteMapping[site];
  const id = props?.params?.RenderingIdentifier;

  const phKeyform = `dart-form`;

  return (
    <div>
      <Container>
        <div data-component="formcontainer/formcontainer" id={id ? id : undefined}>
          <div className={`${phKeyform}`}>
            <Placeholder name={phKeyform} rendering={props.rendering} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FormContainer;
