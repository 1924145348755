// Global
import { ImageField, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useContext } from 'react';
import { tv } from 'tailwind-variants';
import { useI18n } from 'next-localization';

// Lib
import { ComponentProps } from 'lib/component-props';
import { useTheme } from 'lib/context/ThemeContext';
import { CardComponents, ProductAndArticleComponent } from 'lib/templates/Feature.Dart.model';
import { Common } from 'lib/templates/Foundation.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';
import { getStaticAssetUrl } from 'lib/utils/public-url-utils';

// Local
import { ProductCardContext } from 'components/authorable/ProductCard/ProductCard';
import Button from 'helpers/Button/Button';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import Label from 'helpers/Label/Label';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { stripHtml } from 'lib/utils/regex';

export type Badge = CardComponents.ProductCard.Badge;

export type Label = Common.Label.Label;

export type ProductInfo = ProductAndArticleComponent.ProductInformation.ProductInformation;

export type ProductCard = ItemEx &
  CardComponents.ProductCard.ProductCardItem &
  Badge & { indexOfItem: number; isBadgeFullWidth: string | undefined };

export type ProductCardProps = ComponentProps & CardComponents.ProductCard.ProductCardsList;

const tailwindVariants = tv({
  slots: {
    badgeBG: [
      'bg-components-product-card-color-badge-bg',
      'block',
      'font-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'px-[34px]',
      'py-[8px]',
      'text-bodySans-medium-semibold',
      'text-center',
      'text-themes-text-color-white',
    ],
    badgeContainer: ['absolute', 'z-10'],
    buttonClasses: [
      'border-[3px]',
      'border-components-button-color-outline-white-outline-stroke',
      'group',
      'h-8',
      'mr-2',
      'rounded-themes-radius-large-button',
      'w-8',
      'active:bg-components-button-color-outline-white-pressed-bg',
      'active:border-components-button-color-outline-white-pressed-stroke',
      'disabled:bg-transparent',
      'disabled:border-components-button-color-outline-white-disabled-stroke',
      'disabled:border-components-button-color-outline-white-disabled-stroke',
      'disabled:pointer-events-none',
      'disabled:text-components-button-color-outline-white-disabled-text',
      'hover:bg-components-button-color-outline-white-hover-bg',
      'hover:border-components-button-color-outline-white-hover-stroke',
    ],
    cardContainer: [
      'flex',
      'flex-1',
      'flex-col',
      'gap-components-product-card-spacing-large-category-margin-bottom',
    ],
    cardContentWrapper: [
      'flex',
      'flex-col',
      'flex-1',
      'justify-between',
      'py-components-product-card-spacing-large-padding-content-y',
      'px-components-product-card-spacing-large-padding-content-x',
      'gap-components-product-card-spacing-large-copy-margin-bottom',
    ],
    cardImgWrapper: [
      'aspect-card',
      'block',
      'flex',
      'justify-center',
      'relative',
      'w-full',
      'max-h-[330px]',
    ],
    cardLinkWrapper: ['block', 'w-full'],
    cardMedia: ['object-cover', 'min-h-full'],
    cardWrapper: [
      'bg-components-product-card-color-bg',
      'flex-col',
      'flex',
      'relative',
      'overflow-hidden',
      'rounded-themes-radius-large-card',
      'border-components-article-card-spacing-large-border-width',
      'border-components-article-card-color-border',
      'border-solid',
      'w-full',
    ],
    cta: ['w-full'],
    ctaWrapper: ['[&>a]:w-full'],
    labelBg: ['bg-components-product-card-color-bg'],
    productDescription: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'line-clamp-2',
      'text-bodySans-small',
      'text-components-product-card-color-description',
      '[&>.richtext-wrapper>div>p]:p-0',
      '[&>.richtext-wrapper>div>p]:pt-[3px]',
      'md:leading-bodySans-medium',
      'md:font-bodySans-medium',
      'md:text-bodySans-medium',
      'mb-[-2px]',
      'pt-[4px]',
      'border-b-4',
      'pb-[5px]',
      'border-transparent',
      'max-h-[50px]',
    ],
    productInfoContainer: [
      'flex',
      'flex-1',
      'flex-col',
      'justify-between',
      'gap-components-product-card-spacing-large-title-margin-bottom',
      '[&_.pr-snippet.pr-category-snippet]:!mb-0',
      '[&_.pr-snippet-stars.pr-snippet-stars-png]:!mb-0',
    ],
    productInfoTitleRatingsContainer: ['flex', 'flex-col'],
    ratingWrapper: ['mt-components-product-card-spacing-large-title-margin-bottom'],
    productTitle: [
      'font-header-small-xSmall',
      'leading-header-small-xSmall',
      'text-components-product-card-color-title',
      'text-header-small-xSmall',
      'md:font-header-large-xSmall',
      'md:leading-header-large-xSmall',
      'md:text-header-large-xSmall',
    ],
    rating: ['flex', 'flex-row', 'mb-components-product-card-spacing-large-title-margin-bottom'],
    starIcon: ['fill-colors-accent-1-400'],
  },
  variants: {
    isBadgeFullWidth: {
      '1': { badgeBG: ['h-[39px]'], badgeContainer: ['w-full'] },
      undefined: {
        badgeBG: ['h-[30px]', 'min-w-[130px]'],
        badgeContainer: ['top-0', 'left-1/2', 'transform', '-translate-x-1/2'],
      },
    },
  },
});

const ProductSingleCard = ({ indexOfItem, ...props }: ProductCard): JSX.Element => {
  const { ctaLink } = props?.fields || {};

  const productCardUid = useContext(ProductCardContext);
  const { themeName } = useTheme();

  const {
    badgeBG,
    badgeContainer,
    cardContainer,
    cardContentWrapper,
    cardImgWrapper,
    cardLinkWrapper,
    cardMedia,
    cardWrapper,
    ctaWrapper,
    labelBg,
    productDescription,
    productInfoContainer,
    productInfoTitleRatingsContainer,
    ratingWrapper,
    productTitle,
  } = tailwindVariants({});

  const badge = props?.fields?.badge as Badge;
  const badgeSVG = `ProductCard/Breakpoint=Medium,Brand=${themeName}`;
  const pageID = props?.fields?.productInformation?.id;
  const productInfo = props?.fields?.productInformation as ProductInfo;
  const label = productInfo?.fields?.label as unknown as Label;
  const svgBG = `${getStaticAssetUrl()}/assets/${badgeSVG}.svg`;
  const ariaLabel = stripHtml(productInfo?.fields?.headline?.value as string);
  const i18n = useI18n();
  const About = i18n.t('AccessibilityAbout') ? i18n.t('AccessibilityAbout') : 'about';

  return (
    <div className={cardWrapper()}>
      {badge?.fields?.badgeTitle?.value && (
        <div
          className={badgeContainer({
            isBadgeFullWidth: props?.isBadgeFullWidth as '1' | undefined,
          })}
          aria-hidden="true"
        >
          <span
            className={badgeBG({
              isBadgeFullWidth: props?.isBadgeFullWidth as '1' | undefined,
            })}
            style={{
              maskImage: `url(${svgBG})`,
              maskSize: 'cover',
              maskRepeat: props?.isBadgeFullWidth && 'no-repeat',
              maskPosition: props?.isBadgeFullWidth && 'center top',
            }} //Added style as tailwind does not have maskImage and maskSize
          >
            {badge?.fields?.badgeTitle?.value}
          </span>
        </div>
      )}
      {ctaLink?.value?.href && (
        <LinkWrapper
          className={cardImgWrapper()}
          showLinkTextWithChildrenPresent={false}
          field={ctaLink}
          aria-label={ariaLabel}
        >
          <ImageWrapper
            field={productInfo?.fields?.primaryImage as ImageField}
            layout="responsive"
            className={cardMedia()}
          />
        </LinkWrapper>
      )}
      <div className={cardContentWrapper()}>
        <div className={cardContainer()}>
          {label?.fields?.link?.value?.href && (
            <Label
              color={'white'}
              link={label?.fields?.link as LinkField}
              backgroundColor={labelBg()}
            />
          )}
          <div className={productInfoContainer()}>
            <div className={productInfoTitleRatingsContainer()}>
              {productInfo?.fields?.headline && (
                <div className={productTitle()}>
                  {props?.fields?.ctaLink?.value?.href && (
                    <LinkWrapper
                      className={cardLinkWrapper()}
                      showLinkTextWithChildrenPresent={false}
                      field={props?.fields?.ctaLink}
                      aria-label={ariaLabel}
                    >
                      <RichTextA11yWrapper field={productInfo?.fields?.headline} />
                    </LinkWrapper>
                  )}
                </div>
              )}
              {productInfo?.fields?.ratingAndReview && (
                <div
                  id={`category-snippet-${productCardUid}-${pageID}-${indexOfItem}`}
                  className={ratingWrapper()}
                ></div>
              )}
            </div>
            {productInfo?.fields?.description && (
              <div className={productDescription()}>
                <RichTextA11yWrapper field={productInfo?.fields?.description} />
              </div>
            )}
          </div>
        </div>
        {ctaLink?.value?.href && (
          <div className={ctaWrapper()}>
            <Button
              srOnlyText={ariaLabel}
              label={ctaLink?.value?.text}
              type="fill"
              title={`${ctaLink?.value?.text} ${About} ${ariaLabel}`}
              tag="a"
              target={ctaLink?.value?.target}
              href={props?.fields?.ctaLink?.value?.href}
              gtmEvent={{
                event: 'cta_click',
                type: 'primary',
                'gtm.element.dataset.gtmLinkUrl': props?.fields?.ctaLink?.value?.href,
                'gtm.element.dataset.gtmLinkName': props?.fields?.ctaLink?.value?.text,
                'gtm.element.dataset.gtmDatasourceId': props?.fields?.productInformation?.id,
                'gtm.element.dataset.gtmDatasourceName': props?.fields?.productInformation?.name,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductSingleCard;
