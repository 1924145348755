module.exports = {
  brands: {
    DART: {
      colors: {
        'components-category-card-color-category-bg': '1',
        'components-category-card-color-category-bg-2': '1',
        'components-category-card-color-category-bg-3': '1',
        'components-category-card-color-category-bg-4': '1',
        'components-category-card-color-category-bg-5': '1',
      },
    },
    Ziploc: {
      colors: {
        'components-category-card-color-category-bg': '#0CF',
        'components-category-card-color-category-bg-2': '#EA2B38',
        'components-category-card-color-category-bg-3': '#48C249',
        'components-category-card-color-category-bg-4': '#0241CD',
        'components-category-card-color-category-bg-5': '#792F9D',
      },
    },
  },
};
