const FooterQuery = `query Footer($datasource: String!, $language: String!) {
  footer: item(language: $language, path: $datasource) {
    ... on Footer {
      copyRightText {
        jsonValue
      }
      logoImage {
        jsonValue
      }
      logoUrl {
        jsonValue
      }
      enableSocial {
        jsonValue
      }
      socialTitle {
        jsonValue
      }
      socialLinks {
        ... on MultilistField {
          targetItems {
            id
            ... on SocialLink {
              socialIcon {
                jsonValue
              }
              socialUrl {
                jsonValue
              }
            }
          }
        }
      }
      footerLinks {
        ... on MultilistField {
          targetItems {
            id
            ... on Link_a47c0c2279e947a6936d870d8564e892 {
              ctaLink {
                jsonValue
              },
              rawHtml {
                jsonValue
              }
            }
          }
        }
      }
      mainNavigations {
        ... on MultilistField {
          targetItems {
            id
            ... on PrimaryFooterNavigation {
              navigationLink {
                jsonValue
              }
              navigationTitle {
                jsonValue
              }
              secondaryNavigations {
                ... on MultilistField {
                  targetItems {
                    id
                    ... on SecondaryNavigation {
                      navigationLink {
                        jsonValue
                      }
                      navigationTitle {
                        jsonValue
                      }
                      navigations {
                        ... on MultilistField {
                          targetItems {
                            id
                            ... on TertiaryNavigation {
                              navigationLink {
                                jsonValue
                              }
                              navigationTitle {
                                jsonValue
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`;

export default FooterQuery;
