// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// Global
import { useSitecoreContext, Text, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect, useState } from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartPages } from 'lib/templates/Project.Dart.model';
import { DartComponents } from 'lib/templates/Feature.Dart.model';
import { BaseFields } from 'lib/templates/Foundation.Dart.model';

// Local
import CtaBorderAnimation from 'helpers/CTA/CtaBorderAnimation';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import Search from 'src/widgets/Search';
import { useRouter } from 'next/router';
import { ALL_THEMES, useTheme } from 'lib/context/ThemeContext';

export type NameDefault = {
  nameDefault?: { jsonValue: Field<string> };
};

export type Countrylist = DartComponents.Navigation.RegionJson &
  NameDefault &
  DartComponents.Navigation.CountryJson;

export type FeaturedLinks = BaseFields.CtaJson;

export type HeaderDesktopProps = ComponentProps &
  DartComponents.HeaderJson & {
    staticProps?: {
      header?: DartComponents.HeaderJson;
    };
  };

export type MainNavigations = DartComponents.Navigation.SecondaryNavigationJson &
  DartComponents.Navigation.PrimaryHeaderNavigationJson &
  BaseFields.FeaturedJson;

export type SecondaryNavigations = DartComponents.Navigation.SecondaryNavigationJson;

export type SearchPage = DartPages.SearchPage;

const themeVariants = ALL_THEMES.reduce(
  (acc, curr) => ((acc[curr] = {}), acc),
  {} as Record<string, object>
);

const tailwindVariants = tv({
  compoundSlots: [
    {
      slots: [
        'flexCommonClass',
        'headerWrapper',
        'headerLeft',
        'primaryNavigation',
        'primaryNavigationListItem',
        'primaryNavigationListItemLinkWrapper',
        'secondaryNavigationListItemTitleLinkWrapper',
        'secondaryNavigationItem',
        'secondaryDropdownItem',
        'currentCountry',
        'countryItemLinkWrapper',
        'countryItemLink',
      ],
      class: ['flex', 'flex-row', 'items-center'],
    },
  ],
  slots: {
    base: ['bg-components-header-color-bg', 'component header', 'hidden', 'w-full', 'md:block'],
    headerWrapper: [
      'header-wrapper',
      'border-b-[length:theme(spacing.spacing-spacing-025)]',
      'border-components-header-color-bottom-stroke',
      'h-[80px]',
      'items-stretch',
      'justify-between',
      'px-components-header-spacing-large-padding-x',
      'relative',
    ],
    headerLeft: ['header-left'],
    logoWrapper: ['logo-wrapper'],
    logo: ['logo', 'w-[200%]', 'mr-components-header-spacing-large-logo-margin-right'],
    navigation: ['navigation', 'h-full'],
    navigationInner: ['navigation-inner', 'h-full'],
    primaryNavigation: [
      'primary-navigation',
      'md:gap-[0.5rem]', // Adding this gap, will achieve proper logo and navLinks for all the viewpoints
      'lg:gap-components-header-spacing-large-links-margin-right',
      'h-full',
    ],
    primaryNavigationListItemWrapper: ['h-full'],
    primaryNavigationListItem: ['h-full'],
    primaryNavigationListItemLinkWrapper: [
      'cursor-pointer',
      'font-header-medium-xxSmall',
      'leading-header-medium-xxSmall',
      'text-header-medium-xxSmall',
      'group/item',
      'p-spacing-padding-large-1',
      'text-components-header-color-links-nav-main-link-default',
    ],
    primaryNavigationListItemLink: [
      'group-active/item:text-components-header-color-links-nav-main-link-pressed',
      'group-hover/item:text-components-header-color-links-nav-main-link-hover',
      'mr-spacing-space-between-large-1',
    ],
    primaryNavigationListItemArrowDropDown: [
      '[&>svg]:fill-components-header-color-links-nav-main-link-default',
      'group-active/item:[&>svg]:fill-components-header-color-links-nav-main-link-pressed',
      'group-hover/item:[&>svg]:!fill-components-header-color-links-nav-main-link-hover',
    ],
    secondaryNavigationWrapper: [
      'secondary-navigation-wrapper',
      'absolute',
      'bg-components-header-color-mega-menu-bg',
      'border-t',
      'border-t-components-header-color-bottom-stroke',
      'flex',
      'flex-row',
      'flex-wrap',
      '-mt-[1px]',
      'top-full',
      'w-full',
      'h-auto',
      'max-h-[100vh]',
      'fixed',
      'top-0',
      'left-0',
      'right-0',
      'overflow-x-hidden',
      'overflow-y-auto',
      'z-50',
    ],
    secondaryNavigation: [
      'secondary-navigation',
      'grid',
      'grid-cols-4',
      'gap-components-header-spacing-large-mega-menu-column-margin-right',
      'px-components-header-spacing-large-mega-menu-padding-x',
      'py-components-header-spacing-large-mega-menu-padding-y',
      'w-[calc(100%-444px)]',
    ],
    secondaryNavigationListItemWrapper: ['flex-1'],
    secondaryNavigationListItemTitleLinkWrapper: [
      'group/secondaryitem',
      'mb-components-header-spacing-large-mega-menu-category-link-margin-bottom',
    ],
    secondaryNavigationListItemTitleLink: [
      'block',
      'font-bodySans-large-semibold',
      'inline-flex',
      'leading-bodySans-large-semibold',
      'p-spacing-padding-large-1',
      'text-bodySans-large-semibold',
      'text-components-header-color-links-nav-category-link-category-default',
      'focus:text-components-header-color-links-nav-category-link-category-pressed',
      'group-active/secondaryitem:text-components-header-color-links-nav-category-link-category-pressed',
      'group-hover/secondaryitem:text-components-header-color-links-nav-category-link-category-hover',
    ],
    secondaryNavigationListItemLinkIconWrapper: ['icon-wrapper', 'h-[24px]', 'w-[24px]'],
    secondaryNavigationListItemLinkIcon: [
      'duration-300',
      'fill-components-header-color-links-nav-main-link-hover',
      'transition-opacity',
      'group-active/secondaryitem:fill-components-header-color-links-nav-main-link-pressed',
      '[&_svg]:opacity-0',
      '[&_svg]:group-hover/secondaryitem:opacity-100',
    ],
    secondaryNavigationItemsWrapper: ['secondary-navigation-items-wrapper', 'flex', 'flex-col'],
    secondaryNavigationItem: [
      'group/secondaryitem',
      'mb-components-header-spacing-large-mega-menu-category-link-margin-bottom',
    ],
    secondaryNavigationItemLink: [
      'font-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'p-spacing-padding-large-1',
      'text-bodySans-medium-semibold',
      'text-components-header-color-links-nav-menu-link-link-default',
      'focus:text-components-header-color-links-nav-menu-link-link-pressed',
      'group-hover/secondaryitem:text-components-header-color-links-nav-menu-link-link-hover',
    ],
    featuredContentWrapper: [
      'featured-content-wrapper',
      'bg-components-header-color-mega-menu-featured-bg',
      'p-components-header-spacing-large-mega-menu-featured-padding-y',
      'w-[444px]',
      'ml-auto',
    ],
    featuredContentTitleWrapper: ['featured-content-title-wrapper'],
    featuredContentTitle: [
      'text-header-large-xSmall',
      'leading-120',
      'font-header-large-xSmall',
      'font-semibold',
      'text-components-header-color-mega-menu-featured-title',
      'mb-margin-large-4',
    ],
    featuredContentImageWrapper: [
      'featured-content-image-wrapper',
      'object-cover',
      'relative',
      'my-components-header-spacing-large-mega-menu-featured-image-margin-bottom',
    ],
    featuredContentImage: ['rounded-themes-radius-large-image'],
    featuredContentLinksWrapper: ['featured-content-links-wrapper'],
    featuredContentLinks: ['featured-content-links'],
    featuredContentLinkItem: [
      'font-bodySans-medium-semibold',
      'mr-margin-medium-4',
      'inline-block',
    ],
    secondaryDropdownWrapper: [
      'absolute',
      'bg-components-header-color-links-nav-menu-link-bg',
      'border-t',
      'border-t-components-header-color-bottom-stroke',
      'flex',
      'flex-row',
      'justify-between',
      'min-w-[270px]',
      'px-components-header-spacing-large-dropdown-padding-x',
      'py-components-header-spacing-large-dropdown-padding-y',
      'secondary-dropdown-wrapper',
      'top-full',
      'w-full',
      'z-50',
    ],
    secondaryDropdownListItem: [
      'secondary-dropdown-item-list',
      'flex',
      'flex-col',
      'gap-components-header-spacing-large-dropdown-margin-bottom',
      'p-padding-large-3',
    ],
    secondaryDropdownItem: [
      'gap-spacing-space-between-large-1',
      'group/secondaryitem',
      'p-spacing-padding-large-1',
      '[&:not(:last)]:mb-components-header-spacing-large-dropdown-margin-bottom',
    ],
    secondaryDropdownItemLink: [
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-components-header-color-links-nav-menu-link-link-default',
      'focus:text-components-header-color-links-nav-menu-link-link-pressed',
      'group-active/secondaryitem:text-components-header-color-links-nav-menu-link-link-pressed',
      'group-hover/secondaryitem:text-components-header-color-links-nav-menu-link-link-hover',
    ],
    headerRight: [
      'header-right',
      'flex',
      'flex-row',
      'items-center',
      'gap-[0.5rem]', // Adding this gap, will achieve proper logo and navLinks for all the viewpoints
      'lg:gap-components-header-spacing-large-icons-space-between',
    ],
    searchForm: [
      'bg-components-site-search-bg',
      'flex',
      'items-start',
      'justify-between',
      'py-spacing-margin-small-3',
      'w-full',
    ],
    searchFormInput: [
      'bg-components-site-search-bg',
      'font-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'placeholder-components-header-color-search-search-text',
      'text-components-header-color-search-search-text',
    ],
    searchFormButton: [
      'basicFocus',
      'font-medium',
      'h-[30px]',
      'mt-[-3px]',
      'text-white',
      'text-xl',
      'w-[30px]',
    ],
    searchFormButtonIcon: ['fill-components-header-color-search-search-icon'],
    countryWrapper: [
      'country-wrapper',
      'px-components-header-spacing-large-language-selector-padding-y',
    ],
    countryWrapperInner: ['country-wrapper-inner', 'flex', 'flex-row', 'gap-2', 'cursor-pointer'],
    flagIconWrapper: ['flag-icon', 'w-max'],
    logoPlaceholder: [
      'logo-placeholder',
      'w-[18px]',
      'h-[13px]',
      'bg-components-header-color-mega-menu-featured-bg',
    ],
    currentCountry: [
      'current-country',
      'gap-components-header-spacing-large-language-selector-space-between',
    ],
    countryInformation: [
      'country-information',
      'flex',
      'flex-col',
      'gap-components-header-spacing-large-language-selector-country-space-between',
    ],
    currentCountryName: [
      'current-country-name',
      'text-components-header-color-links-nav-menu-link-link-default',
      'font-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
    ],
    currentCountryLanguage: [
      'current-country-language',
      'text-components-header-color-language-selector-language',
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
    ],
    countryDropDownIcon: ['fill-components-header-color-links-nav-main-link-default'],
    countryListWrapper: [
      'absolute',
      'bg-components-header-color-mega-menu-bg',
      'country-list-wrapper',
      'left-0',
      'max-h-[calc(100vh-80px)]',
      'overflow-auto',
      'top-full',
      'w-full',
      'z-50',
    ],
    countryListInnerWrapper: [
      'country-list-inner-wrapper',
      'p-components-header-spacing-large-mega-menu-padding-y',
    ],
    countryListTop: ['country-list-top'],
    countryListHeading: [
      'country-list-heading',
      'text-components-header-color-mega-menu-category-title',
      'text-header-large-xSmall',
      'font-header-large-xSmall',
      'leading-header-large-xSmall',
      'mb-components-header-spacing-large-mega-menu-title-margin-bottom',
    ],
    countryListBottom: ['country-list-bottom', 'grid', 'grid-cols-4', 'gap-4'],
    countryListCols: ['country-list-cols'],
    countryListCol: ['country-list-col'],
    countryName: [
      'country-name',
      'p-spacing-padding-large-1',
      'font-header-medium-xxSmall',
      'text-header-medium-xxSmall',
      'text-components-header-color-links-nav-category-link-category-default',
      'mb-components-header-spacing-large-mega-menu-category-link-margin-bottom',
      'leading-header-medium-xxSmall',
    ],
    countryList: ['country-list'],
    countryItemLinkWrapper: [
      'group',
      'max-w-[240px]',
      'country-list-item',
      'px-spacing-padding-large-2',
      'py-spacing-padding-large-1',
      'mb-components-header-spacing-large-mega-menu-link-margin-bottom',
      'rounded-components-header-spacing-large-language-selector-radius',
      'border',
      'cursor-pointer',
      'hover:bg-components-header-color-language-selector-bg',
      'hover:border-colors-grayscale-400',
      'border-transparent',
    ],
    countryItemLink: ['items-start', 'justify-between', 'w-full'],
    countryListItemLinkleft: ['country-list-item-link-left', 'flex', 'flex-row', 'items-start'],
    countryListItemLinkleftInfo: [
      'country-info',
      'ml-components-header-spacing-large-language-selector-space-between',
      'flex',
      'flex-col',
      'gap-components-header-spacing-large-language-selector-country-space-between',
    ],
    countryListItemLinkleftInfoTitle: [
      'text-components-header-color-links-nav-menu-link-link-default',
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
    ],
    countryListItemLinkleftInfoSubTitle: [
      'text-colors-grayscale-400',
      'text-bodySans-small',
      'font-bodySans-small',
      'leading-bodySans-small',
    ],
    countryListRight: ['country-list-right'],
    countrySelection: ['country-selection'],
    countryListRightIcon: [
      'fill-colors-grayscale-400',
      '[&_svg]:group-hover:opacity-100',
      '[&_svg]:opacity-0',
    ],
    flexCommonClass: [],
  },
  variants: {
    hasHref: {
      false: {},
      true: {
        primaryNavigationListItem: ['cursor-pointer'],
      },
    },
    isActive: {
      false: {},
      true: {
        primaryNavigationListItemLink: ['text-components-header-color-links-nav-main-link-active'],
        primaryNavigationListItemArrowDropDown: [
          '[&>svg]:!fill-components-header-color-links-nav-main-link-active',
        ],
        primaryNavigationListItemLinkWrapper: [
          'isActive',
          'relative',
          'before:absolute',
          'before:bg-components-header-color-links-nav-main-active-bar',
          'before:bottom-0.5',
          'before:h-0.5',
          'before:left-0',
          'before:w-full',
        ],
        secondaryDropdownItemLink: [
          'relative',
          'text-components-header-color-links-nav-menu-link-link-active',
          'before:absolute',
          'before:bg-components-header-color-links-nav-main-active-bar',
          'before:bottom-0.5',
          'before:h-0.5',
          'before:left-0',
          'before:w-full',
        ],
        secondaryNavigationListItemLinkIcon: [
          '[&_svg]:opacity-100',
          '[&>svg]:fill-components-header-color-links-nav-main-link-active',
        ],
        secondaryNavigationListItemTitleLink: [
          'relative',
          'text-components-header-color-links-nav-menu-link-link-active',
          'before:absolute',
          'before:bg-components-header-color-links-nav-main-active-bar',
          'before:bottom-0.5',
          'before:h-0.5',
          'before:left-0',
          'before:w-full',
        ],
        secondaryNavigationListItemLinkIconWrapper: ['pt-[3px]'],
        secondaryNavigationItemLink: [
          'relative',
          '!text-components-header-color-links-nav-menu-link-link-active',
          'before:absolute',
          'before:bg-components-header-color-links-nav-main-active-bar',
          'before:bottom-0.5',
          'before:h-0.5',
          'before:left-0',
          'before:w-full',
        ],
      },
    },
    isDropdown: {
      false: {},
      true: {
        primaryNavigationListItemWrapper: ['relative'],
      },
    },
    matchingCountry: {
      false: {},
      true: {
        countryItemLinkWrapper: ['bg-components-header-color-language-selector-bg'],
      },
    },
    brand: {
      ...themeVariants,
      Glade: {
        logoWrapper: ['overflow-hidden', 'relative', 'h-[100%]'],
      },
      Corporate: {
        logo: ['!w-[100%]'],
      },
    },
  },
});

const HeaderDesktop = (props: HeaderDesktopProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const { themeName } = useTheme();

  const {
    base,
    countryDropDownIcon,
    countryInformation,
    countryItemLink,
    countryList,
    countryListBottom,
    countryListCol,
    countryListCols,
    countryListHeading,
    countryListInnerWrapper,
    countryListItemLinkleft,
    countryListItemLinkleftInfo,
    countryListItemLinkleftInfoSubTitle,
    countryListItemLinkleftInfoTitle,
    countryListRight,
    countryListRightIcon,
    countryListTop,
    countryListWrapper,
    countryName,
    countrySelection,
    countryWrapper,
    countryWrapperInner,
    currentCountry,
    currentCountryLanguage,
    currentCountryName,
    featuredContentImage,
    featuredContentImageWrapper,
    featuredContentLinkItem,
    featuredContentLinks,
    featuredContentLinksWrapper,
    featuredContentTitle,
    featuredContentTitleWrapper,
    featuredContentWrapper,
    flagIconWrapper,
    headerLeft,
    headerRight,
    headerWrapper,
    logo,
    logoPlaceholder,
    logoWrapper,
    navigation,
    navigationInner,
    primaryNavigation,
    secondaryDropdownItem,
    secondaryDropdownItemLink,
    secondaryDropdownListItem,
    secondaryDropdownWrapper,
    secondaryNavigation,
    secondaryNavigationItemsWrapper,
    secondaryNavigationListItemLinkIcon,
    secondaryNavigationListItemLinkIconWrapper,
    secondaryNavigationListItemWrapper,
    secondaryNavigationWrapper,
  } = tailwindVariants({
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
  });

  const { componentName, dataSource } = props?.rendering || {};
  const {
    enableSearch,
    logoImage,
    logoUrl,
    mainNavigations,
    regions,
    title,
    autoSuggestionItems,
    searchPlaceholderText,
    resultSuggestionItems,
  } = props?.staticProps?.header || {};

  const SearchProps = {
    autoSuggestionItems: autoSuggestionItems?.jsonValue.value || 5,
    resultSuggestionItems: resultSuggestionItems?.jsonValue.value || 5,
    searchPlaceholderText: searchPlaceholderText?.jsonValue?.value,
    defaultValue: '',
    mainNavigationsLength: mainNavigations?.targetItems?.length as number,
  };

  const router = useRouter();

  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const [matchingCountry, setMatchingCountry] = useState<Countrylist | null | undefined>();
  const [openCountryWrapper, setOpenCountryWrapper] = useState<boolean>(false);
  const [pathname, setPathname] = useState(`${router?.locale}/${router?.asPath}`);

  useEffect(() => {
    router && setPathname(`/${router.locale}${router.asPath}`);
    setHoveredItem(null);
  }, [router]);

  const handleMouseEnter = (index: number) => {
    setHoveredItem(index);
    setOpenCountryWrapper(false);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const showCountryWrapper = () => {
    setOpenCountryWrapper(!openCountryWrapper);
  };

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLElement>,
    secondaryNavExists: boolean
  ) => {
    if (e.key === 'Enter') {
      if (secondaryNavExists && e?.preventDefault) e.preventDefault();
      setHoveredItem(hoveredItem === index ? null : index);
      if (hoveredItem !== index) setOpenCountryWrapper(false);
    }
  };

  useEffect(() => {
    if (!regions) return;

    const foundCountry = regions?.targetItems
      .flatMap((region: Countrylist) => {
        return region?.countries?.targetItems;
      })
      .find(
        (country?: Countrylist) =>
          country?.languageCode?.jsonValue?.value === sitecoreContext.language
      );

    setMatchingCountry(foundCountry);
  }, [props, regions, sitecoreContext]);

  return (
    <div className={base()} data-component="authorable/landmarks/header">
      <div className={headerWrapper()}>
        <div className={headerLeft()}>
          <div className={logoWrapper()}>
            <div className={logo()}>
              {pathname !== '/' ? (
                <LinkWrapper
                  aria-label="Link to homepage"
                  field={logoUrl?.jsonValue}
                  gtmEvent={{
                    event: 'navigation_click',
                    'gtm.element.dataset.gtmDatasourceId': dataSource,
                    'gtm.element.dataset.gtmComponentName': componentName,
                  }}
                  title="Link to homepage"
                >
                  <ImageWrapper field={logoImage?.jsonValue} />
                </LinkWrapper>
              ) : (
                <ImageWrapper field={logoImage?.jsonValue} />
              )}
            </div>
          </div>
          <nav className={navigation()}>
            <div className={navigationInner()}>
              <ul className={primaryNavigation()}>
                {mainNavigations?.targetItems?.map((ListItem: MainNavigations, index: number) => {
                  const {
                    primaryNavigationListItem,
                    primaryNavigationListItemArrowDropDown,
                    primaryNavigationListItemLink,
                    primaryNavigationListItemLinkWrapper,
                    primaryNavigationListItemWrapper,
                  } = tailwindVariants({
                    hasHref:
                      (ListItem?.navigationLink?.jsonValue?.value?.href &&
                        ListItem?.navigationLink?.jsonValue?.value?.href?.length < 0) ||
                      false,
                    isActive:
                      (ListItem?.navigationLink?.jsonValue?.value?.href &&
                        ListItem?.navigationLink?.jsonValue?.value?.href === pathname) ||
                      false,
                    isDropdown: ListItem?.isDropdown?.jsonValue?.value,
                  });

                  return (
                    <li
                      className={primaryNavigationListItemWrapper()}
                      key={index}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      onKeyDown={(e) =>
                        handleKeyDown(
                          index,
                          e,
                          ListItem?.secondaryNavigations?.targetItems?.length !== 0
                        )
                      }
                    >
                      <div className={primaryNavigationListItem()}>
                        <div
                          className={primaryNavigationListItemLinkWrapper()}
                          tabIndex={
                            ListItem?.secondaryNavigations?.targetItems?.length !== 0 ? 0 : -1
                          }
                        >
                          {ListItem?.navigationLink?.jsonValue?.value?.href ? (
                            <LinkWrapper
                              className={primaryNavigationListItemLink()}
                              field={ListItem?.navigationLink?.jsonValue}
                              gtmEvent={{
                                event: 'navigation_click',
                                type: 'header',
                                'gtm.element.dataset.gtmLinkName':
                                  ListItem?.navigationLink?.jsonValue?.value.text,
                                'gtm.element.dataset.gtmLinkUrl':
                                  ListItem?.navigationLink?.jsonValue?.value?.href,
                                'gtm.element.dataset.gtmDatasourceId': dataSource,
                                'gtm.element.dataset.gtmComponentName': componentName,
                              }}
                              tabIndex={
                                ListItem?.secondaryNavigations?.targetItems?.length !== 0 ? -1 : 0
                              }
                              aria-haspopup={
                                ListItem?.secondaryNavigations?.targetItems?.length !== 0 &&
                                hoveredItem === index
                                  ? 'true'
                                  : 'false'
                              }
                            />
                          ) : (
                            <Text
                              aria-label={ListItem?.navigationTitle?.jsonValue.value}
                              className={primaryNavigationListItemLink()}
                              encode={false}
                              field={ListItem?.navigationTitle?.jsonValue}
                              onFocus={() => {
                                handleMouseEnter(index);
                              }}
                              tag="span"
                              tabIndex={-1}
                            />
                          )}
                          {ListItem?.secondaryNavigations?.targetItems?.length !== 0 && (
                            <GoogleMaterialSymbol
                              className={primaryNavigationListItemArrowDropDown()}
                              icon="arrow_drop_down"
                            />
                          )}
                        </div>
                      </div>
                      {hoveredItem === index &&
                        ListItem?.secondaryNavigations?.targetItems?.length !== 0 &&
                        !openCountryWrapper &&
                        (!ListItem?.isDropdown?.jsonValue?.value ? (
                          // Mega Menu
                          <div className={secondaryNavigationWrapper()}>
                            <ul className={secondaryNavigation()}>
                              {ListItem?.secondaryNavigations?.targetItems?.map(
                                (ListIteminner: SecondaryNavigations, index: number) => {
                                  const {
                                    secondaryNavigationListItemLinkIcon,
                                    secondaryNavigationListItemTitleLink,
                                    secondaryNavigationListItemTitleLinkWrapper,
                                  } = tailwindVariants({
                                    isActive:
                                      (ListIteminner?.navigationLink?.jsonValue?.value?.href &&
                                        ListIteminner?.navigationLink?.jsonValue?.value?.href ===
                                          pathname) ||
                                      false,
                                    isDropdown: ListItem?.isDropdown?.jsonValue?.value,
                                  });

                                  return (
                                    <li
                                      key={index}
                                      className={secondaryNavigationListItemWrapper()}
                                    >
                                      {ListIteminner?.navigationLink?.jsonValue?.value?.href && (
                                        <div
                                          className={secondaryNavigationListItemTitleLinkWrapper()}
                                        >
                                          <LinkWrapper
                                            className={secondaryNavigationListItemTitleLink()}
                                            field={ListIteminner?.navigationLink?.jsonValue}
                                            gtmEvent={{
                                              event: 'navigation_click',
                                              type: 'header',
                                              'gtm.element.dataset.gtmLinkName':
                                                ListIteminner?.navigationLink?.jsonValue?.value
                                                  .text,
                                              'gtm.element.dataset.gtmLinkUrl':
                                                ListIteminner?.navigationLink?.jsonValue?.value
                                                  ?.href,
                                              'gtm.element.dataset.gtmDatasourceId': dataSource,
                                              'gtm.element.dataset.gtmComponentName': componentName,
                                            }}
                                            tabIndex={0}
                                            onKeyDown={(e) => {
                                              if (e.key === 'Enter') {
                                                e.stopPropagation();
                                              }
                                            }}
                                          />
                                          <div
                                            className={secondaryNavigationListItemLinkIconWrapper()}
                                          >
                                            {/* Added this as a wrapper placeholder as parent div of this
                                          only renders on hover of navItem and each time
                                          GoogleMaterialSymbol  */}
                                            <GoogleMaterialSymbol
                                              className={secondaryNavigationListItemLinkIcon()}
                                              icon="chevron_right"
                                            />
                                          </div>
                                        </div>
                                      )}
                                      <ul className={secondaryNavigationItemsWrapper()}>
                                        {ListIteminner?.navigations?.targetItems?.map(
                                          (SecondaryItems: SecondaryNavigations, index: number) => {
                                            const {
                                              secondaryNavigationItemLink,
                                              secondaryNavigationListItemLinkIcon,
                                              secondaryNavigationListItemLinkIconWrapper,
                                              secondaryNavigationItem,
                                            } = tailwindVariants({
                                              isActive:
                                                (SecondaryItems?.navigationLink?.jsonValue?.value
                                                  ?.href &&
                                                  SecondaryItems?.navigationLink?.jsonValue?.value
                                                    ?.href === pathname) ||
                                                false,
                                              isDropdown: ListItem?.isDropdown?.jsonValue?.value,
                                            });

                                            return (
                                              SecondaryItems?.navigationLink?.jsonValue?.value
                                                ?.href && (
                                                <li
                                                  key={index}
                                                  className={secondaryNavigationItem()}
                                                >
                                                  <LinkWrapper
                                                    className={secondaryNavigationItemLink()}
                                                    field={
                                                      SecondaryItems?.navigationLink?.jsonValue
                                                    }
                                                    gtmEvent={{
                                                      event: 'navigation_click',
                                                      type: 'header',
                                                      'gtm.element.dataset.gtmLinkName':
                                                        SecondaryItems?.navigationLink?.jsonValue
                                                          ?.value.text,
                                                      'gtm.element.dataset.gtmLinkUrl':
                                                        SecondaryItems?.navigationLink?.jsonValue
                                                          ?.value?.href,
                                                      'gtm.element.dataset.gtmDatasourceId':
                                                        dataSource,
                                                      'gtm.element.dataset.gtmComponentName':
                                                        componentName,
                                                    }}
                                                    tabIndex={0}
                                                    onKeyDown={(e) => {
                                                      if (e.key === 'Enter') {
                                                        e.stopPropagation();
                                                      }
                                                    }}
                                                  />
                                                  <div
                                                    className={secondaryNavigationListItemLinkIconWrapper()}
                                                  >
                                                    <GoogleMaterialSymbol
                                                      className={secondaryNavigationListItemLinkIcon()}
                                                      icon="chevron_right"
                                                      variant="outlined"
                                                    />
                                                  </div>
                                                </li>
                                              )
                                            );
                                          }
                                        )}
                                      </ul>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                            {ListItem?.featuredImage?.jsonValue?.value?.src && (
                              <div className={featuredContentWrapper()}>
                                <div className={featuredContentTitleWrapper()}>
                                  <Text
                                    className={featuredContentTitle()}
                                    encode={false}
                                    field={{ value: ListItem?.featuredTitle?.jsonValue?.value }}
                                    tag="p"
                                  />
                                </div>
                                <div className={featuredContentImageWrapper()}>
                                  <ImageWrapper
                                    field={ListItem?.featuredImage?.jsonValue}
                                    className={featuredContentImage()}
                                  />
                                </div>
                                <div className={featuredContentLinksWrapper()}>
                                  <ul className={featuredContentLinks()}>
                                    {ListItem?.featuredLinks?.targetItems?.map(
                                      (featuredLinkItem: FeaturedLinks, index: number) => {
                                        return (
                                          <React.Fragment key={index}>
                                            {featuredLinkItem?.ctaLink?.jsonValue?.value?.href && (
                                              <li className={featuredContentLinkItem()}>
                                                {featuredLinkItem?.ctaLink && (
                                                  <CtaBorderAnimation
                                                    fields={featuredLinkItem?.ctaLink?.jsonValue}
                                                  />
                                                )}
                                              </li>
                                            )}
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </ul>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          // Dropdown Menu
                          <div className={secondaryDropdownWrapper()}>
                            <ul className={secondaryDropdownListItem()}>
                              {ListItem?.secondaryNavigations?.targetItems?.map(
                                (ListIteminner: SecondaryNavigations, index: number) => {
                                  return (
                                    ListIteminner?.navigationLink?.jsonValue?.value?.href && (
                                      <li key={index}>
                                        <div className={secondaryDropdownItem()}>
                                          <LinkWrapper
                                            className={secondaryDropdownItemLink()}
                                            field={ListIteminner?.navigationLink?.jsonValue}
                                            gtmEvent={{
                                              event: 'navigation_click',
                                              type: 'header',
                                              'gtm.element.dataset.gtmLinkName':
                                                ListIteminner?.navigationLink?.jsonValue?.value
                                                  .text,
                                              'gtm.element.dataset.gtmLinkUrl':
                                                ListIteminner?.navigationLink?.jsonValue?.value
                                                  ?.href,
                                              'gtm.element.dataset.gtmDatasourceId': dataSource,
                                              'gtm.element.dataset.gtmComponentName': componentName,
                                            }}
                                            tabIndex={0}
                                            onKeyDown={(e) => {
                                              if (e.key === 'Enter') {
                                                e.stopPropagation();
                                              }
                                            }}
                                            onBlur={() => {
                                              const isLastLink =
                                                index ===
                                                (ListItem?.secondaryNavigations?.targetItems
                                                  ?.length ?? 0) -
                                                  1;
                                              if (isLastLink) {
                                                handleMouseLeave();
                                              }
                                            }}
                                          />
                                          <div
                                            className={secondaryNavigationListItemLinkIconWrapper()}
                                          >
                                            <GoogleMaterialSymbol
                                              className={secondaryNavigationListItemLinkIcon()}
                                              icon="chevron_right"
                                            />
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        ))}
                    </li>
                  );
                })}
              </ul>
            </div>
          </nav>
        </div>

        {/* Search */}
        <div className={headerRight()}>
          {enableSearch?.jsonValue?.value && <Search rfkId={'rfkid_11'} {...SearchProps} />}

          {/* Language Switcher */}
          <div className={countryWrapper()}>
            <div
              className={countryWrapperInner()}
              role="button"
              tabIndex={0}
              aria-label={`Current Country and Language - ${
                matchingCountry?.name?.jsonValue?.value
                  ? matchingCountry?.name?.jsonValue?.value
                  : matchingCountry?.nameDefault?.jsonValue?.value
              } ${matchingCountry?.languageName?.jsonValue?.value}`}
              onClick={showCountryWrapper}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  showCountryWrapper();
                }
              }}
            >
              <div className={flagIconWrapper()}>
                {matchingCountry?.flagIcon?.jsonValue?.value?.src ? (
                  <ImageWrapper field={matchingCountry?.flagIcon?.jsonValue} />
                ) : (
                  <div className={logoPlaceholder()}></div>
                )}
              </div>
              <div className={currentCountry()}>
                <div className={countryInformation()}>
                  <div className={currentCountryName()}>
                    {matchingCountry?.name?.jsonValue?.value
                      ? matchingCountry?.name?.jsonValue?.value
                      : matchingCountry?.nameDefault?.jsonValue?.value}
                  </div>
                  <div className={currentCountryLanguage()}>
                    {matchingCountry?.languageName?.jsonValue?.value}
                  </div>
                </div>
                <GoogleMaterialSymbol
                  className={countryDropDownIcon()}
                  icon={openCountryWrapper ? 'arrow_drop_up' : 'arrow_drop_down'}
                />
              </div>
            </div>
            {openCountryWrapper && (
              <div className={countryListWrapper()}>
                <div className={countryListInnerWrapper()}>
                  <div className={countryListTop()}>
                    <div className={countryListHeading()}>{title?.jsonValue?.value}</div>
                  </div>
                  <div className={countryListBottom()}>
                    {regions?.targetItems?.map(
                      (country: Countrylist & NameDefault, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            {country?.countries?.targetItems?.length !== 0 &&
                              (country?.name?.jsonValue || country?.nameDefault?.jsonValue) && (
                                <div className={countryListCols()}>
                                  <div className={countryListCol()}>
                                    <Text
                                      className={countryName()}
                                      encode={false}
                                      field={
                                        country?.name?.jsonValue
                                          ? country?.name?.jsonValue
                                          : country?.nameDefault?.jsonValue
                                      }
                                      tag="p"
                                    />
                                  </div>
                                  <div className={countryList()}>
                                    {country?.countries?.targetItems?.map(
                                      (countryItem: Countrylist, index: number) => {
                                        const matchingCountryItemName = matchingCountry?.name
                                          ?.jsonValue
                                          ? matchingCountry?.name?.jsonValue
                                          : matchingCountry?.nameDefault?.jsonValue;
                                        const countryItemName = countryItem?.name?.jsonValue
                                          ? countryItem?.name?.jsonValue
                                          : countryItem?.nameDefault?.jsonValue;
                                        const isMatchingCountry =
                                          matchingCountryItemName?.value ===
                                            countryItemName?.value &&
                                          matchingCountry?.languageName?.jsonValue?.value ===
                                            countryItem?.languageName?.jsonValue?.value;

                                        const { countryItemLinkWrapper } = tailwindVariants({
                                          matchingCountry: isMatchingCountry,
                                        });

                                        // use interanl links to conrol languge switching. external links just redirect to sites we haven't built yet.
                                        const linkField = countryItem?.link?.jsonValue;
                                        if (linkField?.value?.linktype == 'internal') {
                                          linkField.value.href = '';
                                        }

                                        return (
                                          <div className={countryItemLinkWrapper()} key={index}>
                                            <LinkWrapper
                                              aria-label={`Set Country and Language to ${countryItemName?.value} ${countryItem?.languageName?.jsonValue?.value}`}
                                              className={countryItemLink()}
                                              field={countryItem?.link?.jsonValue}
                                              showLinkTextWithChildrenPresent={false}
                                              target={countryItem?.link?.jsonValue?.value?.target}
                                              locale={countryItem?.languageCode?.jsonValue?.value?.toLocaleLowerCase()}
                                            >
                                              <div className={countryListItemLinkleft()}>
                                                {countryItem?.flagIcon?.jsonValue?.value?.src && (
                                                  <ImageWrapper
                                                    field={countryItem?.flagIcon?.jsonValue}
                                                  />
                                                )}
                                                <div className={countryListItemLinkleftInfo()}>
                                                  <Text
                                                    className={countryListItemLinkleftInfoTitle()}
                                                    encode={false}
                                                    field={countryItemName}
                                                    tag="p"
                                                  />
                                                  <span
                                                    className={countryListItemLinkleftInfoSubTitle()}
                                                  >
                                                    {countryItem?.languageName?.jsonValue?.value}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className={countryListRight()}>
                                                <div className={countrySelection()}>
                                                  <>
                                                    <GoogleMaterialSymbol
                                                      className={`${
                                                        isMatchingCountry
                                                          ? 'fill-color-system-success-400'
                                                          : countryListRightIcon()
                                                      } `}
                                                      icon="done"
                                                    />
                                                  </>
                                                </div>
                                              </div>
                                            </LinkWrapper>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              )}
                          </React.Fragment>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderDesktop;
