import jssConfig from 'src/temp/config';

type googleCaptchaModel = {
  key: string;
  hostName: string;
};

const GetGoogleCaptcha = () => {
  const CAPTHCA_KEY = jssConfig.sitecoreRecaptchaKey;
  const hostName = typeof window !== 'undefined' ? window.location.hostname : '';
  const googleCaptchadata: googleCaptchaModel[] = JSON.parse(CAPTHCA_KEY);

  const googleCaptchKey =
    googleCaptchadata?.find((o) => o.hostName === hostName)?.key ||
    jssConfig.sitecoreDefaultRecaptchaKey;
  return googleCaptchKey;
};

export default GetGoogleCaptcha;
