// function to trap focus in selected elements childern nodes
/**
 *
 * @param element : Parent Element
 */

export function focusTrap(element: HTMLElement, handleEscFunction?: () => void): () => void {
  // add any focusable HTML element you want to include to this string
  const focusableElements = element.querySelectorAll(
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
  );
  const firstElement = focusableElements[0];
  const tabKeyCode = 9;

  // initialy focus on first focusable element
  (firstElement as HTMLElement)?.focus();

  const trapFocus = (e: KeyboardEvent) => {
    const focusableElements = element.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );

    const lastElement = focusableElements[focusableElements.length - 1];

    const isTabPressed = e.key === 'Tab' || e.keyCode === tabKeyCode;

    // return if key other than Tab is pressed
    if (!isTabPressed && e.key === 'Escape' && handleEscFunction) {
      handleEscFunction();
      return;
    }

    if (e.shiftKey && document.activeElement === firstElement) {
      e.preventDefault();
      (lastElement as HTMLElement)?.focus();
    } else if (!e.shiftKey && isTabPressed && document.activeElement === lastElement) {
      e.preventDefault();
      (firstElement as HTMLElement)?.focus();
    }
  };

  // add keydown function
  element.addEventListener('keydown', trapFocus);

  // return cleanup function
  return () => {
    element.removeEventListener('keydown', trapFocus);
  };
}
