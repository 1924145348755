// Global
import { sendGTMEvent } from '@next/third-parties/google';
import { Item, Placeholder, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useState } from 'react';
import { tv } from 'tailwind-variants';
import graphQLClientFactory from 'lib/graphql-client-factory';

// Lib
import { ComponentProps } from 'lib/component-props';
import { CompositeComponents, Ingredients } from 'lib/templates/Feature.Dart.model';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import Video from 'helpers/Video/Video';
import { stripHtml } from 'lib/utils/regex';
import LegalDisclaimer from 'helpers/LegalDisclaimer/LegalDisclaimer';
import Button from 'helpers/Button/Button';
import { GetStaticComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import { IngredientQueryService } from 'lib/ingredient-service';
import IngredientQuery from '../GlossaryTab/Ingredient.graphql';
import { ALL_THEMES, useTheme } from 'lib/context/ThemeContext';

type Alignment = 'left' | 'right' | undefined;

export type AccordionListProps = ComponentProps &
  CompositeComponents.Accordion.AccordionList & { staticProps?: RawGqlData[] };
export type AccordionList = CompositeComponents.Accordion.Accordion;

export type IngredientsDataType = Ingredients.Ingredient &
  Ingredients.Allergen &
  Ingredients.Fragrance;

export type IngredientIndex = Ingredients.IngredientIndex;

export type RawGqlData = {
  ingredients: IngredientData[];
};

export type IngredientData = {
  title: {
    value: string;
  };
  definition: {
    value: string;
  };
  active: {
    value: boolean;
  };
};

const Accordion = (props: AccordionListProps): JSX.Element => {
  const { themeName } = useTheme();

  const themeVariants = ALL_THEMES.reduce(
    (acc, curr) => ((acc[curr] = {}), acc),
    {} as Record<string, object>
  );

  /**
   * Check the configuration for the Accordion
   * Read Rendering parameter for option
   * 1. Accordion Can open at a time
   * 2. One Accordion Open at a time
   * 3. Multiple Accordion can open at the same Time
   */
  const tailwindVariants = tv({
    slots: {
      backgroundBG: ['bg-components-accordion-color-section-section-bg'],
      base: [
        'flex',
        'radius-themes-radius-large-general',
        'py-components-accordion-spacing-small-padding-y',
        'px-components-accordion-spacing-small-padding-x',
        'md:px-components-accordion-spacing-large-padding-x',
        'md:py-components-accordion-spacing-large-padding-y',
      ],
      componentDescription: [
        'font-bodySans-small',
        'leading-bodySans-small',
        'text-bodySans-small',
        'text-components-accordion-color-body',
        'md:leading-bodySans-medium',
        'md:font-bodySans-medium',
        'md:text-bodySans-medium',
      ],
      componentHeaderContentWrapper: ['flex-1', 'mb-spacing-margin-small-6', 'md:mb-0'],
      componentTitle: [
        'font-header-small-small',
        'leading-header-small-small',
        'text-header-small-small',
        'text-components-accordion-color-title',
        'mb-components-accordion-spacing-small-section-title-margin-bottom',
        'md:font-header-large-small',
        'md:leading-leading-header-large-small',
        'md:text-header-large-small',
        'md:mb-components-accordion-spacing-large-section-title-margin-bottom',
      ],
      pane: [
        'hover:cursor-pointer',
        'bg-components-accordion-color-item-item-bg',
        'border-solid',
        'border-components-accordion-color-item-divider',
        'flex',
        'flex-col',
        'border-t-2',
        'border-b-2',
        'mb-components-accordion-spacing-small-item-item-space-between',
        'rounded-themes-radius-small-item',
        'px-components-accordion-spacing-small-item-padding-x',
        'last:mb-0',
        'md:mb-components-accordion-spacing-large-item-item-space-between',
        'md:px-components-accordion-spacing-large-item-padding-x',
        'md:rounded-components-accordion-spacing-large-item-item-radius',
      ],
      paneBody: [
        'paneBody',
        'hover:cursor-auto',
        'font-bodySans-small',
        'leading-bodySans-small',
        'text-bodySans-small',
        'text-components-accordion-color-content-body',
        'py-components-accordion-spacing-large-content-padding-y',
        'md:font-bodySans-medium',
        'md:leading-bodySans-medium',
        'md:text-bodySans-medium',
      ],
      paneHeader: [
        'md:flex',
        'md:items-center',
        'md:gap-x-components-top-title-spacing-large-body-margin-right',
        'mb-components-accordion-spacing-small-copy-margin-bottom',
        'md:mb-components-accordion-spacing-large-section-copy-margin-bottom',
      ],
      paneBodyWrapper: [],
      paneTitle: ['align-stretch', 'flex', 'flex-1', 'flex-col', 'justify-center'],
      paneTitleButton: [
        'flex',
        'items-center',
        'justify-between',
        'font-bodySans-large-semibold',
        'leading-bodySans-large-semibold',
        'text-bodySans-large-semibold',
        'text-components-accordion-color-item-title-item-default',
        'py-components-accordion-spacing-small-item-padding-y',
        'md:py-components-accordion-spacing-large-item-padding-y',
        'text-left',
        'w-full',
      ],
      paneTitleIcon: [
        'fill-components-accordion-color-item-icon-icon-default',
        '[&_svg]:h-[32px]',
        '[&_svg]:w-[32px]',
      ],
      paneTitleText: [
        'font-bodySans-large-semibold',
        'text-bodySans-large-semibold',
        'leading-bodySans-large-semibold',
        'text-components-accordion-color-item-title-item-default',
        'w-full',
      ],
      paneBodyMediaWrapper: [
        'flex',
        'md:py-components-accordion-spacing-large-content-content-space-between',
        'py-components-accordion-spacing-small-content-content-space-between',
        'w-full',
        'justify-center',
        '[&_img]:rounded-themes-radius-large-image',
      ],
      paneWrapper: ['flex', 'flex-col'],
      videoClasses: ['w-full', 'h-auto', 'aspect-video', 'max-w-[720px]'],
      wrapper: ['mx-auto', 'w-full', 'md:max-w-components-accordion-spacing-large-max-width'],
      disclaimerClasses: ['pt-components-accordion-spacing-large-content-content-space-between'],
      themeBasedContainerClass: [],
      glossaryTabContainer: [],
      ingredientContainer: [],
      accordionTabContentHeader: [],
      ingredientTitle: [],
    },
    variants: {
      alignment: {
        left: {
          paneBody: [
            'text-left',
            'md:py-spacing-spacing-padding-large-5',
            'md:px-components-accordion-spacing-large-content-padding-x',
            'md:py-components-accordion-spacing-large-content-padding-y',
            'py-components-accordion-spacing-small-content-padding-y',
            'px-components-accordion-spacing-small-content-padding-x',
          ],
          paneTitle: ['flex-row'],
          paneTitleButton: ['flex-row', 'text-left'],
        },
        right: {
          paneBody: ['text-right'],
          paneTitle: ['flex-row-reverse'],
          paneTitleButton: ['flex-row-reverse', 'text-right'],
        },
      },
      isOpen: {
        false: {
          paneBodyWrapper: ['hidden'],
        },
        true: {
          paneBodyWrapper: ['block'],
        },
      },
      ingredientLength: {
        false: {
          wrapper: [],
        },
        true: {
          base: ['mt-components-accordion-spacing-large-content-padding-y'],
          wrapper: ['!mx-0', 'w-full', 'md:!max-w-full'],
        },
      },
      brand: {
        ...themeVariants,
        Corporate: {
          base: ['mx-0'],
          glossaryTabContainer: [
            'glossaryTabContainer',
            'hover:cursor-auto',
            'flex',
            'flex-col',
            'px-components-accordion-spacing-large-content-padding-x',
            'py-components-accordion-spacing-large-content-padding-y',
          ],
          accordionTabContentHeader: [
            'accordionTabContentHeader',
            'text-header-large-xSmall',
            'font-header-large-xSmall',
            'leading-header-large-xSmall',
            'pb-components-content-list-spacing-large-item-title-margin-bottom',
            'gap-components-accordion-spacing-large-content-content-space-between',
          ],
          ingredientContainer: [
            'grid',
            'md:grid-cols-3',
            'grid-cols-1',
            'gap-x-spacing-spacing-8',
            'list-disc',
            'list-outside',
            'ml-spacing-spacing-5',
            'pb-spacing-spacing-7',
          ],
          ingredientTitle: [
            'ingredientTitle',
            'break-all',
            'pb-spacing-spacing-3',
            'text-bodySans-medium',
            'font-bodySans-medium',
            'leading-bodySans-medium',
            'text-components-content-list-color-body',
          ],
          disclaimerClasses: [
            'pt-components-accordion-spacing-large-content-content-space-between',
          ],
        },
      },
    },
  });

  const { accordions, description, title, disclaimerText, CTA } = props?.fields ?? {};
  const ingredientLength = props?.staticProps || '';

  const {
    enableRTL: alignmentParam,
    expandedFirstAccordionOnPageLoad: expandedFirstAccordionOnPageLoadParam,
    openMultipleAccordionAtSameTime,
  } = props?.params ?? {};

  // Check Rendering Parameter for Accordion Configuration
  const alignment = alignmentParam === '1' ? 'right' : 'left';
  const expandedFirstAccordionOnPageLoad = expandedFirstAccordionOnPageLoadParam ? 0 : -1;

  const {
    base,
    componentHeaderContentWrapper,
    componentDescription,
    componentTitle,
    pane,
    paneBody,
    paneHeader,
    paneBodyMediaWrapper,
    paneTitle,
    paneTitleButton,
    paneTitleIcon,
    paneTitleText,
    paneWrapper,
    videoClasses,
    wrapper,
    backgroundBG,
    disclaimerClasses,
    themeBasedContainerClass,
    glossaryTabContainer,
    ingredientContainer,
    accordionTabContentHeader,
    ingredientTitle,
  } = tailwindVariants({
    alignment: alignment as Alignment,
    ingredientLength: (ingredientLength?.length > 0) as boolean,
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
  });

  // Set State
  const [activeIndexes, setActiveIndexes] = useState<number[]>([expandedFirstAccordionOnPageLoad]);

  /**
   * handleAccordionClick function is to handle Accordion.
   * 1. It can open one at a time
   * 2. It can open multiple at a time
   * @param index : to manage accordion item
   * @param openMultipleAccordionAtSameTime : pass accordion configuration
   */

  const handleAccordionClick = (
    index: number,
    openMultipleAccordionAtSameTime: string | undefined,
    id: string | undefined,
    name: string | undefined
  ) => {
    // Set Indexes for Accordion
    if (openMultipleAccordionAtSameTime) {
      setActiveIndexes([...activeIndexes, index]);
    } else {
      setActiveIndexes([index]);
    }

    // Filter indexes for Accordion
    if (activeIndexes.includes(index)) {
      sendGTMEvent({
        event: 'accordion',
        type: 'close',
        'gtm.element.dataset.gtmDatasourceId': id,
        'gtm.element.dataset.gtmComponentName': name,
      });
      setActiveIndexes(activeIndexes.filter((item) => item !== index));
    } else {
      sendGTMEvent({
        event: 'accordion',
        type: 'open',
        'gtm.element.dataset.gtmDatasourceId': id,
        'gtm.element.dataset.gtmComponentName': name,
      });
    }
  };

  const id = props?.params?.RenderingIdentifier;

  return (
    <div
      className={backgroundBG()}
      data-component="authorable/accordion"
      id={id ? id : undefined}
      tabIndex={-1}
    >
      <Container className={themeBasedContainerClass()}>
        <div
          data-component="authorable/general/accordion"
          data-testid="accordion"
          className={base()}
        >
          <div className={wrapper()}>
            <div className={paneWrapper()}>
              {(title?.value || description?.value) && (
                <div className={paneHeader()}>
                  <div className={componentHeaderContentWrapper()}>
                    {title?.value && (
                      <div className={componentTitle()}>
                        <Text encode={false} field={title} tag="h2" />
                      </div>
                    )}
                    {description?.value && (
                      <div className={componentDescription()}>
                        <Text encode={false} field={description} tag="p" />
                      </div>
                    )}
                  </div>
                  {CTA?.value?.text && CTA?.value?.href && (
                    <Button
                      label={CTA.value.text}
                      title={CTA?.value?.title}
                      type="outline"
                      tag="a"
                      href={CTA.value.href}
                      target={CTA?.value?.target}
                    />
                  )}
                </div>
              )}
              {accordions?.map((accordion: AccordionList, index: number) => {
                const { paneBodyWrapper } = tailwindVariants({
                  isOpen: activeIndexes.includes(index) as boolean,
                });

                const { bottomDescription, topDescription, image, video, usePlaceholder } =
                  accordion?.fields ?? {};
                const { id, name } = (accordion as Item) || {};
                /* eslint-disable  @typescript-eslint/no-explicit-any */
                const { useIngredients } = accordion?.fields as any;

                const phKey = `dartaccordionrow-${index}-{*}`;
                return (
                  <React.Fragment key={index}>
                    {/* TODO: components-accordion-spacing-large-item-item-radius is missing */}
                    <div className={pane()} data-id={index} key={index}>
                      <div className={paneTitle()}>
                        <button
                          aria-label={stripHtml(accordion?.fields?.accordionLabel?.value as string)}
                          type="button"
                          className={paneTitleButton()}
                          onClick={() =>
                            handleAccordionClick(index, openMultipleAccordionAtSameTime, id, name)
                          }
                          aria-expanded={activeIndexes.includes(index)}
                        >
                          <div className={paneTitleText()}>
                            <RichTextA11yWrapper field={accordion?.fields?.accordionLabel} />
                          </div>
                          <GoogleMaterialSymbol
                            className={paneTitleIcon()}
                            icon={activeIndexes.includes(index) ? 'expand_less' : 'expand_more'}
                          />
                        </button>
                      </div>
                      <div
                        id={`accordion-panel-${index}-${id}`}
                        aria-labelledby={`accordion-panel-${index}-${id}-label`}
                        aria-expanded={activeIndexes.includes(index)}
                        className={paneBodyWrapper()}
                      >
                        {/* For Glossary Component, added this condition when we have Ingredients available then render Ingredients else render usePlaceholder content used in general Accordion Content. */}
                        {useIngredients?.value ? (
                          <div className={glossaryTabContainer()}>
                            {accordion?.fields?.ingredients &&
                              accordion?.fields?.ingredients?.map(
                                (ingredientData: IngredientIndex) => {
                                  const ingredientIndexTitle = ingredientData?.fields?.title
                                    ?.value as string;
                                  return (
                                    <>
                                      {/* Display the folder title */}
                                      {ingredientIndexTitle && (
                                        <div className={accordionTabContentHeader()}>
                                          <RichTextA11yWrapper
                                            field={{
                                              value: ingredientIndexTitle,
                                            }}
                                          />
                                        </div>
                                      )}
                                      <div key={index} className="ingredientWrapper">
                                        <ul key={index} className={ingredientContainer()}>
                                          {/* Ingredients Data */}
                                          {props?.staticProps &&
                                            props?.staticProps?.map((item: RawGqlData) => {
                                              return (
                                                <>
                                                  {item?.ingredients
                                                    ?.filter((ingredient: IngredientData) => {
                                                      const ingredientTitle =
                                                        ingredient?.title?.value;

                                                      if (!ingredientTitle || !ingredientIndexTitle)
                                                        return false;

                                                      // If ingredientIndexTitle is "0", match ingredients starting with 0-9
                                                      if (ingredientIndexTitle === '0') {
                                                        return /^[0-9]/.test(ingredientTitle);
                                                      }

                                                      // If ingredientIndexTitle is "Hashtag", match ingredients starting with any special character
                                                      if (ingredientIndexTitle === '#') {
                                                        return /^[^a-zA-Z0-9]/.test(
                                                          ingredientTitle
                                                        ); // Regex to match titles starting with non-alphanumeric characters
                                                      }
                                                      // Otherwise, match ingredients that start with the ingredientIndexTitle alphabet
                                                      return ingredientTitle
                                                        .toLowerCase()
                                                        .startsWith(
                                                          ingredientIndexTitle.toLowerCase()
                                                        );
                                                    })
                                                    // Filter ingredients based on ingredientIndexTitle
                                                    .map(
                                                      (
                                                        ingredient: IngredientData,
                                                        index: number
                                                      ) => {
                                                        return (
                                                          <>
                                                            {ingredient?.title?.value && (
                                                              <div
                                                                className={ingredientTitle()}
                                                                key={index}
                                                              >
                                                                <Text
                                                                  field={{
                                                                    value: ingredient?.title?.value,
                                                                  }}
                                                                  tag="li"
                                                                />
                                                              </div>
                                                            )}
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                </>
                                              );
                                            })}
                                        </ul>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            {/* Disclamier */}
                            {accordion?.fields?.disclaimerText?.value != '' && (
                              <LegalDisclaimer
                                disclaimerText={accordion?.fields?.disclaimerText}
                                disclaimerClasses={disclaimerClasses()}
                              />
                            )}
                          </div>
                        ) : (
                          <div className={paneBody()}>
                            {usePlaceholder?.value ? (
                              <Placeholder key={index} name={phKey} rendering={props.rendering} />
                            ) : (
                              <>
                                {/* General Accordion throughout Brands */}
                                <RichTextA11yWrapper field={topDescription} />
                                {image?.value?.src ? (
                                  <div className={paneBodyMediaWrapper()}>
                                    <ImageWrapper field={image} />
                                  </div>
                                ) : (
                                  <>
                                    {video?.value && (
                                      <div className={paneBodyMediaWrapper()}>
                                        <Video class={videoClasses()} responsive field={video} />
                                      </div>
                                    )}
                                  </>
                                )}
                                <RichTextA11yWrapper field={bottomDescription} />
                                {accordion?.fields?.disclaimerText?.value !== '' && (
                                  <LegalDisclaimer
                                    disclaimerText={accordion?.fields?.disclaimerText}
                                    disclaimerClasses={disclaimerClasses()}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            {disclaimerText?.value != '' && (
              <LegalDisclaimer
                disclaimerText={disclaimerText}
                disclaimerClasses={disclaimerClasses()}
              />
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Accordion;

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  const fields = rendering?.fields?.accordions as CompositeComponents.Accordion.Accordion[];

  const isIngredients = fields[0].fields?.ingredients;

  if (isIngredients && isIngredients?.length > 0) {
    const graphQLClient = graphQLClientFactory({});
    const ingredientService = new IngredientQueryService<RawGqlData>(graphQLClient);
    const results = await ingredientService.fetch(IngredientQuery, {
      datasource: rendering.dataSource,
      language: layoutData?.sitecore?.context?.language,
    });
    return {
      staticProps: results,
    };
  } else {
    return {
      staticProps: [],
    };
  }
};
