// Global
import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { useSearchParams } from 'next/navigation';
import { tv } from 'tailwind-variants';

// Lib
import { ComponentProps } from 'lib/component-props';
import { ALL_THEMES, useTheme } from 'lib/context/ThemeContext';
import { SearchComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import SVG from 'helpers/SVG/SVG';
import SearchPreview from 'src/widgets/SearchPreview';
import { ComponentVariants } from 'lib/context/ComponentVariants';

export type SearchHeroProps = ComponentProps & SearchComponents.SearchHero.SearchHero;

type ShowPattern = true | false | undefined;

const themeVariants = ALL_THEMES.reduce(
  (acc, curr) => ((acc[curr] = {}), acc),
  {} as Record<string, object>
);

const tailwindVariants = tv({
  slots: {
    base: ['SearchHero', 'component'],
    searchHeroWrapper: [
      'component-content',
      'flex',
      'flex-col',
      'justify-center',
      'relative',
      'h-full',
      'min-h-components-hero-internal-spacing-large-min-height',
      'py-components-site-search-hero-search-small-padding-y',
      'px-components-site-search-hero-search-small-padding-x',
      'gap-components-site-search-hero-search-margin-bottom',
      'md:py-components-site-search-hero-search-large-padding-y',
      'md:px-components-site-search-hero-search-large-padding-x',
    ],
    searchHeroTitleWrapper: ['field-searchHeroTitle'],
    searchHeroTitle: [
      'font-header-small-large',
      'leading-header-small-large',
      'text-header-small-large',
      'text-components-site-search-hero-search-title',
      'z-10',
      'relative',
      'md:font-header-large-large',
      'md:leading-header-large-large',
      'md:text-header-large-large',
    ],
    searchHeroInputWrapper: ['search-input-wrapper', 'z-10'],
    searchHeroSvg: [
      'absolute',
      'left-0',
      'top-0',
      'w-full',
      'h-full',
      'overflow-hidden',
      'hidden',
      '[&>*]:h-full',
      '[&>*]:top-0',
      '[&>*]:w-full',
      '[&>*]:absolute',
    ],
    svgDecor: ['w-full', 'absolute', 'bottom-0', 'left-0', 'z-10', 'hidden'],
  },
  variants: {
    showPattern: {
      true: {
        searchHeroSvg: ['hidden', 'md:block'],
        svgDecor: ['hidden', 'md:block'],
        searchHeroWrapper: [
          'bg-components-hero-internal-color-accent-bg',
          'md:bg-components-hero-internal-color-default-bg',
        ],
      },
      false: {
        searchHeroWrapper: ['bg-components-hero-internal-color-default-bg'],
      },
    },
    brand: {
      ...themeVariants,
      Glade: {
        searchHeroSvg: ['left-[33%]', 'lg:left-[38%]'],
      },
    },
  },
});

const SearchHero = (props: SearchHeroProps): JSX.Element => {
  const {
    title,
    searchPlaceholderText,
    enablePattern,
    autoSuggestionItems,
    resultSuggestionItems,
  } = props?.fields || {};
  const showPattern = enablePattern?.value ? true : false;

  const { themeName } = useTheme();

  const SearchPreviewProps = {
    autoSuggestionItems: autoSuggestionItems?.value || 5,
    resultSuggestionItems: resultSuggestionItems?.value || 5,
    searchPlaceholderText: searchPlaceholderText?.value,
    defaultValue: '',
  };

  const {
    base,
    searchHeroWrapper,
    searchHeroSvg,
    svgDecor,
    searchHeroTitleWrapper,
    searchHeroTitle,
    searchHeroInputWrapper,
  } = tailwindVariants({
    showPattern: showPattern as ShowPattern,
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
  });

  const searchParams = useSearchParams();
  const searchQuery = searchParams.get('q') || '';

  const componentVariants = ComponentVariants();

  if (!props?.fields) return <></>;
  // Unique id for component
  const id = props?.params?.RenderingIdentifier;
  return (
    <Container fullWidth>
      <div
        className={base()}
        data-component="authorable/heroes/searchhero"
        id={id ? id : undefined}
        tabIndex={id ? -1 : 1}
      >
        <div className={searchHeroWrapper()}>
          {componentVariants?.heroInternal?.hasDecor && (
            <SVG className={svgDecor()} svg={`hero-internal/Decor,Brand=${themeName}`} />
          )}
          <SVG className={searchHeroSvg()} svg={`hero-internal/BG,Brand=${themeName}`} />
          <div className={searchHeroTitleWrapper()}>
            <Text className={searchHeroTitle()} encode={false} field={title} tag="h2" />
          </div>
          <div className={searchHeroInputWrapper()}>
            <SearchPreview
              rfkId={'rfkid_11'}
              key={`${searchQuery}-search-preview`}
              {...SearchPreviewProps}
              defaultValue={searchQuery}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};
export default SearchHero;
