import { GetStaticComponentProps, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import graphQLClientFactory from 'lib/graphql-client-factory';
import React from 'react';
import config from 'temp/config';
import WhereToBuyQuery from './WhereToBuy.graphql';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-nextjs';
import startItemQuery from './startItemQuery.graphql';

export type rawStartItem = {
  layout: {
    item: {
      id: string;
    };
  };
};

export type uanNumber = {
  staticProps: {
    search: {
      results: {
        productInformation: {
          targetItem: {
            variantItems: {
              jsonValue: {
                fields: {
                  ean: {
                    value: string;
                  };
                };
              }[];
            };
          };
        };
      }[];
    };
  };
  rendering: ComponentRendering;
};

const WhereToBuy = (staticProps: uanNumber) => {
  // Extracting and joining the fields
  const eanNumbers = staticProps?.staticProps?.search?.results;
  const dataEans = eanNumbers
    .map((product) =>
      product?.productInformation?.targetItem?.variantItems?.jsonValue?.map(
        (vi) => vi?.fields?.ean?.value
      )
    )
    .flat();
  const { sitecoreContext } = useSitecoreContext();
  const dataEansUnique = [...new Set(dataEans)].join(',');
  if (!dataEansUnique?.length) <></>;
  return (
    <>
      <div
        id="mikmak-widget"
        data-mm-wtbid={sitecoreContext?.integrationMikMak?.mikMakProductListWidgetID}
        data-mm-ids={dataEansUnique}
      ></div>
    </>
  );
};

export default WhereToBuy;

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  const graphQLClient = graphQLClientFactory({});

  const startItemLookup = await graphQLClient.request<rawStartItem>(startItemQuery, {
    language: layoutData?.sitecore?.context?.language,
    site: config.sitecoreSiteName,
  });
  const result = await graphQLClient.request<unknown>(WhereToBuyQuery, {
    datasource: rendering.dataSource,
    language: layoutData?.sitecore?.context?.language,
    root: startItemLookup.layout.item.id,
  });
  return {
    staticProps: result,
  };
};
