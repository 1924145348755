// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
// Lib
import { ComponentProps } from 'lib/component-props';
import { ALL_THEMES, useTheme } from 'lib/context/ThemeContext';
import { CardComponents } from 'lib/templates/Feature.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';
// Local
import Button from 'helpers/Button/Button';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import tokensTheme from 'src/theme/tokens.theme';
import SVG from 'helpers/SVG/SVG';
import { stripHtml } from 'lib/utils/regex';
import LegalDisclaimer from 'helpers/LegalDisclaimer/LegalDisclaimer';
import fallback from 'lib/fallback/fallback';

// Types
interface ObjectStructure {
  bgcolor: string;
}
interface backgroundColor {
  backgroundColor: string;
}
export type CategoryCard = ItemEx & CardComponents.CategoryCard.CategoryCardItem;
export type CategoryCardProps = ComponentProps & CardComponents.CategoryCard.CategoryCardsList;
export type CategoryCardItem = backgroundColor & CardComponents.CategoryCard.CategoryCardItem;
type AlignCTA = 'BottomCenter' | 'TopRight' | undefined;

const themeVariants = ALL_THEMES.reduce(
  (acc, curr) => ((acc[curr] = {}), acc),
  {} as Record<string, object>
);
const tailwindVariants = tv({
  slots: {
    iconBg: [],
    base: [
      'flex',
      'flex-col',
      'py-components-category-card-listing-spacing-small-padding-y',
      'px-components-category-card-listing-spacing-small-padding-x',
      'md:p-components-category-card-listing-spacing-large-padding-y',
      'bg-components-category-card-listing-color-bg',
    ],
    TitleContainer: [
      'flex',
      'flex-col',
      'align-center',
      'items-stretch',
      'md:gap-components-top-title-spacing-large-body-margin-bottom',
      'md:pb-components-top-title-spacing-large-margin-bottom',
    ],
    Title: [
      'text-components-category-card-listing-color-title',
      'md:font-header-large-large',
      'md:text-header-large-large',
      'md:leading-header-large-large',
      'md:tracking-header-large-large',
      'font-header-small-large',
      'text-header-small-large',
      'leading-header-small-large',
      'tracking-header-small-large',
    ],
    headerContainer: [
      'flex',
      'justify-between',
      'mb-components-top-title-spacing-small-margin-bottom',
      'w-full',
      'md:mb-components-top-title-spacing-large-margin-bottom',
      'flex-col',
      'md:flex-row',
    ],
    headerDescriptionText: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'md:font-bodySans-medium',
      'md:leading-bodySans-medium',
      'md:text-bodySans-medium',
      'text-components-category-card-listing-color-copy',
      'max-md:mb-components-top-title-spacing-small-body-margin-bottom',
    ],
    headerTitleContainer: [
      'flex',
      'flex-col',
      'lg:mb-0',
      'md:mr-components-top-title-spacing-large-body-margin-right',
    ],
    headerTitleText: [
      'font-header-small-large',
      'text-header-small-large',
      'leading-header-small-large',
      'text-components-category-card-listing-color-title',
      'md:font-header-large-large',
      'md:leading-header-large-large',
      'md:text-header-large-large',
      'md:mb-components-top-title-spacing-large-title-margin-bottom',
      'mb-components-top-title-spacing-small-title-margin-bottom',
    ],
    ctaContainer: ['flex', 'items-center'],
    cardContainer: [
      'flex',
      'flex-row',
      'gap-components-category-card-listing-spacing-large-card-space-between',
      'justify-start',
      'flex-wrap',
      'self-stretch',
      'item-stretch',
      'relative',
    ],
    cardWrapper: ['md:w-[calc(theme(width.1/3)-18px)]', 'flex', 'w-full', 'group'],
    cardTitle: [
      'font-header-large-xSmall',
      'leading-header-large-xSmall',
      'text-header-large-xSmall',
      'text-components-category-card-color-title',
      'md:tracking-cardSide',
      'tracking-cardSideTighter',
    ],
    card: [
      'flex',
      'w-full',
      'bg-components-category-card-color-content-bg',
      'border-solid',
      'border-components-category-card-color-stroke',
      'border-2',
      'flex-row',
      'gap-0',
      'items-start',
      'justify-start',
      'relative',
      'overflow-hidden',
      'rounded-themes-radius-large-card',
      'hover:border-components-category-card-color-stroke-hover',
    ],
    cardContent: [
      'flex',
      'flex-row',
      'gap-spacing-space-between-large-2',
      'items-center',
      'justify-start',
      'relative',
    ],
    cardContentWrapper: [
      'p-spacing-spacing-4',
      'flex',
      'flex-row',
      'gap-spacing-padding-large-3',
      'items-center',
      'justify-start',
      'self-stretch',
      'flex-1',
      'relative',
    ],
    cardMediaContainer: ['flex-1', 'relative', 'overflow-hidden'],
    cardMediaWrapper: [
      'p-2',
      'flex',
      'flex-row',
      'gap-2',
      'items-center',
      'justify-center',
      'md:w-2/5',
      'w-1/3',
      'relative',
      'overflow-hidden',
      'group-hover:scale-105',
      'group-hover:transition',
      'group-hover:duration-500',
      'scale-100',
      'duration-500',
      'transition',
    ],
    cardIcon: ['fill-components-category-card-color-title'],
    cardSVG: [
      'absolute',
      'transition-transform',
      'ease-in',
      'translate-y-[0]',
      'md:group-hover:translate-y-[2%]',
      'md:group-hover:transition',
      'md:group-hover:duration-500',
      'md:duration-400',
      'transition',
      'w-[100%]',
      'md:w-auto',
      '[&_svg]:w-auto',
      '[&_svg]:h-auto',
    ],
    legalDisclaimerText: ['self-start', 'mt-spacing-spacing-5'],
  },
  variants: {
    alignCTA: {
      BottomCenter: {
        ctaContainer: [
          'align-center',
          'flex',
          'mt-components-category-card-listing-spacing-small-button-margin-top',
          'md:mt-components-category-card-listing-spacing-large-button-margin-top',
          'w-full',
          'md:[&>a]:w-auto',
          '[&>a]:w-full',
          'justify-center',
        ],
        headerTitleContainer: ['w-full', 'text-center'],
      },
      TopRight: {
        ctaContainer: ['flex', 'items-center'],
      },
    },
    size: {
      small: {
        cardSVG: ['md:hidden', 'block'],
      },
      large: {
        cardSVG: ['md:block', 'hidden'],
      },
    },
    brand: {
      ...themeVariants,
      Ziploc: {
        cardSVG: ['right-[58%]', 'md:top-[-13%]', 'md:right-[-87%]'],
      },
      Off: {
        cardSVG: ['right-[40%]', 'md:right-[-50%]'],
      },
      Autan: {
        cardSVG: ['right-[60%]', 'md:right-[-50%]'],
      },
      AutanDefense: {
        cardSVG: ['top-[-2%]'],
      },
      OffDefense: {
        cardSVG: ['top-[-2%]'],
      },
      Glade: {
        cardSVG: ['top-[-2%]', 'left-[0%]'],
        legalDisclaimerText: ['!text-components-category-card-listing-color-copy'],
      },
    },
  },
});

// Add fallback component variant color
const fallbackComponentVariantColor = fallback?.componentVariants?.value;
const fallbackComponentVariantType = fallback?.componentVariants?.type;

const CategoryCardListingSideBySide = ({ ...props }: CategoryCardProps) => {
  const {
    title,
    description,
    primaryCTA,
    enablePattern,
    disclaimerText,
    primaryCTAType,
    primaryCTAColor,
  } = props?.fields || {};
  const { alignCTA } = props?.params || '';
  const { componentName, dataSource } = props?.rendering || {};
  /**
   * Filter background colors for the category card background.
   * 1. Get theme name
   * 2. Get Colors
   * 3. filter out components-category-card-color-category
   */
  const { themeName, tailwindThemeName } = useTheme();
  // Get colors for specific theme
  const variantGroup = tokensTheme?.brands?.[tailwindThemeName]?.colors;
  // Filter out background
  const filterBackground = (obj: typeof variantGroup) => {
    const result = [];
    for (const key in obj) {
      if (key.includes('components-category-card-color-category')) {
        result.push(`fill-${key}`);
      }
    }
    return result;
  };
  // Call the function
  const variants = filterBackground(variantGroup);
  // map trhough the key and create new array
  const result: ObjectStructure[] = variants.map((item) => ({ bgcolor: item }));
  // Combine Background Color and Category Props into a single array
  const combinedArray = props?.fields?.categoryCards?.map((category, index) => ({
    ...category,
    backgroundColor: result[index % result.length]?.bgcolor || '',
  }));
  const {
    base,
    headerContainer,
    headerTitleText,
    headerDescriptionText,
    headerTitleContainer,
    ctaContainer,
    card,
    cardContainer,
    cardWrapper,
    cardContent,
    cardContentWrapper,
    cardMediaWrapper,
    cardMediaContainer,
    cardIcon,
    cardTitle,
    cardSVG,
    legalDisclaimerText,
  } = tailwindVariants({
    alignCTA: alignCTA as AlignCTA,
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
  });
  return (
    <>
      <div className={base()}>
        {title && (
          <div className={headerContainer()}>
            {title && (
              <div className={headerTitleContainer()}>
                {title?.value && <Text className={headerTitleText()} field={title} tag="p" />}
                {description?.value && (
                  <Text
                    className={headerDescriptionText()}
                    encode={false}
                    field={description}
                    tag="p"
                  />
                )}
              </div>
            )}
            {alignCTA == 'TopRight' && (
              <div className={ctaContainer()}>
                <Button
                  href={primaryCTA?.value?.href}
                  label={primaryCTA?.value.text}
                  tag="a"
                  target={primaryCTA?.value?.target}
                  // The design requires an outline CTA but field name is primaryCTA,
                  // so for that we have added a fallback as an outline value,
                  // so if there is no value in sitecore field, it will take the outline value.
                  type={primaryCTAType?.value || fallbackComponentVariantType}
                  color={primaryCTAColor?.value || fallbackComponentVariantColor}
                  gtmEvent={{
                    event: 'cta_click',
                    type: 'primary',
                    'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                    'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                    'gtm.element.dataset.gtmDatasourceId': dataSource,
                    'gtm.element.dataset.gtmComponentName': componentName,
                  }}
                />
              </div>
            )}
          </div>
        )}
        <div className={cardContainer()}>
          {combinedArray?.map((cards: CategoryCardItem) => (
            <>
              <LinkWrapper
                aria-label={`${stripHtml(cards?.fields?.title?.value as string)}${
                  cards?.fields?.description?.value ? ` - ${cards?.fields?.description?.value}` : ''
                }`}
                className={cardWrapper()}
                field={cards?.fields?.ctaLink}
                suppressLinkText
                ignoreEE
              >
                <div className={`${card()}`}>
                  <div className={cardContentWrapper()}>
                    <div className={cardContent()}>
                      <div className={cardTitle()}>
                        <Text encode={false} field={cards?.fields?.title} tag="span" />
                      </div>
                      <GoogleMaterialSymbol className={cardIcon()} icon="arrow_forward" />
                    </div>
                  </div>
                  <div className={`${cardMediaWrapper()}`}>
                    {enablePattern?.value && (
                      <>
                        <SVG
                          svg={`CategoryCard/Breakpoint=Large,Brand=${themeName}`}
                          className={`${cardSVG({
                            size: 'small',
                          })} ${cards?.backgroundColor}`}
                        />
                        <SVG
                          svg={`CategoryCard/Breakpoint=Large,Brand=${themeName}`}
                          className={`${cardSVG({
                            size: 'large',
                          })} ${cards?.backgroundColor}`}
                        />
                      </>
                    )}
                    <div className={cardMediaContainer()}>
                      {cards?.fields?.image && (
                        <ImageWrapper field={cards?.fields?.image} layout="responsive" />
                      )}
                    </div>
                  </div>
                </div>
              </LinkWrapper>
            </>
          ))}
        </div>
        {alignCTA == 'BottomCenter' && (
          <div className={ctaContainer()}>
            <Button
              href={primaryCTA?.value?.href}
              label={primaryCTA?.value.text}
              tag="a"
              target={primaryCTA?.value?.target}
              // The design requires an outline CTA but field name is primaryCTA,
              // so for that we have added a fallback as an outline value,
              // so if there is no value in sitecore field, it will take the outline value.
              type={primaryCTAType?.value || fallbackComponentVariantType}
              color={primaryCTAColor?.value || fallbackComponentVariantColor}
              gtmEvent={{
                event: 'cta_click',
                type: 'primary',
                'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                'gtm.element.dataset.gtmDatasourceId': dataSource,
                'gtm.element.dataset.gtmComponentName': componentName,
              }}
            />
          </div>
        )}
        {disclaimerText?.value != '' && (
          <LegalDisclaimer
            disclaimerText={disclaimerText}
            disclaimerClasses={legalDisclaimerText()}
          />
        )}
      </div>
    </>
  );
};
export default CategoryCardListingSideBySide;
