// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { ComponentProps } from 'lib/component-props';
import { ProductAndArticleComponent } from 'lib/templates/Feature.Dart.model';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';

export type ArticleCalloutProps = ComponentProps &
  ProductAndArticleComponent.ArticleCallout.ArticleCallout;

const tailwindVariants = tv({
  slots: {
    title: [
      'lg:font-header-large-xSmall',
      'lg:leading-header-large-xSmall',
      'lg:text-header-large-xSmall',
      'md:font-header-medium-xSmall',
      'md:leading-header-medium-xSmall',
      'md:text-header-medium-xSmall',
      'font-header-small-xSmall',
      'leading-header-small-xSmall',
      'text-header-small-xSmall',
      'text-components-tip-color-title',
    ],
    description: [
      'md:font-bodySans-large',
      'md:leading-bodySans-large',
      'md:text-bodySans-large',
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'text-components-tip-color-body',
    ],
    wrapper: [
      'bg-components-tip-color-bg',
      'border-components-tip-color-stroke',
      'border-2',
      'flex',
      'flex-col',
      'gap-spacing-spacing-5',
      'py-spacing-spacing-6',
      'px-spacing-spacing-5',
      'rounded-themes-radius-large-container-tip',
    ],
  },
});

const ArticleCallout = (props: ArticleCalloutProps): JSX.Element => {
  if (!props.fields) return <></>;

  const articleCallout = props.fields;
  const { title, description, wrapper } = tailwindVariants();
  // Unique id for component
  const id = props?.params?.RenderingIdentifier;
  return (
    <Container id={id ? id : undefined}>
      <div className={wrapper()} data-component="authorable/articlecallout" tabIndex={id ? -1 : 1}>
        <Text className={title()} encode={false} field={articleCallout.title} tag="h2" />
        <RichTextA11yWrapper className={description()} field={articleCallout.description} />
      </div>
    </Container>
  );
};

export default ArticleCallout;
