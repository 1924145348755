/**
 * @param {import('next').NextConfig} nextConfig
 */
const cors = (nextConfig = {}) => {
  return Object.assign({}, nextConfig, {
    async headers() {
      if (process.env.IS_EDITING_HOST === 'true') {
        return [
          {
            // matching all routes
            source: '/:path*',
            headers: [
              { key: 'Access-Control-Allow-Credentials', value: 'true' },
              { key: 'Access-Control-Allow-Origin', value: '*' },
              { key: 'Access-Control-Allow-Methods', value: 'HEAD,GET,DELETE,PATCH,POST,PUT' },
              {
                key: 'Access-Control-Allow-Headers',
                value:
                  'X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version',
              },
            ],
          },
        ];
      } else {
        return [];
      }
    },
  });
};

module.exports = cors;
