// Global
import { GetStaticComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import graphQLClientFactory from 'lib/graphql-client-factory';
import React, { useEffect, useState } from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartComponents } from 'lib/templates/Feature.Dart.model';

// Local
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';
import HeaderQuery from './Header.graphql';

export type HeaderGQLinks = ComponentProps &
  DartComponents.Header &
  DartComponents.HeaderJson & {
    staticProps?: {
      header?: DartComponents.HeaderJson;
    };
  };

const Header = (staticProps: HeaderGQLinks): JSX.Element => {
  const [windowSize, setWindowSize] = useState<number>();

  useEffect(() => {
    // set the window size
    if (typeof window !== 'undefined') {
      setWindowSize(window.innerWidth);
    }

    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowSize]);

  return (
    <>
      <HeaderDesktop {...staticProps} />
      <HeaderMobile {...staticProps} />
    </>
  );
};

export default Header;

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  const graphQLClient = graphQLClientFactory({});
  const result = await graphQLClient.request<unknown>(HeaderQuery, {
    datasource: rendering.dataSource,
    params: rendering.params,
    language: layoutData?.sitecore?.context?.language,
  });
  return {
    staticProps: result,
  };
};
