import he from 'he';

export const stripHtml = (str: string) => {
  if (str === null || undefined) return;
  return str?.replace(/<[^>]*>[^<]*<\/[^>]+>|&reg;/g, '®');
};

export const stripHtmlTags = (str: string) => {
  if (str === null || undefined) return;
  return he.decode(str?.replace(/(&nbsp;|<([^>]+)>)/gi, ''));
};
