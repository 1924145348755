// Global
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

// Lib
import useExperienceEditor from 'lib/use-experience-editor';

/**
 * This component replaces token with token value for Sitecore Text field
 */

export type TextTokenReplacerProps = {
  editable?: boolean;
  field?: Field<string>;
  tag?: string;
  encode?: boolean;
  tokens?: string[];
  tokenValues?: string[];
  tokenValuesClass?: string;
  isSpanQuoteContain?: boolean;
  indexValueOfQuoteContainSpan?: number;
};

const TextTokenReplacer = React.forwardRef(
  (
    {
      field,
      editable,
      tag,
      encode,
      tokens,
      tokenValues,
      tokenValuesClass,
      isSpanQuoteContain,
      indexValueOfQuoteContainSpan,
    }: TextTokenReplacerProps,
    ref
  ): JSX.Element => {
    const isEE = useExperienceEditor();

    if (!field) {
      return <></>;
    }

    if (isEE) {
      return <Text ref={ref} field={field} encode={encode} editable={editable} tag={tag} />;
    }

    // The condition works when we receive a string value and want to highlight a particular part of it.
    if (field?.value && tokens && tokenValues) {
      const parts = field.value.split(new RegExp(`(${tokens.join('|')})`, 'g'));

      return (
        <>
          {parts?.map((part, index) => {
            if (tokens.includes(part)) {
              const tokenIndex = tokens.indexOf(part);
              const tokenValue = tokenValues[tokenIndex];
              return (
                <span key={index} className={tokenValuesClass}>
                  {index === indexValueOfQuoteContainSpan && isSpanQuoteContain
                    ? `"${tokenValue}"`
                    : tokenValue}
                </span>
              );
            }
            return <span key={index}>{part}</span>;
          })}
        </>
      );
    }

    return <Text ref={ref} field={field} encode={encode} editable={editable} tag={tag} />;
  }
);

TextTokenReplacer.displayName = 'TextTokenReplacer';

export default TextTokenReplacer;
