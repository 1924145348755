import { FeaturedJobCardProps } from 'components/authorable/FeaturedJobCard/FeaturedJobCard';

// Realistically this is going to have a lot more data from Sitecore or XM Cloud, but this is a good example mock data set.
const defaultData: FeaturedJobCardProps = {
  rendering: { componentName: 'FeaturedJobCard' },
  params: {
    alignCTA: 'TopRight',
    grid: '2column',
    GridParameters: 'col-12',
    FieldNames: 'Default',
    DynamicPlaceholderId: '1',
    isInStorybook: 'true', // Data from API
  },
  fields: {
    primaryCTAType: {
      value: '',
    },
    title: {
      value: 'Featured Job Listing',
    },
    description: {
      value:
        'Brief description text. Amet illo nemo quaerat delectus rerum corrupti laudantium accusantium.',
    },
    primaryCTA: {
      value: {
        text: 'View All',
        anchor: '',
        linktype: 'internal',
        class: '',
        title: '',
        target: '',
        querystring: '',
        id: '{53813178-3F9C-4253-B167-7257D76276D9}',
        href: '/en-us',
      },
    },
    primaryCTAColor: {
      value: '',
    },
    multiColorBar: {
      value: false,
    },
    disclaimerText: {
      value: '*Legal disclaimer lorem ipsum dolor',
    },
  },
};

export const noData = {
  rnder: {},
  params: [],
};

export default defaultData;

// Data coming from API - But here add static data - Form demonstration
export const apiMockData = [
  {
    title: 'Senior Payroll Specialist with French',
    location: 'Warsaw, Mazovia, Poland; Frimley, England, United Kingdom',
    category: 'Human Resources',
    url: 'https://jobs.scjohnson.com/job/-/-/5362/64454249216',
    date: '8/21/2024 5:39:38 PM',
  },
  {
    title: 'Accounts Receivable (AR) / Credit Control Analyst – German Speaker',
    location: 'Frimley, England, United Kingdom',
    category: 'Finance and Accounting',
    url: 'https://jobs.scjohnson.com/job/-/-/5362/64570093488',
    date: '8/21/2024 5:39:38 PM',
  },
  {
    title: 'Sr. Associate, Patent Paralegal',
    location: 'Racine, Wisconsin, United States',
    category: 'Legal',
    url: 'https://jobs.scjohnson.com/job/-/-/5362/64757224704',
    date: '8/21/2024 5:39:38 PM',
  },
  {
    title: 'Senior Associate, Registration & Regulatory Compliance',
    location: 'RJakarta, Jakarta Special Capital Region, Indonesia',
    category: 'Research and Development',
    url: 'https://jobs.scjohnson.com/job/-/-/5362/65443677120',
    date: '8/21/2024 5:39:38 PM',
  },
  {
    title: 'Praktikant Supply Chain (f/m/d)',
    location: 'Düsseldorf, North Rhine-Westphalia, Germany',
    category: 'Supply Chain',
    url: 'https://jobs.scjohnson.com/job/-/-/5362/65489836720',
    date: '8/21/2024 5:39:38 PM',
  },
  {
    title: 'Regional SHE Manager',
    location: 'Rosslyn, Gauteng, South Africa; Cairo, Cairo Governorate, Egypt',
    category: 'Safety, Health and Environment',
    url: 'https://jobs.scjohnson.com/job/-/-/5362/65569320992',
    date: '8/21/2024 5:39:38 PM',
  },
  {
    title: 'Senior Associate – Registration & Regulatory Compliance',
    location: 'Istanbul, Istanbul, Turkey',
    category: 'Research and Development',
    url: 'https://jobs.scjohnson.com/job/-/-/5362/65845954208',
    date: '8/21/2024 5:39:38 PM',
  },
  {
    title: 'Export Sales Representative (French speaker)',
    location: 'Accra, Greater Accra Region, Ghana',
    category: 'Sales',
    url: 'https://jobs.scjohnson.com/job/-/-/5362/66077450816',
    date: '8/21/2024 5:39:38 PM',
  },
];
