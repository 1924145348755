import { MosquitoForecastResultProps } from 'components/authorable/MosquitoForcast/MosquitoForecastResult';

// Realistically this is going to have a lot more data from Sitecore or XM Cloud, but this is a good example mock data set.
const defaultData: MosquitoForecastResultProps = {
  rendering: { componentName: 'MediaGallery' },
  params: {
    galleryOrientation: 'Default',
    isInStorybook: 'true', // Data from API
  },
  fields: {
    disableCaptcha: {
      value: true,
    },
    emailPlaceholderText: {
      value: 'Email Address',
    },
    emailTitle: {
      value: 'Get the mosquito forecast delivered to your inbox weekly',
    },
    forecastLogo: {
      value: {
        src: 'https://edge.sitecorecloud.io/scjohnsonan1996-dart-dev-0055/media/project/dart/off/dev/forecast/offcast-logo.png?h=118&iar=0&w=208',
        alt: 'Forecast Logo',
        width: '208',
        height: '118',
        extension: 'png',
      },
    },
    newsletterAcceptanceText: {
      value:
        'I would like to receive my local mosquito forecast and confirm that I am 18 years or older and have read the',
    },
    promotionsOffersText: {
      value: 'Desejo receber promoções e ofertas especiais.',
    },
    primaryCTA: {
      value: {
        text: 'Sign up now',
        anchor: '',
        linktype: 'internal',
        class: '',
        title: '',
        target: '',
        querystring: '',
        id: '{69BB2FF7-2C90-4294-96C2-55F10E07CACD}',
        href: '/en-US/Dev/Off-Cast-Thank-You',
      },
    },
    title: {
      value: 'Your Mosquito Forecast for {count}',
    },
    enableSocial: {
      value: true,
    },
    socialLinks: [
      {
        id: '8dea757e-4595-480c-808d-8501a2066520',
        url: '/en-US/Data/Mosquito-Forecast-Result/Mosquito-Forecast-Result/Social-Link-Folder/Email',
        name: 'Email',
        displayName: 'Email',
        fields: {
          socialIcon: {
            value: {
              src: 'https://edge.sitecorecloud.io/scjohnsonan1996-dart-dev-0055/media/project/dart/shared/dart-icons/email.svg?h=40&iar=0&w=40',
              alt: 'Social Icon',
              width: '40',
              height: '40',
              extension: 'svg',
            },
          },
          socialUrl: {
            value: {
              text: 'Products',
              anchor: '',
              linktype: 'internal',
              class: '',
              title: '',
              target: '',
              querystring: '',
              id: '{223A362F-5EE0-4BB9-B1A3-1F079A1306DA}',
              href: '/products',
            },
          },
        },
      },
      {
        id: '2def28fb-bf9c-488a-9644-be4c107dd397',
        url: '/en-US/Data/Mosquito-Forecast-Result/Mosquito-Forecast-Result/Social-Link-Folder/Facebook',
        name: 'Facebook',
        displayName: 'Facebook',
        fields: {
          socialIcon: {
            value: {
              src: 'https://edge.sitecorecloud.io/scjohnsonan1996-dart-dev-0055/media/project/dart/shared/dart-icons/facebook.svg?h=40&iar=0&w=40',
              alt: 'Social Icon',
              width: '40',
              height: '40',
              extension: 'svg',
            },
          },
          socialUrl: {
            value: {
              href: '',
            },
          },
        },
      },
      {
        id: '17e35de1-2c52-4060-a263-f5fdfd6895a3',
        url: '/en-US/Data/Mosquito-Forecast-Result/Mosquito-Forecast-Result/Social-Link-Folder/Twitter',
        name: 'Twitter',
        displayName: 'Twitter',
        fields: {
          socialIcon: {
            value: {
              src: 'https://edge.sitecorecloud.io/scjohnsonan1996-dart-dev-0055/media/project/dart/shared/dart-icons/twitter.svg?h=40&iar=0&w=40',
              alt: '',
              width: '40',
              height: '40',
              extension: 'svg',
            },
          },
          socialUrl: {
            value: {
              text: 'FAQs',
              anchor: '',
              linktype: 'internal',
              class: '',
              title: '',
              target: '',
              querystring: '',
              id: '{D1F36E02-5A26-4883-A8FC-84DC975F4A58}',
              href: '/faqs',
            },
          },
        },
      },
    ],
    socialTitle: {
      value: 'Share OFF!Cast Mosquito Forecast™',
    },
  },
};

export const noData = {
  rnder: {},
  params: [],
};

export default defaultData;

// Data coming from API - But here add static data - Form demonstration
export const apiMockData = {
  validTimeUtc: [
    1728864000, 1728950400, 1729036800, 1729123200, 1729209600, 1729296000, 1729382400,
  ],
  dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  indexValue: [1, 1, 1, 1, 1, 1, 1],
  rawIndexValue: [8.7, 7.7, 5.6, 5.6, 5.6, 7, 5.6],
  indexCategory: ['Medium', 'Medium', 'Medium', 'Medium', 'Medium', 'Medium', 'Medium'],
};
