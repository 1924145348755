// Global
import React, { useState, useEffect, useRef } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useI18n } from 'next-localization';
import { tv } from 'tailwind-variants';
import axios from 'axios';

// Local
import { LocationSearchData } from 'helpers/GlobalContext/globalContext';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';

export type OffcastSearchInputProps = {
  placeholder: string;
  onSelectLocation: (item?: LocationSearchData) => void;
  selectedPlace?: LocationSearchData;
  closeOnEsc?: boolean;
  isError?: boolean;
};

type ForecastAPIConfig = {
  countryCode: string;
  languageCountryCode: string;
  locationSearchUrl: string;
  mosquitoForecastUrl: string;
  locationType: string;
};

const tailwindVariants = tv({
  slots: {
    addressList: [
      'bg-components-global-search-color-omnibox-list-bg',
      'py-components-global-search-spacing-omnibox-list-padding-y',
      'max-h-96',
      'overflow-auto',
    ],
    addressListItem: [
      'cursor-pointer',
      'fill-components-global-search-color-omnibox-item-text-default',
      'flex',
      'font-bodySans-medium',
      'gap-3',
      'hover:bg-components-dropdown-item-bg-hover',
      'items-center',
      'leading-bodySans-medium',
      'px-components-global-search-spacing-omnibox-item-padding-x',
      'py-components-global-search-spacing-omnibox-item-padding-y',
      'text-bodySans-medium',
      'text-components-global-search-color-omnibox-item-text-default',
    ],
    listContainer: [
      'relative',
      '[&>*]:!absolute',
      '[&>*]:!min-w-full',
      '[&>*]:!shadow-md',
      '[&>*]:!transform-none',
      '[&>*]:!w-full',
      '[&>*]:z-20',
      '[&>*]:left-0',
      'md:[&>*]:w-full',
    ],
    searchContainer: ['flex', 'flex-col', 'flex-1', 'self-stretch'],
    searchIcon: [
      'cursor-pointer',
      'flex',
      'h-12',
      'w-12',
      'items-center',
      'justify-center',
      'absolute',
      'right-0',
    ],
    searchInput: [
      'flex-1',
      'min-h-14',
      'outline-0',
      'focus:-m-px',
      'px-components-text-field-input-padding-x',
      'py-components-text-field-input-padding-y',
      'rounded-full',
      'self-stretch',
      'text-bodySans-medium',
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'bg-transparent',
      'text-components-text-field-input-field-default',
      'focus:text-components-text-field-input-field-focused',
      'active:text-components-text-field-input-field-focused',
      'pr-12',
    ],
    searchInputContainer: [
      'border',
      'flex-1',
      'flex',
      'group',
      'items-center',
      'justify-center',
      'min-h-14',
      'relative',
      'rounded-full',
      'self-stretch',
      'self-stretch',
      'focus-within:border-2',
      'focus-within:outline-components-text-field-border-focused',
      'focus-within:bg-components-text-field-bg-focused',
    ],
  },
  variants: {
    hasInput: {
      false: {
        searchIcon: ['hidden'],
      },
      true: {
        searchIcon: ['inline'],
      },
    },
    itemSelected: {
      false: {
        addressListItem: ['bg-transparent'],
      },
      true: {
        addressListItem: ['bg-components-dropdown-item-bg-hover'],
      },
    },
    isError: {
      true: {
        searchInputContainer: ['border-components-text-field-border-destructive'],
      },
      false: {
        searchInputContainer: ['border-components-text-field-border-default'],
      },
    },
  },
});

const OffcastSearchInput = ({
  placeholder,
  onSelectLocation,
  selectedPlace,
  isError = false,
}: OffcastSearchInputProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();

  const [query, setQuery] = useState<string>('');
  const [suggestions, setSuggestions] = useState<LocationSearchData[]>([]);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState<number>(-1);

  const listboxRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const i18n = useI18n();
  const searchTermMsg = i18n.t('SearchTermRequired');
  const failedToFetchMsg = i18n.t('FailedToFetch');

  const {
    addressList,
    addressListItem,
    listContainer,
    searchContainer,
    searchIcon,
    searchInput,
    searchInputContainer,
  } = tailwindVariants({ hasInput: !!query, isError: isError });

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedPlace) onSelectPlace(selectedPlace);
  }, [selectedPlace]);

  const onSelectPlace = (item: LocationSearchData) => {
    setQuery(item?.displayName + ', ' + item?.postalCode);
    onSelectLocation(item);
    setSuggestions([]);

    if (item.displayName) setQuery(item?.displayName + ', ' + item?.postalCode);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setQuery(inputValue);
    onSelectLocation(); // To clear selection on type again

    // Example: Fetch suggestions from an API
    fetchSuggestions(inputValue);
    setSelectedSuggestionIndex(-1);
  };

  const fetchSuggestions = async (inputValue: string) => {
    // Simulating fetching suggestions from an API
    try {
      const { locationSearchUrl, countryCode, languageCountryCode, locationType } =
        sitecoreContext?.weatherCompanySettings as ForecastAPIConfig;
      const payload = {
        countryCode: countryCode,
        languageCountryCode: languageCountryCode,
        searchTerm: inputValue,
        apiURL: locationSearchUrl,
        //apiKey: jssConfig.sitecoreWeatherCompanySearchApiKey, // get value from environment variable
        searchTermMsg,
        failedToFetchMsg,
        locationType: locationType,
      };
      const response = await axios.get('/api/climatecompany/search', {
        params: payload,
      });
      setSuggestions(response.data.locations);
    } catch (error) {
      console.error(failedToFetchMsg);
    }
  };

  // Closing suggestions as wel
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowDown') {
      // To change focus
      event.preventDefault();
      setSelectedSuggestionIndex((prevIndex) =>
        prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
      );
      scrollToSelectedSuggestion();
    } else if (event.key === 'ArrowUp') {
      // To change focus
      event.preventDefault();
      setSelectedSuggestionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
      scrollToSelectedSuggestion();
    } else if (event.key === 'Enter') {
      // To select item
      event.preventDefault();
      if (selectedSuggestionIndex !== -1 && suggestions.length)
        onSelectLocation(suggestions[selectedSuggestionIndex]);
    } else if (event.key === 'Escape') {
      // To close suggestions
      setSuggestions([]);
      inputRef.current?.focus();
    }
  };

  // manage scrolling which using keys
  const scrollToSelectedSuggestion = () => {
    const selectedSuggestionElement = listboxRef.current?.children[selectedSuggestionIndex];
    if (selectedSuggestionElement) {
      selectedSuggestionElement.scrollIntoView({ block: 'nearest' });
    }
  };

  // Close suggestions on close
  const handleClickOutside = (event: MouseEvent) => {
    if (listboxRef.current && !listboxRef.current.contains(event.target as Node)) {
      setSuggestions([]);
    }
  };

  // To clear searched input
  const clearInput = () => {
    setQuery('');
    onSelectLocation();
    inputRef.current?.focus();
  };

  return (
    <div className={searchContainer()}>
      <div className={searchInputContainer()}>
        <input
          type="text"
          value={query}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          className={searchInput()}
          aria-label={placeholder}
          aria-autocomplete="list"
          aria-haspopup="true"
          autoComplete="off"
          ref={inputRef}
          id="forcastSearchInput"
        />
        {query && (
          <button type="button" onClick={clearInput} className={searchIcon()}>
            <GoogleMaterialSymbol icon="close" />
          </button>
        )}
      </div>
      {suggestions.length > 0 && (
        <div className={listContainer()}>
          <ul ref={listboxRef} role="listbox" className={addressList()}>
            {suggestions.map((suggestion: LocationSearchData, index) => {
              return (
                <li
                  key={index}
                  className={addressListItem({
                    itemSelected: selectedSuggestionIndex === index,
                  })}
                  role="option"
                  aria-selected={selectedSuggestionIndex === index ? 'true' : 'false'}
                  onClick={() => {
                    onSelectPlace(suggestion);
                  }}
                >
                  <GoogleMaterialSymbol icon="location_on" />
                  {suggestion.address}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default OffcastSearchInput;
