// GLobal
import React from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { ComponentProps } from 'lib/component-props';
import { CompositeComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';

export type ContentListProps = ComponentProps & CompositeComponents.ContentList.ContentList;

const tailwindVariants = {
  base: tv({
    compoundSlots: [
      {
        slots: ['itemNum', 'itemTitle'],
        class: [
          'font-header-small-xSmall',
          'leading-header-small-xSmall',
          'text-components-content-list-color-title',
          'text-header-small-xSmall',
          'md:font-header-medium-xSmall',
          'md:leading-header-medium-xSmall',
          'md:text-header-medium-xSmall',
          'lg:font-header-large-xSmall',
          'lg:leading-header-large-xSmall',
          'lg:text-header-large-xSmall',
        ],
      },
      {
        slots: ['itemDescription', 'itemIcon'],
        class: [
          'font-bodySans-small',
          'leading-bodySans-small',
          'text-bodySans-small',
          'md:font-bodySans-medium',
          'md:leading-bodySans-medium',
          'md:text-bodySans-medium',
        ],
      },
    ],
    slots: {
      base: [
        'content-list',
        'text-components-content-list-color-body',
        'group-[.layoutWrapper]:p-0',
        'md:p-spacing-spacing-10',
        'py-components-content-list-spacing-small-padding-y',
        'px-components-content-list-spacing-small-padding-x',
      ],
      componentWrapper: ['bg-components-content-list-color-bg'],
      itemDescription: [
        'flex-1',
        'text-components-content-list-color-body',
        '[&_img]:mt-spacing-spacing-4',
        'md:[&_img]:mt-spacing-spacing-7',
        '[&_img]:rounded-themes-radius-small-image',
        'md:[&_img]:rounded-themes-radius-small-image',
        '[&_img]:mx-auto',
        '[&>ul]:list-disc',
        '[&>ul]:ml-spacing-spacing-5',
        '[&>ul]:m-0',
        '[&>.img-wrapper-ce]:mt-components-content-list-spacing-small-item-copy-margin-bottom',
        '[&>.img-wrapper-ce]:md:mt-components-content-list-spacing-large-item-copy-margin-bottom',
        '[&>.scWebEditInput>.ql-editor>ul]:list-disc',
        '[&>.scWebEditInput>.ql-editor>ul]:ml-spacing-spacing-5',
      ],
      itemIcon: ['fill-components-content-list-color-body'],
      itemNum: [],
      itemTitle: [
        'mb-components-content-list-spacing-small-item-title-margin-bottom',
        'md:mb-components-content-list-spacing-large-item-title-margin-bottom',
      ],
      itemContentWrapper: ['inline-flex', 'px-4'],
      itemWrapper: [
        'flex',
        'gap-components-content-list-spacing-small-item-icon-margin-right',
        'mb-components-content-list-spacing-small-header-margin-bottom',
        'md:mb-components-content-list-spacing-large-item-margin-bottom',
        'last-of-type:mb-0',
      ],
    },
    variants: {
      withUnorderedList: {
        true: {
          itemWrapper: [
            'last-of-type:border-b',
            'border-t',
            'border-components-site-search-facet-stroke',
            'py-spacing-spacing-4',
            '!mb-0',
            'md:mb-0',
            '[&_ul]:ml-4',
          ],
        },
      },
      withIcon: {
        false: {
          itemWrapper: [
            'border-b',
            'border-t',
            'rounded-components-site-search-facet-radius',
            '[&+div]:border-t-0',
            'border-components-site-search-facet-stroke',
            'py-components-content-list-list-item-container-padding-y',
            'px-components-content-list-list-item-container-padding-x',
            '!mb-0',
          ],
        },
      },
    },
  }),
  listHeaderTailwindVariants: tv({
    slots: {
      base: [
        'mb-components-content-list-spacing-small-header-margin-bottom',
        'md:mb-components-content-list-spacing-large-header-margin-bottom',
        'gap-spacing-spacing-4',
        'md:gap-spacing-spacing-4',
        'flex',
        'flex-col',
      ],
      header: [
        'font-header-small-medium',
        'leading-header-small-medium',
        'text-components-content-list-color-title',
        'text-header-small-medium',
        'md:font-header-medium-medium',
        'md:leading-header-medium-medium',
        'md:text-header-medium-medium',
        'lg:font-header-large-medium',
        'lg:leading-header-large-medium',
        'lg:text-header-large-medium',
      ],
      subHeader: [
        'font-header-small-xSmall',
        'leading-header-small-xSmall',
        'text-components-content-list-color-body',
        'text-header-small-xSmall',
        'md:font-header-medium-xSmall',
        'md:leading-header-medium-xSmall',
        'md:text-header-medium-xSmall',
        'lg:font-header-large-xSmall',
        'lg:leading-header-large-xSmall',
        'lg:text-header-large-xSmall',
      ],
      description: [
        'text-components-content-list-color-body',
        'font-bodySans-medium',
        'leading-bodySans-medium',
        'text-bodySans-medium',
        'lg:font-bodySans-large',
        'lg:leading-bodySans-large',
        'lg:text-bodySans-large',
      ],
    },
    variants: {
      onlyHeader: {
        true: {
          header: ['!pb-0'],
        },
      },
    },
  }),
};

const ContentList = (props: ContentListProps): JSX.Element => {
  const { base, componentWrapper, itemDescription, itemIcon, itemWrapper } =
    tailwindVariants.base();
  // Unique id for component
  const id = props?.params?.RenderingIdentifier;
  return (
    <div
      className={componentWrapper()}
      data-component="authorable/contentlist"
      id={id ? id : undefined}
      tabIndex={-1}
    >
      <Container>
        <div className={base()}>
          <ContentListHeader {...props} />
          <>
            {props?.fields?.contentListItems?.map((content, index: number) => {
              return (
                <React.Fragment key={index}>
                  <div
                    className={itemWrapper({
                      withIcon: !!content.fields.icon?.value,
                    })}
                    data-id={index}
                    key={index}
                  >
                    {content.fields?.icon?.value && (
                      <GoogleMaterialSymbol
                        className={itemIcon()}
                        icon={content.fields?.icon?.value}
                      />
                    )}
                    <RichTextA11yWrapper
                      className={itemDescription()}
                      field={content?.fields?.description}
                    />
                  </div>
                </React.Fragment>
              );
            })}
          </>
        </div>
      </Container>
    </div>
  );
};

// DIRECTIONS
export const WithSteps = (props: ContentListProps): JSX.Element => {
  const { base, componentWrapper, itemDescription, itemNum, itemTitle, itemWrapper } =
    tailwindVariants.base();
  // Unique id for component
  const id = props?.params?.RenderingIdentifier;
  return (
    <div className={componentWrapper()} id={id ? id : undefined} tabIndex={-1}>
      <Container>
        <div className={base()}>
          <ContentListHeader {...props} />
          {props?.fields?.contentListItems?.map((content, index: number) => {
            const itemNumber = index + 1;
            return (
              <React.Fragment key={index}>
                <div className={itemWrapper()} data-id={index} key={index}>
                  <div className={itemNum()}>{itemNumber}.</div>
                  <div>
                    {content.fields.title?.value && (
                      <h2>
                        <RichTextA11yWrapper
                          className={itemTitle()}
                          field={content.fields?.title}
                        />
                      </h2>
                    )}
                    <RichTextA11yWrapper
                      className={itemDescription()}
                      field={content?.fields?.description}
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export const WithMultipleBulletLists = (props: ContentListProps): JSX.Element => {
  const { base, componentWrapper, itemDescription, itemTitle, itemWrapper } =
    tailwindVariants.base();
  // Unique id for component
  const id = props?.params?.RenderingIdentifier;
  return (
    <div className={componentWrapper()} id={id ? id : undefined} tabIndex={-1}>
      <Container>
        <div className={base()}>
          <ContentListHeader {...props} />
          {props?.fields?.contentListItems?.map((content, index: number) => {
            return (
              <React.Fragment key={index}>
                <div className={itemWrapper()} data-id={index} key={index}>
                  <div>
                    {content.fields.title?.value && (
                      <h2>
                        <RichTextA11yWrapper
                          className={itemTitle()}
                          field={content.fields?.title}
                        />
                      </h2>
                    )}
                    <RichTextA11yWrapper
                      className={itemDescription()}
                      field={content?.fields?.description}
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export const WithBulletsWithInTheSteps = (props: ContentListProps): JSX.Element => {
  const { base, componentWrapper, itemDescription, itemNum, itemTitle, itemWrapper } =
    tailwindVariants.base();
  // Unique id for component
  const id = props?.params?.RenderingIdentifier;
  return (
    <div className={componentWrapper()} tabIndex={-1}>
      <Container>
        <div className={base()} id={id ? id : undefined}>
          <ContentListHeader {...props} />
          {props?.fields?.contentListItems?.map((content, index: number) => {
            const itemNumber = index + 1;
            return (
              <React.Fragment key={index}>
                <div className={itemWrapper()} data-id={index} key={index}>
                  <div className={itemNum()}>{itemNumber}.</div>
                  <div>
                    {content.fields.title?.value && (
                      <h2>
                        <RichTextA11yWrapper
                          className={itemTitle()}
                          field={content.fields?.title}
                        />
                      </h2>
                    )}
                    <RichTextA11yWrapper
                      className={itemDescription()}
                      field={content?.fields?.description}
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

// INGREDIENTS
export const WithUnorderedList = (props: ContentListProps): JSX.Element => {
  const { base, componentWrapper, itemDescription, itemContentWrapper, itemWrapper } =
    tailwindVariants.base({
      withUnorderedList: true,
    });
  // Unique id for component
  const id = props?.params?.RenderingIdentifier;
  return (
    <div className={componentWrapper()} id={id ? id : undefined} tabIndex={-1}>
      <Container>
        <div className={base()}>
          <ContentListHeader {...props} />
          {props?.fields?.contentListItems?.map((content, index: number) => {
            return (
              <div className={itemWrapper()} data-id={index} key={index}>
                <div className={itemContentWrapper()}>
                  <RichTextA11yWrapper
                    className={itemDescription()}
                    field={content?.fields?.description}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

const ContentListHeader = (props: ContentListProps): JSX.Element => {
  const { base, description, header, subHeader } = tailwindVariants.listHeaderTailwindVariants();
  // Unique id for component
  const { uid } = props.rendering;

  return (
    <>
      {(props?.fields?.header?.value ||
        props?.fields?.subHeader?.value ||
        props?.fields?.description?.value) && (
        <div className={base()} id={uid ? uid : undefined} tabIndex={uid ? -1 : 1}>
          {props?.fields?.header?.value && (
            <RichTextA11yWrapper
              className={header({
                onlyHeader: !props.fields.subHeader?.value && !props.fields.description?.value,
              })}
              field={props?.fields?.header}
            />
          )}
          {props?.fields?.subHeader?.value && (
            <RichTextA11yWrapper className={subHeader()} field={props?.fields?.subHeader} />
          )}
          {props?.fields?.description?.value && (
            <RichTextA11yWrapper className={description()} field={props?.fields?.description} />
          )}
        </div>
      )}
    </>
  );
};

export default ContentList;
