export type ThemeUrlKeys = 'Corporate' | 'Ziploc' | 'Vanilla' | '' | 'Glade';

export const ThemeUrls: Record<ThemeUrlKeys, string> = {
  Glade:
    'https://cdn.fonts.net/kit/8383a640-2a23-4e11-96df-b4aab8fef5c9/8383a640-2a23-4e11-96df-b4aab8fef5c9.css',
  Corporate:
    'https://cdn.fonts.net/kit/3bcec18a-4ec4-4df8-a1ad-ea8283708578/3bcec18a-4ec4-4df8-a1ad-ea8283708578.css',
  Ziploc:
    'https://cdn.fonts.net/kit/fbf8b1ba-7fcf-4b12-9d7e-e861055df2df/fbf8b1ba-7fcf-4b12-9d7e-e861055df2df.css?display=swap',
  Vanilla:
    'https://cdn.fonts.net/kit/fbf8b1ba-7fcf-4b12-9d7e-e861055df2df/fbf8b1ba-7fcf-4b12-9d7e-e861055df2df.css?display=swap',
  '': 'https://cdn.fonts.net/kit/fbf8b1ba-7fcf-4b12-9d7e-e861055df2df/fbf8b1ba-7fcf-4b12-9d7e-e861055df2df.css?display=swap',
};
