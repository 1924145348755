import { useTheme } from './ThemeContext';
import TOKENS from '../../theme/tokens.theme';

export const ComponentVariants = () => {
  const { themeName } = useTheme();
  const componentVariants =
    themeName !== 'Vanilla'
      ? TOKENS.componentVariants[themeName]
      : TOKENS.componentVariants['DART'];

  return componentVariants;
};
