// Global
import { HTMLLink, Item, RouteData, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import Head from 'next/head';
import { useEffect } from 'react';

// Lib
import { useTheme } from 'lib/context/ThemeContext';
import { DartPages } from 'lib/templates/Project.Dart.model';
import { useRealPathName } from 'lib/utils/use-real-pathname';
import Script from 'next/script';
import { ThemeUrlKeys, ThemeUrls } from '../Fonts/MonotypeFonts';

export interface RobotsData {
  fields: {
    Key: {
      value: string | undefined;
    };
  };
}

export type PageHeadSectionProps = {
  route?: RouteData | null;
  language?: string;
  headLinks: HTMLLink[] | null;
};

export type RobotData = Item & RobotsData;

type PageRoute = RouteData & DartPages.Base.DartBasePage;

const PageHeadSection = ({ route, language, headLinks }: PageHeadSectionProps) => {
  const page = route as PageRoute;

  // Fetching the whole URL for the og:url
  const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

  const {
    browserTitle,
    canonicalUrl,
    metaTitle,
    metaDescription,
    metaKeywords,
    ogDescription,
    ogImage,
    ogTitle,
    robotsText,
  } = page?.fields || {};

  const pathName = useRealPathName();
  const context = useSitecoreContext();
  const { themeName } = useTheme();

  const isMikMak = context?.sitecoreContext?.integrationMikMak?.isMikMak === '1';
  const mikMakAppId = context?.sitecoreContext?.integrationMikMak?.appID;
  const title = browserTitle?.value || route?.displayName;
  const adimoMultiTouchPoint =
    context?.sitecoreContext?.integrationEvidon?.isAdimoMultiTouchpoint === '1';
  const isAdimoPriceFirst = context?.sitecoreContext?.integrationEvidon?.isAdimoPriceFirst === '1';
  const isPreview = process.env.IS_PREVIEW_SITE ? true : false;

  const isBazaarVoice = context?.sitecoreContext?.bazaarVoice?.enableBazaarVoice === '1';
  const crL8Sitename = context?.sitecoreContext?.bazaarVoice?.crL8Sitename;

  /* selectedTheme retrieving the selected theme name from the `themeName` variable, then
 using that theme name to get the corresponding URL from the `ThemeUrls` object. Finally, it assigns
 the URL to the `brandSpecificMonotypeHref` variable for further usage. This allows dynamically
 selecting a specific theme URL based on the selected theme name. */
  const selectedTheme = themeName;

  const brandSpecificMonotypeHref = ThemeUrls[selectedTheme as ThemeUrlKeys];

  // Swaven Tag Script
  useEffect(() => {
    if (!isMikMak) return;

    (function (e, d) {
      try {
        const a = (window.swnDataLayer = window.swnDataLayer || {});

        a.appId = e || a.appId;
        a.eventBuffer = a.eventBuffer || [];
        a.loadBuffer = a.loadBuffer || [];
        a.push =
          a.push ||
          function (e: object) {
            a.eventBuffer.push(e);
          };
        a.load =
          a.load ||
          function (e: object) {
            a.loadBuffer.push(e);
          };
        a.dnt = a.dnt != null ? a.dnt : d;

        const n = document.createElement('script');

        n.async = true;
        n.id = 'mikmak_script';
        n.src = '//wtb-tag.mikmak.ai/scripts/' + a.appId + '/tag.min.js';

        const t = document.getElementsByTagName('script')[0];
        t.parentNode?.insertBefore(n, t);
      } catch (error) {
        console.log(error);
      }
    })(mikMakAppId, false);

    return () => {
      const n = document.getElementById('mikmak_script');
      n?.remove();

      window.swnTagLoaded = false;
    };
  }, [mikMakAppId, isMikMak, pathName]);

  return (
    <>
      <Head>
        {/* Font Link */}
        {brandSpecificMonotypeHref && <link href={brandSpecificMonotypeHref} rel="stylesheet" />}

        <title>{title || themeName}</title>
        {/* Head links */}
        {headLinks?.map(({ rel, href }) => (
          <link href={href} key={href} rel={rel} />
        ))}
        {/* siteFavIcon  */}
        {!isPreview && context.sitecoreContext?.siteFavIcon?.value?.src && (
          <link href={context.sitecoreContext?.siteFavIcon?.value?.src} rel="icon" />
        )}
        {isPreview && context.sitecoreContext?.siteFavIconPreview?.value?.src && (
          <link href={context.sitecoreContext?.siteFavIconPreview?.value?.src} rel="icon" />
        )}
        {/* Meta tags */}
        {metaTitle?.value && <meta content={metaTitle.value} name="title" />}
        {metaDescription?.value && <meta content={metaDescription.value} name="description" />}
        {metaKeywords?.value && <meta content={metaKeywords.value} name="keywords" />}
        {language && <meta content={language} name="language" />}
        {/* Canonical URL */}
        {canonicalUrl?.value && <link href={canonicalUrl?.value} rel="canonical" />}
        {/* OG tags are the only common meta tags that use "property" instead of "name" */}
        <meta content={ogTitle?.value || metaTitle?.value} property="og:title" />
        <meta content={ogDescription?.value || metaDescription?.value} property="og:description" />
        {ogImage?.value?.src && <meta content={ogImage.value.src} property="og:image" />}
        {currentUrl && <meta content={currentUrl} property="og:url" />}
        {/* Twitter tags */}
        <meta content={ogTitle?.value || metaTitle?.value} property="twitter:title" />
        <meta
          content={ogDescription?.value || metaDescription?.value}
          property="twitter:description"
        />
        {ogImage?.value?.src && <meta content={ogImage.value.src} property="twitter:image" />}
        {currentUrl && <meta content={currentUrl} property="twitter:url" />}
        {/* Robots */}
        {!!robotsText?.length &&
          robotsText?.map((robots: RobotData, index: number) => (
            <meta content={robots?.fields?.Key?.value} key={index} name="robots" />
          ))}
      </Head>
      <Script src="https://ui.powerreviews.com/stable/4.1/ui.js" async strategy="lazyOnload" />
      {/* If MikMak is disabled and look for Adimo variables and based on priority below code adds appropriate script as per the selection in sitecore */}
      {!isMikMak && (
        <>
          {adimoMultiTouchPoint && (
            <Script
              src={`https://cdn.adimo.co/scripts/multi-touchpoint.min.js`}
              strategy="lazyOnload"
            />
          )}
          {isAdimoPriceFirst && (
            <Script src={`https://cdn.adimo.co/scripts/priceFirst.min.js`} strategy="lazyOnload" />
          )}
        </>
      )}
      {isBazaarVoice && (
        <Script
          id="curalate-script"
          src={`https://edge.curalate.com/sites/${crL8Sitename}/site/latest/site.min.js`}
          strategy="afterInteractive"
        ></Script>
      )}
    </>
  );
};

export default PageHeadSection;
