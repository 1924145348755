import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import React from 'react';
import { tv } from 'tailwind-variants';

interface LegalDisclaimerProps {
  disclaimerText: Field<string> | undefined;
  disclaimerClasses?: string;
}

const tailwindVariants = tv({
  slots: {
    legalDisclaimerText: [
      'font-bodySans-small',
      'text-bodySans-small',
      'leading-bodySans-small',
      'text-components-promo-color-default-body',
    ],
  },
});

const LegalDisclaimer: React.FC<LegalDisclaimerProps> = ({ disclaimerText, disclaimerClasses }) => {
  const { legalDisclaimerText } = tailwindVariants();

  return (
    <div className={legalDisclaimerText({ className: disclaimerClasses })}>
      <RichTextA11yWrapper field={disclaimerText} />
    </div>
  );
};

export default LegalDisclaimer;
