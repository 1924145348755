// Global
import { useSearchParams } from 'next/navigation';
import React from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartPages } from 'lib/templates/Project.Dart.model';

// Local
import SearchResultsWidget from 'src/widgets/SearchResults';

export type SearchResultProps = ComponentProps & DartPages.SearchPage;

export const Default = (props: SearchResultProps): JSX.Element => {
  const useKeyphrase = (): string => {
    const searchParams = useSearchParams();
    const searchQuery = searchParams.get('q') || '';
    return searchQuery || '';
  };

  const SearchResultsData = {
    resultsPerPage: props?.fields?.resultsPerPage,
    filterTitle: props?.fields?.filterTitle,
    noSearchResult: props?.fields?.noSearchResult,
    searchTitle: props?.fields?.searchTitle,
    sortDropdownLabel: props?.fields?.sortDropdownLabel,
    sortLabel: props?.fields?.sortLabel,
    defaultKeyphrase: useKeyphrase(),
  };

  return (
    <div data-component="search/result">
      <SearchResultsWidget
        rfkId={'rfkid_10'}
        {...SearchResultsData}
        key={`${useKeyphrase()}-search`}
      />
    </div>
  );
};
