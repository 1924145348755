// Global
import React, { ReactElement, useEffect, useRef, useState } from 'react';

// Lib
import { getStaticAssetUrl } from 'lib/utils/public-url-utils';
import { addDartSvgLoading, removeDartSvgLoading } from 'lib/utils/dart-svg-loading';
import Parser from 'html-react-parser';

// Local
import { normalizeImageUrl } from 'helpers/ImageWrapper/ImageWrapper';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

export type SVGProps = {
  className?: string;
  hidden?: boolean;
  svg?: string;
  url?: string;
};

const SVG = ({ className, hidden = true, svg, url }: SVGProps): ReactElement => {
  const [fetchedSVG, setFetchedSVG] = useState('');
  const svgUriRef = useRef<string>('');
  //const sitecoreContext = useSitecoreContext().sitecoreContext;
  const currentLang = useSitecoreContext().sitecoreContext.language;
  const currenthost = typeof window !== 'undefined' ? window.location.hostname : '';
  const lang = currenthost.toLocaleLowerCase().includes('localhost')
    ? ''
    : `/${currentLang?.toLocaleLowerCase()}`;

  useEffect(() => {
    if (!svg && !url) return;

    async function fetchSVG() {
      if (!url) {
        svgUriRef.current = `${getStaticAssetUrl()}${lang}/assets/${svg}.svg`;
      }
      if (url) {
        svgUriRef.current = normalizeImageUrl(url) ?? '';
      }

      addDartSvgLoading(svgUriRef.current);
      if (svgUriRef.current) {
        fetch(svgUriRef.current)
          .then((res) => (res.ok ? res.text() : null))
          .then((text) =>
            text
              ? setFetchedSVG(text)
              : console.error('There was a problem with the Fetch operation:')
          )
          .catch((error) => {
            console.error('There was a problem with the Fetch operation:', error);
          })
          .finally(() => {
            removeDartSvgLoading(svgUriRef.current);
          });
      }
    }
    fetchSVG();
  }, [svg, url, lang]);

  return (
    <>
      {fetchedSVG && (
        <span aria-hidden={hidden} data-svg-url={svgUriRef.current} className={className}>
          {Parser(fetchedSVG)}
        </span>
      )}
    </>
  );
};

export default SVG;
