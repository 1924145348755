// Global
import { ComponentVariants } from 'lib/context/ComponentVariants';
import { ALL_THEMES, useTheme } from 'lib/context/ThemeContext';
import { tv } from 'tailwind-variants';

export interface MulticolorborderProps {
  className?: string;
  multipleBar?: string;
  skewXdeg?: string;
}

const MultiColorBorder: React.FC<MulticolorborderProps> = (props) => {
  const { multipleBar, skewXdeg } = props || {};

  const componentVariants = ComponentVariants();
  const { themeName } = useTheme();

  const themeVariants = ALL_THEMES.reduce(
    (acc, curr) => ((acc[curr] = {}), acc),
    {} as Record<string, object>
  );

  const topBorderOneColor = componentVariants?.multipleBar?.top?.borderOne?.fill?.value;
  const topBorderTwoColor = componentVariants?.multipleBar?.top?.borderTwo?.fill?.value;
  const topBorderThreeColor = componentVariants?.multipleBar?.top?.borderThree?.fill?.value;
  const topBorderFourColor = componentVariants?.multipleBar?.top?.borderFour?.fill?.value;
  const topBorderOneHeight = componentVariants?.multipleBar?.top?.borderOne?.height?.value;
  const topBorderTwoHeight = componentVariants?.multipleBar?.top?.borderTwo?.height?.value;
  const topBorderThreeHeight = componentVariants?.multipleBar?.top?.borderThree?.height?.value;
  const topBorderFourHeight = componentVariants?.multipleBar?.top?.borderFour?.height?.value;

  const bottomBorderFiveColor = componentVariants?.multipleBar?.bottom?.borderFive?.fill?.value;
  const bottomBorderSixColor = componentVariants?.multipleBar?.bottom?.borderSix?.fill?.value;
  const bottomBorderSevenColor = componentVariants?.multipleBar?.bottom?.borderSeven?.fill?.value;
  const bottomBorderEightColor = componentVariants?.multipleBar?.bottom?.borderEight?.fill?.value;
  const bottomBorderFiveHeight = componentVariants?.multipleBar?.bottom?.borderFive?.height?.value;
  const bottomBorderSixHeight = componentVariants?.multipleBar?.bottom?.borderSix?.height?.value;
  const bottomBorderSevenHeight =
    componentVariants?.multipleBar?.bottom?.borderSeven?.height?.value;
  const bottomBorderEightHeight =
    componentVariants?.multipleBar?.bottom?.borderEight?.height?.value;

  const tailwindVariants = tv({
    slots: {
      topBase: ['flex', 'overflow-hidden', 'transform'],
      bottomBase: ['flex', 'overflow-hidden', 'transform'],
      borderOne: [
        'borderOne',
        `bg-[${topBorderOneColor}]`,
        `h-[${topBorderOneHeight}]`,
        'w-1/4',
        'relative',
        'before:absolute',
        'before:content-[""]',
        'before:h-full',
        'before:w-[5px]',
        'before:bg-inherit',
        'before:right-[-5px]',
      ],
      borderTwo: [
        'borderTwo',
        `bg-[${topBorderTwoColor}]`,
        `h-[${topBorderTwoHeight}]`,
        `skew-x-[${skewXdeg}]`,
        'z-[9]',
        'w-1/4',
        'relative',
        'before:absolute',
        'before:content-[""]',
        'before:h-full',
        'before:w-[5px]',
        'before:bg-inherit',
      ],
      borderThree: [
        'borderThree',
        `bg-[${topBorderThreeColor}]`,
        `h-[${topBorderThreeHeight}]`,
        `skew-x-[${skewXdeg}]`,
        'z-[9]',
        'w-1/4',
        'relative',
        'after:absolute',
        'after:content-[""]',
        'after:h-full',
        'after:w-[5px]',
        'after:bg-inherit',
      ],
      borderFour: [
        'borderFour',
        `bg-[${topBorderFourColor}]`,
        `h-[${topBorderFourHeight}]`,
        'w-1/4',
        'relative',
        'after:absolute',
        'after:h-full',
        'after:content-[""]',
        'after:w-[5px]',
        'after:bg-inherit',
        'after:left-[-5px]',
      ],
      borderFive: [
        'borderFive',
        `bg-[${bottomBorderFiveColor}]`,
        `h-[${bottomBorderFiveHeight}]`,
        'w-1/4',
        'relative',
        'before:absolute',
        'before:content-[""]',
        'before:h-full',
        'before:w-[5px]',
        'before:bg-inherit',
        'before:right-[-5px]',
      ],
      borderSix: [
        'borderSix',
        `bg-[${bottomBorderSixColor}]`,
        `h-[${bottomBorderSixHeight}]`,
        `skew-x-[${skewXdeg}]`,
        'z-[9]',
        'w-1/4',
        'relative',
        'before:absolute',
        'before:content-[""]',
        'before:h-full',
        'before:w-[5px]',
        'before:bg-inherit',
      ],
      borderSeven: [
        'borderSeven',
        `bg-[${bottomBorderSevenColor}]`,
        `h-[${bottomBorderSevenHeight}]`,
        `skew-x-[${skewXdeg}]`,
        'z-[9]',
        'w-1/4',
        'relative',
        'after:absolute',
        'after:content-[""]',
        'after:h-full',
        'after:w-[5px]',
        'after:bg-inherit',
      ],
      borderEight: [
        'borderEight',
        `bg-[${bottomBorderEightColor}]`,
        `h-[${bottomBorderEightHeight}]`,
        'w-1/4',
        'relative',
        'after:absolute',
        'after:content-[""]',
        'after:h-full',
        'after:w-[5px]',
        'after:bg-inherit',
        'after:left-[-5px]',
      ],
    },
    variants: {
      brand: {
        ...themeVariants,
        Ziploc: {
          borderTwo: ['before:right-[-5px]'],
          borderThree: ['after:left-[-5px]'],
          borderSix: ['before:right-[-5px]'],
          borderSeven: ['after:left-[-5px]'],
        },
        Corporate: {
          topBase: ['-mb-[9px]', 'relative', 'z-[9]'],
          bottomBase: ['-mt-[9px]', 'relative', 'z-[10]'],
          borderSeven: ['top-[9px]'],
          borderEight: ['top-[9px]'],
        },
        Glade: {
          topBase: ['-mb-[9px]', 'relative', 'z-[9]'],
          bottomBase: ['-mt-[9px]', 'relative', 'z-[10]'],
          borderSeven: ['top-[9px]'],
          borderEight: ['top-[9px]'],
        },
      },
    },
  });

  const {
    topBase,
    bottomBase,
    borderOne,
    borderTwo,
    borderThree,
    borderFour,
    borderFive,
    borderSix,
    borderSeven,
    borderEight,
  } = tailwindVariants({
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
  });

  return (
    <>
      {multipleBar === 'top' && (
        <div className={topBase()}>
          <div className={borderOne()}></div>
          <div className={borderTwo()}></div>
          <div className={borderThree()}></div>
          <div className={borderFour()}></div>
        </div>
      )}
      {multipleBar === 'bottom' && (
        <div className={bottomBase()}>
          <div className={borderFive()}></div>
          <div className={borderSix()}></div>
          <div className={borderSeven()}></div>
          <div className={borderEight()}></div>
        </div>
      )}
    </>
  );
};

export default MultiColorBorder;
